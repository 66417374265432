// import "process/browser";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";
import Hotjar from "./components/Hotjar";
import { configureStore } from "./store/store";
import { FacebookProvider } from "react-facebook";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore({})}>
    <FacebookProvider appId="915908736630908">
      <React.Fragment>
        <Hotjar />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </FacebookProvider>
  </Provider>
);
reportWebVitals();
// serviceWorker.unregister();
