import React from "react";

function AlertTriangeMono(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.32"
        d="M18.9801 7.92937C16.6988 3.85563 15.5582 1.81876 14.0577 1.14125C12.7495 0.550579 11.2506 0.550579 9.94244 1.14125C8.44194 1.81876 7.3013 3.85563 5.02 7.92937L3.3894 10.8412C1.19652 14.757 0.100072 16.715 0.283475 18.3182C0.443485 19.717 1.18581 20.9835 2.32808 21.8066C3.63734 22.75 5.88138 22.75 10.3694 22.75H13.6307C18.1187 22.75 20.3628 22.75 21.672 21.8066C22.8143 20.9835 23.5566 19.717 23.7166 18.3182C23.9 16.715 22.8036 14.757 20.6107 10.8412L18.9801 7.92937Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V9C11.25 8.58579 11.5858 8.25 12 8.25ZM10.75 16.5C10.75 15.8096 11.3096 15.25 12 15.25C12.6904 15.25 13.25 15.8096 13.25 16.5C13.25 17.1904 12.6904 17.75 12 17.75C11.3096 17.75 10.75 17.1904 10.75 16.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AlertTriangeMono;
