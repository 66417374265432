import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import notificationData from "../../CommonData/Data/notification";
import MyCustomSwitch from "../../components/Common/MyCustomSwitch";
import { Client, Databases, Query } from "appwrite";
import IntlTelInput from "react-intl-tel-input";
import { withTranslation } from "react-i18next";
import { createLog } from "../../helpers/api_helper";
import CustomToast from "../../components/Common/customToast/CustomToast";
import { useMixpanel } from "../../Hooks/useMixpanel";

const Notifications = (props) => {
  const { mixPanelTrack } = useMixpanel();

  const [switchNotification, setNotification] = useState(
    // notificationData.map((data) => data.state)
    {
      high_trafic_alert: false, //(Alertes de Volume Élevé)
      email_transfer: false, //(Notifications de Transfert de Mail)
      urgent_response_alert: false, //(Alertes de Réponse Urgente)
      follow_up_reminder: false, //(Rappels de Suivi)
      tag_updates: false, //(Mises à Jour des Tags)
      performance_updates: false, //(Notfications de Performance)
      security_alerts: false, //(Alertes de Sécurité)
      important_customer_feedback: false, //(Feedback Client Important)
      system_updates: false, //(Mises à Jour du Système)
      technical_issue_alerts: false //(Alertes de Problèmes Techniques)
    }
  );

  const handleWitchNotification = (state, notification_key) => {
    console.log("switch state is", state);
    console.log("switch notification_key is", notification_key);

    setNotification((prev) => {
      const updatedStates = { ...prev };
      updatedStates[notification_key] = state;
      console.log("final updatedStates is", updatedStates);
      return updatedStates;
    });

    // setSwitchNotification((prev) => {
    //   const updatedStates = [...prev];
    //   updatedStates[index] = state;
    //   console.log(updatedStates);
    //   console.log(updatedStates[index]);
    //   return updatedStates;
    // });
    // console.log({ switchNotification });
  };

  const [isEmailEnabled, setIsEmailEnabled] = useState(true);
  const [isPhoneNumberEnabled, setIsPhoneNumberEnabled] = useState(false);
  const [notificationPhoneNumber, setNotificationPhoneNumber] = useState("");
  const [notificationEmail, setNotificationEmail] = useState("");
  // const [ownerInfos, setOwnerInfos] = useState({});
  // const [company_employees_data, setCompanyEmployeesData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastContent, setToastContent] = useState({
    title: "Toast",
    message: "Toast"
  });

  const onLoading = (val) => {
    setLoading(val);
  };
  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };
  async function getClientData() {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.getDocument("default", "client_infos", localStorage.getItem("companyId"));

    return promise.then(
      async function (response) {
        //    const employeeData = response.documents;
        //   if (employeeData.length > 0) {
        //  setCompanyEmployeesData(employeeData[0]);
        // console.log('CompanyEmployeesData is', employeeData[0]);
        //  if (employeeData[0].owner) {
        //  const owner = JSON.parse(employeeData[0].owner);
        // console.log('owner is', owner);
        // console.log('owner notifications', owner.notifications);
        //  setOwnerInfos(owner);

        if (response.notification_settings) {
          console.log("Comapny has notifications settings", JSON.parse(response.notification_settings));
          const notifSettings = JSON.parse(response.notification_settings);
          setNotification(notifSettings);
          setIsEmailEnabled(notifSettings.notification_channels?.email);
          setIsPhoneNumberEnabled(notifSettings.notification_channels?.sms);
          setNotificationPhoneNumber(notifSettings.notification_channels?.notification_sms_number ?? "");
          setNotificationEmail(notifSettings.notification_channels?.notification_email_address ?? "");
        }

        //  }
        //   }
        //  return employeeData;
      },
      function (error) {
        console.log("Error listing company employees ", error);
        console.log(error); // Failure
        return null;
      }
    );
  }
  async function updateCompanyInfos(data) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument("default", "client_infos", localStorage.getItem("companyId"), data);

    return promise.then(
      async function (response) {
        // setOwnerInfos(JSON.parse(response.owner));
        // setCompanyEmployeesData(response);
        setNotification(JSON.parse(response.notification_settings));
        onLoading(false);
        console.log("Employees list updated ", response); // Success
        setToastContent({ title: props.t("Succès"), body: props.t("Mise à jour effectuée."), key: "success" });
        toggleToast();
        return response;
      },
      function (error) {
        onLoading(false);
        setToastContent({ title: props.t("Echec"), body: error.message, key: "danger" });
        toggleToast();
        console.log("Error updating Employees list", error); // Failure
        return null;
      }
    );
  }
  const handleNotificationsUpdate = () => {
    onLoading(true);
    let updatedNotifications = {};
    updatedNotifications = {
      high_trafic_alert: switchNotification.high_trafic_alert,
      email_transfer: switchNotification.email_transfer,
      urgent_response_alert: switchNotification.urgent_response_alert,
      follow_up_reminder: switchNotification.follow_up_reminder,
      tag_updates: switchNotification.tag_updates,
      performance_updates: switchNotification.performance_updates,
      security_alerts: switchNotification.security_alerts,
      important_customer_feedback: switchNotification.important_customer_feedback,
      system_updates: switchNotification.system_updates,
      technical_issue_alerts: switchNotification.technical_issue_alerts,
      notification_channels: {
        email: isEmailEnabled,
        sms: isPhoneNumberEnabled,
        notification_sms_number: notificationPhoneNumber,
        notification_email_address: notificationEmail
      }
    };
    // console.log({ updatedNotifications });

    // let updatedOwner = {
    //   ...ownerInfos,
    //   notifications: updatedNotifications
    // };

    //   console.log("updatedOwner is", updatedOwner);

    const response = updateCompanyInfos({
      notification_settings: JSON.stringify(updatedNotifications)
    });
    if (!response) {
      return;
    }
    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    var company_id = localStorage.getItem("companyId");
    let logData = {
      eventCreatedAt: response?.$createdAt,
      author_id: user_id,
      company_id: company_id
    };
    let logdata = {
      event: "notifications_updated",
      author_id: user_id,
      company_id: company_id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);
    toggleToast();
  };

  useEffect(() => {
    getClientData();
  }, []);
  return (
    <React.Fragment>
      {/* <Container fluid={true} style={{ }} className="notifications col-xl-8 col-xxl-6"> */}
      <Container fluid={true} style={{ maxWidth: "60%" }} className="notifications">
        <Col lg="12">
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <CardBody style={{ padding: "0" }}>
              <Row className="align-items-center justify-content-between flex-row mb-4">
                <div style={{ width: "fit-content" }}>
                  <h4 className="section-title font-primary mb-0" style={{ fontSize: "18px" }}>
                    {props.t("Notifications")}
                  </h4>
                </div>
              </Row>
              <Row className="mt-3">
                <Col xl={12}>
                  {/* <Card className="notifications_item" style={{ boxShadow: "none" }}>
                    <CardBody className="d-flex align-items-center justify-content-between gap-3">
                      <Row>
                        <div className="d-flex flex-column col" style={{ borderRight: "1px solid #F5F5F5" }}>
                          <div className="d-flex gap-2 align-items-center">
                            <div className="notifications_title font-primary" style={{ fontSize: "14px" }}>
                              {props.t("Numéro de téléphone")}
                            </div>
                          </div>
                          <Row style={{ alignItems: "start" }}>
                            <p className="notifications_teaser mt-2 mb-0 col-10" style={{ fontSize: "14px" }}>
                              {props.t("notification_subtitle_1")}
                            </p>
                            <input
                              className="form-check-input mt-0 me-2 col-2"
                              type="checkbox"
                              checked={isPhoneNumberEnabled}
                              defaultChecked={isPhoneNumberEnabled}
                              onClick={(e) => {
                                setIsPhoneNumberEnabled(!isPhoneNumberEnabled);
                              }}
                            />
                          </Row>

                          <FormGroup className="my-3">
                            <IntlTelInput
                              defaultCountry={props.i18n.language}
                              containerClassName="intl-tel-input col-md-12"
                              inputClassName="form-control"
                              formatOnInit={false}
                              placeholder=""
                              disabled={!isPhoneNumberEnabled}
                              value={notificationPhoneNumber}
                              onPhoneNumberChange={(status, phoneNumber, country) => {
                                setNotificationPhoneNumber(phoneNumber.replace(/ /g, ""));
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className="d-flex flex-column col">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="notifications_title font-primary" style={{ fontSize: "14px" }}>
                              {props.t("Email")}
                            </div>
                          </div>
                          <Row style={{ alignItems: "start" }}>
                            <p className="notifications_teaser mt-2 mb-0 col-10" style={{ fontSize: "14px" }}>
                              {props.t("notification_subtitle_2")}
                            </p>
                            <input
                              className="form-check-input mt-0 me-2 col-2"
                              type="checkbox"
                              checked={isEmailEnabled}
                              defaultChecked={isEmailEnabled}
                              onClick={(e) => {
                                setIsEmailEnabled(!isEmailEnabled);
                              }}
                            />
                          </Row>
                          <FormGroup className="my-3 mt-auto">
                            <Input
                              type="email"
                              className="form-control"
                              disabled={!isEmailEnabled}
                              id="basicpill-email-input42"
                              placeholder="exemple@gmail.com"
                              value={notificationEmail}
                              onChange={(e) => setNotificationEmail(e.target.value)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    </CardBody>
                  </Card> */}
                  {notificationData.map((notification, index) => (
                    <Card key={notification.title} className="notifications_item" style={{ boxShadow: "none" }}>
                      <CardBody className="d-flex align-items-center justify-content-between gap-3">
                        <div className="d-flex flex-column">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="notifications_title font-primary" style={{ fontSize: "14px" }}>
                              {props.t(notification.title)}
                            </div>
                          </div>
                          <p className="notifications_teaser mt-2 mb-0" style={{ fontSize: "14px" }}>
                            {props.t(notification.description)}
                          </p>
                        </div>

                        <MyCustomSwitch
                          onClickHandle={handleWitchNotification}
                          notification_key={notification.key}
                          notification_state={switchNotification[notification.key]}
                        />
                      </CardBody>
                    </Card>
                  ))}
                  <button
                    disabled={loading}
                    className="view-button"
                    style={{ float: "right", backgroundColor: "#563bff" }}
                    onClick={handleNotificationsUpdate}>
                    {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />} {props.t("Enregistrer")}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <CustomToast isOpen={toast} toggle={toggleToast} message={toastContent} />
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Notifications);
