import React, { useEffect, useState } from "react";
import checkImgSrc from "../../assets/images/sucesscheck.png";
import logo_maaia from "../../assets/images/companies/img-3.png";
import synibox_chat_logo_1000 from "../../assets/images/companies/img-3.png";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import {
  cancelStripeSubscription,
  cancelStripeTrial,
  createLog,
  formatDateMillisec,
  getStripeSubscription,
  klaviyoUpdateProfile,
  SMARTAS,
  stripe_payment_return_url
} from "../../helpers/api_helper";
import { withTranslation } from "react-i18next";
import CheckCircleMono from "../../assets/images/icons/CheckCircleMono";
import CrossCircleMono from "../../assets/images/icons/CrossCircleMono";
import AlertTriangeMono from "../../assets/images/icons/AlertTriangeMono";
import { useMixpanel } from "../../Hooks/useMixpanel";
import { useSelector } from "react-redux";
import subscription from "../../store/subscription/reducer";

const StripeCancellation = (props) => {
  document.title = "Cancel Subscription | Synibox - Smart Assistant";

  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region");
  let id = urlParams.get("id");

  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);
  const onCancellationLoading = (val) => {
    setLoading(val);
  };
  const { mixPanelTrack } = useMixpanel();

  const { userPlan, subscription, client_data } = useSelector((state) => {
    return {
      userPlan: state.subscription.userPlan,
      subscription: state.subscription.subscription,
      client_data: state.GeneralStateRedux.client_data
    };
  });

  const handleSubscriptionCancellation = async (
    stripe_region,
    subscription_id
  ) => {
    onCancellationLoading(true);
    try {
      const response = await cancelStripeSubscription(
        stripe_region,
        subscription_id
      );
      if (response) {
        setMessage({ type: "success", alert: "Opération réussie !" });

        // Créer le log
        const company_id = localStorage.getItem("companyId");
        var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

        let logData = {
          eventCreatedAt: Date.now(),
          author_id: user_id,
          company_id: company_id,
          subscription_id: id,
          plan: userPlan
        };
        let logdata = {
          event: "subscription_cancelled",
          author_id: user_id,
          company_id: company_id,
          log_data: JSON.stringify(logData)
        };
        createLog(logdata, mixPanelTrack);

        onCancellationLoading(false);
      } else {
        throw response;
      }
    } catch (error) {
      onCancellationLoading(false);
      setMessage({
        type: "error",
        alert: "Echec lors de l'annulation de l'abonnement."
      });
    }
  };

  useEffect(() => {
    console.log({ user_plan: subscription });
    const company_id = localStorage.getItem("companyId");
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    if (!company_id || !user_id || !id || subscription.cancel_at_period_end) {
      window.location.href = stripe_payment_return_url;
    }
  }, [id]);

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={8}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img
                            src={synibox_chat_logo_1000}
                            height={54}
                            alt="synibox_logo"
                            style={{
                              marginTop: "3vh"
                            }}
                          />
                        </Link>
                      </div>
                      <h4
                        className="font-size-16 font-primary mt-2 mb-4 text-center"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        {props.t("Annulation d'abonnement")}
                      </h4>
                      <Row className="d-flex justify-content-center">
                        <Row>
                          <Col md={12}>
                            {!loading && message.type === "success" && (
                              <div
                                className="alert alert-success  font-primary d-flex align-items-center"
                                style={{
                                  flexDirection: "column"
                                }}
                              >
                                <CheckCircleMono width={80} />
                                {props.t(message.alert)}
                              </div>
                            )}
                            {!loading && message.type === "error" && (
                              <div
                                className="alert alert-danger  font-primary d-flex align-items-center"
                                style={{
                                  flexDirection: "column"
                                }}
                              >
                                <CrossCircleMono width={80} />
                                {props.t(message.alert)}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <Row>
                          <Col md={12}>
                            <>
                              {message.type !== "success" && (
                                <div>
                                  <div className="mb-2">
                                    <AlertTriangeMono
                                      width={"24px"}
                                      height={"24px"}
                                      style={{ color: "red" }}
                                      className="me-2"
                                    />
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "black"
                                      }}
                                    >
                                      {props.t(
                                        "Attention : Cette action est irréversible."
                                      )}
                                    </span>
                                  </div>
                                  <p>
                                    {props.t("stripe_cancellation_text_1")}
                                    <strong
                                      className="ms-1"
                                      style={{
                                        fontWeight: "bold",
                                        color: "black"
                                      }}
                                    >
                                      {props.t(
                                        "seront définitivement supprimés."
                                      )}
                                    </strong>
                                  </p>
                                  <p className="mt-3">
                                    {props.t("stripe_cancellation_text_2")}
                                    <strong
                                      className="mx-1"
                                      style={{
                                        fontWeight: "bold",
                                        color: "black"
                                      }}
                                    >
                                      {props.t(
                                        "preserver vos configurations, donnees"
                                      )}
                                    </strong>
                                    {props.t("et")}
                                    <strong
                                      className="ms-1"
                                      style={{
                                        fontWeight: "bold",
                                        color: "black"
                                      }}
                                    >
                                      {props.t("credits")}
                                    </strong>
                                    .
                                  </p>
                                </div>
                              )}
                              <div className="d-flex justify-content-center gap-3 mt-4">
                                <a
                                  className="btn waves-effect waves-light font-primary"
                                  href="/dashboard"
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    backgroundColor: "black",
                                    color: "white"
                                  }}
                                >
                                  {props.t("Retour")}
                                </a>
                                {userPlan !== "MAAIA_PLAN_PAUSE"
                                  ? message.type !== "success" && (
                                      <a
                                        className="btn btn-primary waves-effect waves-light font-primary"
                                        href={
                                          client_data.stripe_region === "US"
                                            ? `/standby-checkout?region=US`
                                            : `/standby-checkout`
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          borderRadius: "10px",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "500"
                                        }}
                                      >
                                        {props.t("Mettre en pause")}
                                        <sup className="ms-1">(9$/m)</sup>
                                      </a>
                                    )
                                  : ""}

                                {message.type !== "success" && (
                                  <button
                                    className="btn waves-effect waves-light font-primary"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: "10px",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      backgroundColor: "red",
                                      color: "white"
                                    }}
                                    onClick={() => {
                                      handleSubscriptionCancellation(
                                        region,
                                        id
                                      );
                                    }}
                                  >
                                    {loading && (
                                      <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}{" "}
                                    {props.t("Annuler l'abonnement")}
                                  </button>
                                )}
                              </div>
                            </>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="simple-text font-primary">
                    © {new Date().getFullYear()} Synibox
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export const StripeTrialCancellation = withTranslation()((props) => {
  document.title = "Trial interruption | Synibox - Smart Assistant";

  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region");
  let id = urlParams.get("id");

  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);
  const onCancellationLoading = (val) => {
    setLoading(val);
  };
  const { mixPanelTrack } = useMixpanel();

  const { userPlan } = useSelector((state) => {
    return {
      userPlan: state.subscription.userPlan
    };
  });

  const handleTrialCancellation = async (stripe_region, subscription_id) => {
    onCancellationLoading(true);
    try {
      const response = await cancelStripeTrial(stripe_region, subscription_id);
      if (response) {
        setMessage({ type: "success", alert: "Opération réussie !" });

        // Créer le log
        const company_id = localStorage.getItem("companyId");
        var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

        const updates = {
          properties: {
            plan: userPlan,
            plan_activation_date: formatDateMillisec(Date.now()),
            trial_period: false
          }
        };
        klaviyoUpdateProfile(company_id, updates);

        let logData = {
          eventCreatedAt: Date.now(),
          author_id: user_id,
          company_id: company_id,
          subscription_id: id,
          plan: userPlan
        };
        let logdata = {
          event: "subscription_trial_interrupted",
          author_id: user_id,
          company_id: company_id,
          log_data: JSON.stringify(logData)
        };
        createLog(logdata, mixPanelTrack);

        onCancellationLoading(false);
      } else {
        throw response;
      }
    } catch (error) {
      onCancellationLoading(false);
      setMessage({ type: "error", alert: "system_error" });
    }
  };

  useEffect(() => {
    const company_id = localStorage.getItem("companyId");
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    if (!company_id || !user_id || !id) {
      window.location.href = stripe_payment_return_url;
    }
  }, [id]);

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img
                            src={synibox_chat_logo_1000}
                            height={54}
                            alt="synibox_logo"
                            style={{
                              marginTop: "3vh"
                            }}
                          />
                        </Link>
                      </div>
                      <h4 className="font-size-16 font-primary text-muted mt-2 text-center">
                        {props.t("Interruption de la période d'essai")}
                      </h4>
                      <Row className="d-flex justify-content-center">
                        <Row>
                          <Col md={12}>
                            {!loading && message.type === "success" && (
                              <div
                                className="alert alert-success  font-primary d-flex align-items-center"
                                style={{
                                  flexDirection: "column"
                                }}
                              >
                                <CheckCircleMono width={80} />
                                {props.t(message.alert)}
                              </div>
                            )}
                            {!loading && message.type === "error" && (
                              <div
                                className="alert alert-danger  font-primary d-flex align-items-center"
                                style={{
                                  flexDirection: "column"
                                }}
                              >
                                <CrossCircleMono width={80} />
                                {props.t(message.alert)}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <Row>
                          <Col md={12}>
                            <>
                              {message.type !== "success" && (
                                <p
                                  className=""
                                  style={{
                                    textAlign: "center"
                                  }}
                                >
                                  {props.t("stripe_trial_cancellation_text")}
                                </p>
                              )}
                              <div className="d-flex justify-content-center gap-3 mt-3">
                                <a
                                  className="btn waves-effect waves-light font-primary"
                                  href="/dashboard"
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    borderRadius: "10px",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "29.152px",
                                    backgroundColor: "black",
                                    color: "white"
                                  }}
                                >
                                  {props.t("Retour")}
                                </a>
                                {message.type !== "success" && (
                                  <button
                                    className="btn btn-primary waves-effect waves-light font-primary"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: "10px",
                                      fontSize: "16px",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      lineHeight: "29.152px"
                                    }}
                                    onClick={() => {
                                      handleTrialCancellation(region, id);
                                    }}
                                  >
                                    {loading && (
                                      <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}{" "}
                                    {props.t("Écourter l'essai")}
                                  </button>
                                )}
                              </div>
                            </>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="simple-text font-primary">
                    © {new Date().getFullYear()} Synibox
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
});

export default withTranslation()(StripeCancellation);
