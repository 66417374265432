import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Col, Card, CardBody, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import errorSm from "../../assets/images/error.png";
import { fetchFBPostsList, formatDateHours } from "../../helpers/api_helper";

const CommentairePage = (props) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [isFilterDropdown, setIsFilterDropdown] = useState(false);
  const [selectedFBPageDropdown, setselectedFBPageDropdown] = useState(false);
  const [commentActions, setCommentActions] = useState({});
  const [fb_posts_loading, setFBPostsLoading] = useState(false);
  const [fb_selected_posts, setFBSelectedPosts] = useState([]);

  useEffect(() => {
    if (props.fb_selected_page) {
      getFBPostList(props.fb_selected_page.id, props.fb_selected_page.access_token);
    }
  }, [props.fb_selected_page]);

  const getFBPostList = async (page_id, access_token) => {
    setFBPostsLoading(true);
    const response = await fetchFBPostsList(page_id, access_token);

    if (!response) {
      return;
    }
    setFBSelectedPosts(response.posts);
    setFBPostsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="mt-3 mx-4">
        {!props.loading && props.fb_selected_pages && props.fb_selected_pages.length > 0 && (
          <div>
            <Card
              className="user-card font-primary"
              style={{
                borderLeft: "5px solid #563BFF",
                marginBottom: "15px",
                borderRadius: "10px",
                boxShadow: "0px 6px 24px 1px rgba(53, 22, 252, 0.1)",
                paddingTop: "6px",
                paddingRight: "29px",
                paddingBottom: "10px",
                paddingLeft: "16px"
              }}>
              <CardBody className="p-0">
                <Row>
                  <Col md={4} className="d-flex align-items-center">
                    <div className="d-flex align-items-end gap-2">
                      <div className="d-flex align-items-baseline gap-2">
                        <div
                          className="agent-name font-primary"
                          style={{
                            width: "fit-content",
                            color: "#1E222C",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "38.454px",
                            position: "relative"
                          }}>
                          <Dropdown
                            isOpen={visibleDropdown}
                            toggle={() => {
                              setVisibleDropdown(!visibleDropdown);
                            }}>
                            <DropdownToggle
                              tag="button"
                              className="form-control p-0 m-0"
                              style={{
                                textTransform: "capitalize",
                                border: "none",
                                backgroundColor: "transparent",
                                margin: "0",
                                padding: "0"
                              }}>
                              {"Select visible"}
                              <i className="ms-2 mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg-end">
                              <DropdownItem onClick={() => {}}>v1</DropdownItem>
                              <DropdownItem onClick={() => {}}>v2</DropdownItem>
                              <DropdownItem onClick={() => {}}>v3</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} className="align-items-center">
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", fontSize: "16px" }}>
                      {/* Texte et icône de filtre */}
                      <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginRight: "5px" }}>
                        <span>Filtre</span>
                        <Dropdown
                          isOpen={isFilterDropdown}
                          toggle={() => {
                            setIsFilterDropdown(!isFilterDropdown);
                          }}>
                          <DropdownToggle
                            tag="button"
                            className="form-control py-0 p-0 m-0"
                            style={{
                              textTransform: "capitalize",
                              border: "none",
                              backgroundColor: "transparent",
                              margin: "0",
                              padding: "0"
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M11 20q-.425 0-.712-.288T10 19v-6L4.2 5.6q-.375-.5-.112-1.05T5 4h14q.65 0 .913.55T19.8 5.6L14 13v6q0 .425-.288.713T13 20zm1-7.7L16.95 6h-9.9zm0 0"
                              />
                            </svg>
                            <i className="ms-2 mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-lg-end">
                            <DropdownItem onClick={() => {}}>f1</DropdownItem>
                            <DropdownItem onClick={() => {}}>f2</DropdownItem>
                            <DropdownItem onClick={() => {}}>f3</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                      {/* Icône de tri (haut/bas) */}
                      <div style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                          <g fill="currentColor">
                            <g fill-opacity=".3">
                              <path d="M7.5 2.286a1 1 0 0 0-1 1v12a1 1 0 1 0 2 0v-12a1 1 0 0 0-1-1" />
                              <path d="M8.207 2.579a1 1 0 0 0-1.414 0l-3.5 3.5a1 1 0 0 0 1.414 1.414L7.5 4.7l2.793 2.793a1 1 0 1 0 1.414-1.414z" />
                            </g>
                            <path d="M16.5 22.286a1 1 0 0 0 1-1v-12a1 1 0 1 0-2 0v12a1 1 0 0 0 1 1" />
                            <path d="M15.793 21.993a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0-1.414-1.414L16.5 19.872l-2.793-2.793a1 1 0 0 0-1.414 1.414z" />
                          </g>
                        </svg>
                      </div>

                      {/* Icône de recherche */}
                      <div style={{ cursor: "pointer" }}>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21 21L18.4953 18.4953C18.1033 18.1033 17.9073 17.9073 17.7092 17.8311C17.5121 17.7553 17.3684 17.7425 17.161 17.7825C16.9527 17.8226 16.6814 18.0117 16.1387 18.3897C14.6817 19.4048 12.9104 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.2414 19.7487 13.4241 19.2941 14.5"
                            stroke="#2F384C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>

                      {/* Icône d'export */}
                      <div style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M20.92 15.62a1.2 1.2 0 0 0-.21-.33l-3-3a1 1 0 0 0-1.42 1.42l1.3 1.29H12a1 1 0 0 0 0 2h5.59l-1.3 1.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l3-3a.9.9 0 0 0 .21-.33a1 1 0 0 0 0-.76M14 20H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h4a1 1 0 0 0 .92-.62a1 1 0 0 0-.21-1.09l-6-6a1 1 0 0 0-.28-.19h-.09l-.28-.1H6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h8a1 1 0 0 0 0-2M13 5.41L15.59 8H14a1 1 0 0 1-1-1Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </Col>
                  {props.fb_selected_pages && props.fb_selected_pages.length > 0 && (
                    <Col md={2} className="align-items-center ms-auto">
                      <div style={{ display: "flex", alignItems: "center", gap: "10px", fontSize: "16px" }}>
                        <Dropdown
                          isOpen={selectedFBPageDropdown}
                          toggle={() => {
                            setselectedFBPageDropdown(!selectedFBPageDropdown);
                          }}>
                          <DropdownToggle
                            tag="button"
                            className="form-control p-0 m-0"
                            style={{
                              textTransform: "capitalize",
                              border: "none",
                              backgroundColor: "transparent",
                              margin: "0",
                              padding: "0"
                            }}>
                            <i className="mdi mdi-facebook me-1" style={{ fontSize: "18px" }} />
                            {props.fb_selected_page ? props.fb_selected_page.name : "Select Page"}
                            <i className="ms-2 mdi mdi-chevron-down" />
                          </DropdownToggle>

                          <DropdownMenu className="dropdown-menu-lg-end">
                            {props.fb_selected_pages.map((page) => (
                              <DropdownItem
                                key={page.id}
                                onClick={() => {
                                  props.setFBSelectedPage(page);
                                }}>
                                {page.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>

            {fb_selected_posts &&
              fb_selected_posts.length > 0 &&
              fb_selected_posts.map((post) => (
                <div key={post.id}>
                  {post.comments.map((comment, i) => (
                    <Card
                      key={comment.id}
                      className="user-card font-primary"
                      style={{
                        borderLeft: "5px solid #563BFF",
                        marginBottom: "15px",
                        borderRadius: "10px",
                        boxShadow: "0px 6px 24px 1px rgba(53, 22, 252, 0.1)",
                        paddingTop: "6px",
                        paddingRight: "29px",
                        paddingBottom: "10px",
                        paddingLeft: "16px"
                      }}>
                      <CardBody className="p-0">
                        <Row>
                          <Col md={5} className="d-flex align-items-center">
                            <div className="row w-100">
                              <div className="col-md-3" style={{ borderRadius: "20px" }}>
                                <img src={post.full_picture || errorSm} alt="fb_post_full_picture" width={102} />
                              </div>
                              <div className="col-md-9 flex-row align-items-center mt-3">
                                <div
                                  style={{
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    "-moz-box-orient": "vertical",
                                    "-webkit-line-clamp": "2"
                                  }}>
                                  {post.message}
                                </div>
                                <div>{formatDateHours(props, post.created_time)}</div>
                                <div>
                                  <i className="mdi mdi-facebook me-1" style={{ fontSize: "18px" }} />
                                  {props.fb_selected_page.name}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={5} className="d-flex align-items-center">
                            <div className="simple-text font-primary">
                              <div>{comment.message}</div>
                            </div>
                          </Col>
                          <Col className="d-flex justify-content-end col-md-full">
                            <div className="d-flex">
                              {commentActions[i] && commentActions[i].loading ? (
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              ) : (
                                <Dropdown
                                  isOpen={commentActions[i] && commentActions[i].state}
                                  toggle={() => {
                                    setCommentActions((prevState) => ({
                                      ...prevState,
                                      [i]: prevState[i]
                                        ? {
                                            state: !prevState[i].state,
                                            loading: prevState[i].loading
                                          }
                                        : { state: true, loading: false }
                                    }));
                                  }}
                                  style={{
                                    display: "flex"
                                  }}>
                                  <DropdownToggle
                                    tag="button"
                                    className={`btn email-menu-dropdown email-menu-dropdown-active                                      `}
                                    style={{
                                      border: "none",
                                      padding: "0",
                                      marginRight: "10px"
                                    }}>
                                    <svg width="27" height="7" viewBox="0 0 27 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g id="Group 1000002886">
                                        <circle id="Ellipse 1029" cx="3" cy="3.24402" r="3" fill="#563BFF" />
                                        <circle id="Ellipse 1031" cx="13.4277" cy="3.24402" r="3" fill="#563BFF" />
                                        <circle id="Ellipse 1030" cx="23.5723" cy="3.24402" r="3" fill="#563BFF" />
                                      </g>
                                    </svg>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => {
                                        //   onEmployeeToUpdateChanged(employee);
                                        //   decodeAuthozations([...employee.autorizations, ...employee.contact_reasons], employee);
                                        //   tog_updateEmployeeModal();
                                      }}>
                                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M21 21L18.4953 18.4953C18.1033 18.1033 17.9073 17.9073 17.7092 17.8311C17.5121 17.7553 17.3684 17.7425 17.161 17.7825C16.9527 17.8226 16.6814 18.0117 16.1387 18.3897C14.6817 19.4048 12.9104 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.2414 19.7487 13.4241 19.2941 14.5"
                                          stroke="#2F384C"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                        />
                                      </svg>{" "}
                                      {props.t("Rechercher")}
                                    </DropdownItem>
                                    {/* <DropdownItem
                                      onClick={() => {
                                        //   setEmployeesEditActions((prevState) => ({
                                        //     ...prevState,
                                        //     [employee.id]: {
                                        //       state: false,
                                        //       loading: true
                                        //     }
                                        //   }));
                                        //   deleteUserRole(employee.id, employee.email);
                                      }}>
                                      {props.t("Supprimer")}
                                    </DropdownItem> */}
                                  </DropdownMenu>
                                </Dropdown>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              ))}
            {fb_posts_loading && (
              <div className="d-flex">
                <span className="spinner-border spinner-border-lg mx-auto font-primary" role="status" aria-hidden="true" />
              </div>
            )}
          </div>
        )}

        <Row className="w-100">
          {props.loading && <span className="spinner-border spinner-border-lg mx-auto font-primary" role="status" aria-hidden="true" />}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(CommentairePage);
