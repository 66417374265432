import { useCallback } from "react";
import mixpanel from "mixpanel-browser";

export const useMixpanel = () => {
  const mixPanelTrack = useCallback((eventName, properties) => {
    mixpanel.track(eventName, properties);
  }, []);

  const identify = useCallback((userId, userProperties = {}) => {
    mixpanel.identify(userId);
    mixpanel.people.set(userProperties);
  }, []);

  return { mixPanelTrack, identify };
};
