import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "../../assets/scss/custom/pages/_welcome-dashboard.scss";

import helloIconPath from "./../../assets/images/waving-hand.png";

import { Client, Databases, Query, Account, ID } from "appwrite";

import { withTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/es";
const moment = require("moment-timezone");

const WelcomeSection = (props) => {
  const [userInfos, setUserInfos] = useState(undefined);

  function formatDateForUI(timezone) {
    // Sélectionnez la locale de moment en fonction de la langue
    switch (props.i18n.language) {
      case "fr":
        moment.locale("fr");
        break;
      case "es":
        moment.locale("es");
        break;
      // Ajoutez d'autres cas de langue au besoin
      default:
        moment.locale("en"); // Langue par défaut
    }

    const currentDate = moment.tz(timezone);
    const formattedDate = currentDate.format("DD MMMM YYYY");
    return formattedDate;
  }

  // Exemple d'utilisation de la fonction
  const userTimezone = "Europe/Paris";

  async function getUserAccount() {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = account.get();
    return promise.then(
      async function (response) {
        // console.log("userAccount is", response);
        // setRequest(false);
        //     if(client_data.mail_config){
        //    }
        //fetchMailBoxes(data);
        const user = response;
        setUserInfos(user);
      },
      function (error) {
        console.log("Error getting user Account ", error);
        console.log(error); // Failure
        // setRequest(false);
      }
    );
  }
  useEffect(() => {
    getUserAccount();
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <div className="welcome-section__container mb-xxl-4 mb-0 d-flex justify-content-between">
            <div className="welcome-section__right d-flex">
              {/* <div className="dateAndhour__conatainer d-flex">
                <span className="date" style={{ marginRight: '5px' }}>
                  {formatDateForUI(userTimezone)}
                </span>
              </div> */}
              <div className="greetings__container">
                <div className="greetings-text font-primary">
                  {props.t("Welcome")}
                </div>
                <div className="d-flex">
                  <div className="employee-name font-primary">
                    {userInfos ? userInfos.prefs.first_name : ""} !
                  </div>
                  <span
                    className="hello-icon font-primary"
                    style={{
                      background: "url(" + helloIconPath + ")",
                      backgroundSize: "70% 70%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center"
                    }}
                  ></span>
                </div>
              </div>
              <div className="greetings-text__sub font-primary">
                {props.t("Bienvenue dans votre espace Synibox !")}
              </div>
            </div>
            <div className="welcome-section__left d-flex font-primary">
              {/* <button
                className="ms-auto view-tutorial-button black-button d-flex align-items-center font-primary"
                onClick={() => props.setStepsEnabled(true)}>
                {props.t("Follow the tutorial")}
              </button> */}
              {/* <button
                className="view-tutorial-button primary-button d-flex align-items-center font-primary"
                onClick={() => props.setmodal_list(true)}>
                {props.t("Revoir la vidéo d’aide")}
              </button> */}
              {/* <div
                style={{
                  position: 'absolute',
                  bottom: '-28px',
                  left: '-32px',
                }}>
                {Icons.cat}
              </div>
              <div style={{ position: 'absolute', top: '22px', left: '-32px' }}>{Icons.chat_bubbles}</div>
              <div
                style={{
                  position: 'absolute',
                  right: '40.87px',
                  top: '-25px',
                  zIndex: '1',
                }}>
                {Icons.frame}
              </div>
              <div
                style={{
                  position: 'absolute',
                  left: '172px',
                  zIndex: '2',
                  top: '4px',
                }}>
                {Icons.clock}
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '-32px',
                  top: '-28px',
                  zIndex: '0',
                }}>
                {Icons.background}
              </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(WelcomeSection);
