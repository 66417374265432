import GlobaleInbox from "./GlobaleInbox";

const MainInbox = (props) => {

  let box = props.box ?? "INBOX";
if(props.box){
  box = props.box;
}else{
  box = "INBOX";

}
  return (
    <GlobaleInbox folder={box} />
  );
};

export default MainInbox;
