import React from "react";

// Import Chart Js
import BarChart from "../AllCharts/chartjs/barchart";
import LineChart from "../AllCharts/chartjs/linechart";
import PieChart from "../AllCharts/chartjs/piechart";
import DountChart from "../AllCharts/chartjs/dountchart";
import RadarChart from "../AllCharts/chartjs/radarchart";
import AreaChart from "../AllCharts/chartjs/areachart";

import { Container, Col, Row, Card, CardBody, CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ChartJs = () => {
  document.title = "Chartjs | Synibox - Smart Assistant";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Charts" breadcrumbItem="Chartjs" />

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>Bar Chart</CardTitle>
                  <p className="card-title-desc">
                    Example of bar chart chart js.
                  </p>
                  <BarChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>Line Chart</CardTitle>
                  <p className="card-title-desc">
                    Example of line chart chart js.
                  </p>
                  <LineChart />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>Pie chart</CardTitle>
                  <p className="card-title-desc">
                    Example of line pie chart js.
                  </p>
                  <PieChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>Area chart</CardTitle>
                  <p className="card-title-desc">
                    Example of line area chart js.
                  </p>
                  <AreaChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>Donut chart</CardTitle>
                  <p className="card-title-desc">Example of donut chart js.</p>
                  <DountChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>Radar chart</CardTitle>
                  <p className="card-title-desc">Example of radar chart js.</p>
                  <RadarChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChartJs;
