import {
  EMPTY_MAIL_BOX,
  FETCH_MAILS_REDUX,
  FETCH_SINGLE_MAIL_REDUX,
  SEARCH_MAILS_REDUX,
  APPLY_MAILS_SUCCESSFULL,
  APPLY_MAILS_ERROR,
  FETCH_FOLDERS_REDUX,
  APPLY_FOLDERS_SUCCESSFULL,
  APPLY_FOLDERS_ERROR,
  CANCEL_REQUEST
} from "./actionTypes";

export const fetchMailsRedux = (company_id, currentBox, currentPage, pageSize, old_messages) => {
  return {
    type: FETCH_MAILS_REDUX,
    payload: { company_id, currentBox, currentPage, pageSize, old_messages }
  };
};

export const fetchSingleMailRedux = (message_id, company_id, box) => {
  return {
    type: FETCH_SINGLE_MAIL_REDUX,
    payload: { message_id, company_id, box }
  };
};

export const searchMailsRedux = (search, company_id, currentBox, currentPage, pageSize, old_messages) => {
  return {
    type: SEARCH_MAILS_REDUX,
    payload: { search, company_id, currentBox, currentPage, pageSize, old_messages }
  };
};

export const applyMailsSuccessful = (response, isSearch) => {
  return {
    type: APPLY_MAILS_SUCCESSFULL,
    payload: {
      data: response.data ? { mails: [...(response.old_messages ?? []), ...response.data.mails] } : { mails: [...(response.old_messages ?? [])] },
      currentBox: response.currentBox,
      currentPage: response.currentPage ?? 1,
      totalPages: response.data.totalPages,
      isSearch: isSearch ?? undefined
    }
  };
};

export const applyMailsError = (response) => {
  console.log({ error: response });
  return {
    type: APPLY_MAILS_ERROR,
    payload: {
      message: response.message,
      // currentBox: response.currentBox,
      currentPage: response.currentPage
      // totalPages: response.totalPages,
    }
  };
};

export const fetchFoldersRedux = (company_id) => {
  return {
    type: FETCH_FOLDERS_REDUX,
    payload: { company_id }
  };
};

export const applyFoldersSuccessful = (response) => {
  return {
    type: APPLY_FOLDERS_SUCCESSFULL,
    payload: { data: response.data, id: response.id }
  };
};

export const applyFoldersError = (error) => {
  return {
    type: APPLY_FOLDERS_ERROR,
    payload: error
  };
};

export const emptyMailBox = () => {
  return {
    type: EMPTY_MAIL_BOX,
    payload: {}
  };
};

export const cancelRequest = () => ({
  type: CANCEL_REQUEST
});
