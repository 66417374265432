import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import CheckCircleRounded from "../../assets/images/icons/CheckCircleRounded";
import CrossOutlined from "../../assets/images/icons/CrossOutlined";
import axios from "axios";
import { Client, Databases } from "appwrite";
import { fetchWHCompteInfo } from "../../helpers/api_helper";
import CustomToast from "../../components/Common/customToast/CustomToast";

function WhatsappSettings(props) {
  const [wh_comptes, setWHComptes] = useState(undefined);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const [isWhConnected, setIsWhConnected] = useState(undefined);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [toast, setToast] = useState(false);

  function onWhConnected(val) {
    setIsWhConnected(val);
  }

  async function handleLogout() {
    setIsLogoutLoading(true);
    const data = {
      compannyId: localStorage.getItem("companyId")
    };
    try {
      const response = await axios.post("https://meta-app.semecloud.tech/auth/disconnect", data);
      console.log({ logoutResponse: response });
      setIsWhConnected(false);
      setIsLogoutLoading(false);
      setToastMessage({
        header: props.t("Succès"),
        body: props.t("Déconnexion effectuée"),
        key: "success"
      });
    } catch (error) {
      console.log({ logoutResponse: error });
      setIsLogoutLoading(false);
      setToastMessage({
        header: props.t("Echec"),
        body: error.message,
        key: "danger"
      });
    }
  }

  async function handleLogin() {
    setIsLoginLoading(true);

    try {
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
      window.location.href = `https://meta-app.semecloud.tech/auth/connect/meta?userId=${user_id}&companyId=${localStorage.getItem("companyId")}`;
    } catch (error) {
      console.log(error);
    }
    setIsLoginLoading(false);
  }

  const onGlobalLoadingChanged = (val) => {
    setIsGlobalLoading(val);
  };

  async function initWhatsappConfig(clientInfos) {
    console.log({
      whatsappConnections: clientInfos.whatsappConnections
    });
    let accounts = [];
    let business_ids = [];
    for (let i = 0; i < clientInfos.whatsappConnections.length; i++) {
      const account = clientInfos.whatsappConnections[i];
      business_ids.push(clientInfos.whatsappConnections[i].whatsapp_account_id);
      const res = await fetchWHCompteInfo(account.access_token, account.whatsapp_account_id);
      if (res && res.data.length > 0) {
        accounts = [...res.data];
      }
    }
    console.log({
      whatsappConnectionsAccounts: accounts,
      whatsappConnectionsBusinessId: business_ids
    });

    setWHComptes(accounts);

    if (clientInfos.whatsappConnections && clientInfos.whatsappConnections.length > 0) {
      onWhConnected(true);
    } else {
      onWhConnected(false);
    }
  }

  const getClientData = async function () {
    onGlobalLoadingChanged(true);
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

    try {
      const promise = databases.getDocument("default", "client_infos", localStorage.getItem("companyId"));

      promise.then(
        async function (response) {
          console.log("getClientData", response);
          const clientInfos = response;
          await initWhatsappConfig(clientInfos);
          onGlobalLoadingChanged(false);
        },
        function (error) {
          onGlobalLoadingChanged(false);
          console.log(error);
          return null;
        }
      );
    } catch (error) {
      console.log(error);
      onGlobalLoadingChanged(false);
    }
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <div>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: "9999999" }}>
        <CustomToast isOpen={toast} toggle={toggleToast} message={toastMessage} />
      </div>

      {isGlobalLoading === true ? (
        <div style={{ textAlign: "center", fontSize: "14px" }}>
          <Spinner className="me-1" style={{ height: "15px", width: "15px" }} animation="border" />
          {props.t("Chargement") + "..."}
        </div>
      ) : (
        <div className="mt-3">
          {isWhConnected === undefined ? (
            <div style={{ textAlign: "center", fontSize: "14px" }}>
              <Spinner className="me-1" style={{ height: "15px", width: "15px" }} animation="border" />
              {props.t("Chargement") + "..."}
            </div>
          ) : (
            <div className="row mx-auto col-xxl-9 col-xl-11">
              <div
                style={{
                  border: "1px solid",
                  borderColor: "#E9ECEF",
                  borderRadius: "10px",
                  padding: "20px",
                  margin: "0 auto"
                }}>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    color: isWhConnected === true ? "black" : "#8590a5"
                  }}>
                  1. {props.t("Authentifiez")}
                </div>
                {isWhConnected === true ? (
                  <div>
                    <div className="mt-2">
                      {props.t("Connexion effectuée")}{" "}
                      <span style={{ color: "#563BFF" }}>
                        <CheckCircleRounded width={24} height={24} />
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        marginTop: "10px",
                        border: "1px solid",
                        borderColor: "#E9ECEF",
                        borderRadius: "10px",
                        padding: "5px 10px 5px 10px",
                        cursor: "pointer",
                        width: "fit-content",
                        backgroundColor: "#f6f6f6"
                      }}
                      onClick={() => {
                        handleLogout();
                      }}>
                      <CrossOutlined width={22} height={22} style={{ color: "red" }} /> {props.t("Déconnexion")}
                      {isLogoutLoading && <span className="spinner-border spinner-border-sm ms-2 font-primary" role="status" aria-hidden="true" />}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        border: "1px solid",
                        borderColor: "#E9ECEF",
                        borderRadius: "10px",
                        marginTop: "10px",
                        padding: "5px 10px 5px 10px",
                        cursor: "pointer",
                        width: "fit-content"
                      }}
                      onClick={() => {
                        handleLogin();
                      }}>
                      <i className="mdi mdi-whatsapp me-1" style={{ fontSize: "22px", color: "#563BFF" }} /> {props.t("Authentification")}
                      {isLoginLoading && <span className="spinner-border spinner-border-sm ms-2 font-primary" role="status" aria-hidden="true" />}
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  border: "1px solid",
                  borderColor: "#E9ECEF",
                  borderRadius: "10px",
                  padding: "20px",
                  marginTop: "40px",
                  color: isWhConnected === true ? "black" : "#8590a5"
                }}>
                <div
                  style={{
                    fontWeight: "600",
                    marginBottom: "10px",
                    fontSize: "18px"
                  }}>
                  2. {props.t("Comptes WhatsApp Connectés")}
                </div>
                <div>
                  {wh_comptes && wh_comptes.length > 0 ? (
                    <div
                      className="d-flex ps-4"
                      style={{
                        flexDirection: "column",
                        width: "80%"
                      }}>
                      {wh_comptes.map((compte, idx) => (
                        <Card
                          key={idx}
                          className="user-card font-primary"
                          style={{
                            borderLeft: "5px solid #563BFF",
                            marginTop: "10px",
                            marginBottom: "12px",
                            borderRadius: "10px",
                            boxShadow: "0px 6px 10px 1px rgba(53, 22, 252, 0.1)",
                            paddingTop: "6px",
                            paddingRight: "29px",
                            paddingBottom: "10px",
                            paddingLeft: "16px",
                            color: "black"
                          }}>
                          <CardBody className="p-0">
                            <Row>
                              <Col md={5} className="d-flex align-items-center" style={{ fontWeight: "600" }}>
                                {compte.verified_name}
                              </Col>
                              <Col md={5} className="d-flex align-items-center" style={{ fontWeight: "600" }}>
                                {compte.display_phone_number}
                              </Col>
                              <Col md={2} className="align-items-center">
                                <button
                                  onClick={(e) => {
                                    // handleRemoveWHCompte(compte.id);
                                  }}
                                  className="p-0"
                                  style={{
                                    background: "none",
                                    border: "none",
                                    color: "#F10944",
                                    float: "inline-end"
                                  }}>
                                  {deleteLoading[compte.id] ? (
                                    <span className="spinner-border spinner-border-sm ms-2 font-primary" role="status" aria-hidden="true" />
                                  ) : (
                                    <CrossOutlined width={20} height={20} />
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center">{props.t("Vide")}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default withTranslation()(WhatsappSettings);
