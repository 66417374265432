import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import classnames from "classnames";
import getFrenchTag from "../../helpers/local_text_helper";
import { withTranslation } from "react-i18next";
import { Client, Account, Databases, ID, Query } from "appwrite";
import ChevronLeftOutlined from "../../assets/images/icons/ChevronLeftOutlined";
import ChevronRightOutlined from "../../assets/images/icons/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import RangeDatepicker from "../../components/Common/rangeDatepicker/RangeDatepicker";
import { addDays } from "date-fns";
import logo_maaia from "../../assets/images/companies/img-3.png";
import { useFormik } from "formik";
import {
  createLog,
  formatDate,
  getLanguageVersion
} from "../../helpers/api_helper";
import * as Yup from "yup";
import CustomToast from "../../components/Common/customToast/CustomToast";
import StaticData from "../../static/data";
import { tr } from "date-fns/locale";
import { useMixpanel } from "../../Hooks/useMixpanel";
import AlertTriangleGestalt from "../../assets/images/icons/AlertTriangleGestalt";
import WrenchGestalt from "../../assets/images/icons/WrenchGestalt";

const eventTypes = ["exhausted_credit", "maintenance"];

function AllNotification(props) {
  document.title = props.t("Notification") + " | Synibox - Smart Assistant";
  const [allNotifications, setAllNotifications] = useState([]);
  const [groupedNotifications, setGroupedNotifications] = useState();
  const [expandedAlert, setExpandedAlert] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 100;
  const [loading, setLoading] = useState(true);
  const { mixPanelTrack } = useMixpanel();

  const [modal_list, setmodal_list] = useState(false);
  const [modal_list_edit, setmodal_list_edit] = useState(false);

  const [selectedDates, setSelectedDates] = useState({
    first: Date.now() - 7 * 24 * 60 * 60 * 1000,
    last: Date.now()
  });
  const [btn, setbtn] = useState(false);
  const [searchType, setSearchType] = useState(undefined);
  const divRef = useRef(null);
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState({});
  const [toast, setToast] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [client_data, setCompanyInfos] = useState(undefined);
  const [selected_notification, seletectNotification] = useState(undefined);

  const [modalInfo, setModalInfo] = useState({
    id: "", // Initialisez ces valeurs à vide
    statusText: "",
    information: "",
    date: ""
  });

  const onSeletectNotification = (val) => {
    seletectNotification(val);
  };

  const svgs = {
    plus_icon: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.01667 13.7836C6.62474 13.7836 6.32189 13.6767 6.10811 13.4629C5.89433 13.2313 5.78744 12.9195 5.78744 12.5276V8.14517H1.48517C1.11106 8.14517 0.817111 8.04719 0.603333 7.85123C0.389556 7.63745 0.282667 7.34351 0.282667 6.96939C0.282667 6.59528 0.389556 6.31025 0.603333 6.11428C0.817111 5.9005 1.11106 5.79362 1.48517 5.79362H5.78744V1.54478C5.78744 1.15286 5.89433 0.850005 6.10811 0.636227C6.32189 0.422449 6.63365 0.315561 7.04339 0.315561C7.43532 0.315561 7.72926 0.422449 7.92522 0.636227C8.139 0.850005 8.24589 1.15286 8.24589 1.54478V5.79362H12.5482C12.9401 5.79362 13.234 5.9005 13.43 6.11428C13.6438 6.31025 13.7507 6.59528 13.7507 6.96939C13.7507 7.34351 13.6438 7.63745 13.43 7.85123C13.234 8.04719 12.9401 8.14517 12.5482 8.14517H8.24589V12.5276C8.24589 12.9195 8.139 13.2313 7.92522 13.4629C7.72926 13.6767 7.42641 13.7836 7.01667 13.7836Z"
          fill="currentColor"
        />
      </svg>
    ),
    edit_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M0 15.6448V18.9334C0 19.2363 0.237992 19.4743 0.54089 19.4743H3.8295C3.97013 19.4743 4.11077 19.4202 4.20813 19.312L16.0212 7.50978L11.9645 3.4531L0.162267 15.2553C0.054089 15.3635 0 15.4933 0 15.6448ZM19.1535 4.37308C19.5754 3.95119 19.5754 3.26967 19.1535 2.84777L16.6221 0.316405C16.42 0.113839 16.1456 0 15.8594 0C15.5733 0 15.2989 0.113839 15.0968 0.316405L13.1171 2.29606L17.1738 6.35274L19.1535 4.37308Z"
          fill="#ccc"
        />
      </svg>
    ),
    delete_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
      >
        <path
          d="M23.4933 10.5732L22.595 24.3845C22.5409 25.2252 22.1809 26.0133 21.5875 26.5897C20.9942 27.1661 20.2118 27.4878 19.3983 27.4899H8.595C7.78152 27.4878 6.99912 27.1661 6.40579 26.5897C5.81246 26.0133 5.45241 25.2252 5.39833 24.3845L4.5 10.5732H23.4933ZM17.6273 6.94824H10.366C10.0426 6.94811 9.72377 6.86843 9.43587 6.71576C9.14796 6.5631 8.89918 6.34181 8.70997 6.07011C8.52077 5.79841 8.39656 5.48406 8.34757 5.15294C8.29858 4.82181 8.3262 4.48336 8.42817 4.16545L8.819 2.95591C8.95372 2.53494 9.21316 2.16868 9.56044 1.90923C9.90771 1.64977 10.3251 1.51033 10.7533 1.51074H17.2377C17.6663 1.51028 18.0842 1.6498 18.4319 1.90947C18.7796 2.16913 19.0394 2.53573 19.1743 2.95712L19.5628 4.16424C19.6653 4.48213 19.6933 4.82068 19.6446 5.15199C19.5959 5.4833 19.4718 5.79787 19.2827 6.06977C19.0935 6.34167 18.8447 6.56312 18.5567 6.71586C18.2687 6.86859 17.9497 6.94824 17.6262 6.94824H17.6273ZM10.7545 3.32324C10.6934 3.32284 10.6337 3.3425 10.584 3.3794C10.5343 3.41631 10.4972 3.46856 10.478 3.52866L10.0883 4.7382C10.0737 4.78378 10.0697 4.83233 10.0768 4.87981C10.0839 4.92729 10.1018 4.97234 10.1291 5.01121C10.1563 5.05008 10.1922 5.08166 10.2336 5.10332C10.275 5.12498 10.3208 5.13609 10.3672 5.13574H17.6285C17.6749 5.13596 17.7207 5.1247 17.7621 5.1029C17.8035 5.08109 17.8392 5.04937 17.8664 5.01037C17.8936 4.97137 17.9113 4.92622 17.9182 4.87867C17.9252 4.83112 17.921 4.78255 17.9062 4.73699L17.5177 3.52987C17.4983 3.46954 17.461 3.4171 17.4111 3.38C17.3613 3.3429 17.3014 3.32304 17.24 3.32324H10.7545Z"
          fill="#F10944"
        />
        <path
          d="M11.6641 20.2396C11.432 20.2396 11.2094 20.1441 11.0453 19.9741C10.8813 19.8042 10.7891 19.5737 10.7891 19.3333V14.5C10.7891 14.2596 10.8813 14.0291 11.0453 13.8592C11.2094 13.6892 11.432 13.5938 11.6641 13.5938C11.8961 13.5938 12.1187 13.6892 12.2828 13.8592C12.4469 14.0291 12.5391 14.2596 12.5391 14.5V19.3333C12.5391 19.5737 12.4469 19.8042 12.2828 19.9741C12.1187 20.1441 11.8961 20.2396 11.6641 20.2396ZM16.3307 20.2396C16.0987 20.2396 15.8761 20.1441 15.712 19.9741C15.5479 19.8042 15.4557 19.5737 15.4557 19.3333V14.5C15.4557 14.2596 15.5479 14.0291 15.712 13.8592C15.8761 13.6892 16.0987 13.5938 16.3307 13.5938C16.5628 13.5938 16.7854 13.6892 16.9494 13.8592C17.1135 14.0291 17.2057 14.2596 17.2057 14.5V19.3333C17.2057 19.5737 17.1135 19.8042 16.9494 19.9741C16.7854 20.1441 16.5628 20.2396 16.3307 20.2396Z"
          fill="#EDEBEA"
        />
        <path
          d="M23.3333 5.13574H4.66667C4.12518 5.13574 3.60588 5.35853 3.22299 5.75509C2.8401 6.15165 2.625 6.6895 2.625 7.25033C2.625 7.81115 2.8401 8.349 3.22299 8.74556C3.60588 9.14212 4.12518 9.36491 4.66667 9.36491H23.3333C23.8748 9.36491 24.3941 9.14212 24.777 8.74556C25.1599 8.349 25.375 7.81115 25.375 7.25033C25.375 6.6895 25.1599 6.15165 24.777 5.75509C24.3941 5.35853 23.8748 5.13574 23.3333 5.13574Z"
          fill="#F10944"
        />
      </svg>
    ),
    close_icon: (
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.37 19.6142C19.0771 19.8983 18.778 20.038 18.4725 20.0334C18.1504 20.0115 17.8473 19.8542 17.5632 19.5613L12.9511 14.8068L8.27403 19.3437C7.98118 19.6278 7.68204 19.7675 7.3766 19.7629C7.07117 19.7582 6.78476 19.6181 6.5174 19.3425C6.25003 19.0668 6.11866 18.7763 6.12331 18.4709C6.12795 18.1654 6.2767 17.8707 6.56955 17.5866L11.2466 13.0497L6.78484 8.45016C6.48405 8.14008 6.33598 7.83232 6.34062 7.52688C6.34527 7.22145 6.50263 6.91834 6.81271 6.61755C7.10556 6.33347 7.39609 6.2021 7.6843 6.22346C7.98973 6.2281 8.29284 6.38546 8.59364 6.69554L13.0554 11.295L17.7324 6.75812C18.0252 6.47404 18.3244 6.33432 18.6298 6.33896C18.9353 6.34361 19.2217 6.48374 19.489 6.75937C19.7564 7.03499 19.8878 7.32552 19.8831 7.63096C19.8785 7.93639 19.7297 8.23115 19.4369 8.51523L14.7598 13.0522L19.372 17.8067C19.6561 18.0996 19.8041 18.4073 19.8162 18.73C19.8288 19.0187 19.68 19.3135 19.37 19.6142Z"
          fill="currentColor"
        />
      </svg>
    )
  };

  const getClientData = async function () {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.getDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId")
    );

    promise.then(
      function (response) {
        console.log("getClientData", response);
        onCompanyInfoChanged(response);
        const clientInfos = response;
        console.log(
          "clientInfos.company_data avant le parsing :",
          clientInfos.company_data
        );

        const parsedCompanyData = clientInfos.company_data.map((item) => {
          try {
            return JSON.parse(item);
          } catch (error) {
            console.error("Erreur de parsing JSON :", error);
            return null;
          }
        });

        setTopicList(parsedCompanyData);
        // return response;
      },
      function (error) {
        console.log(error); // Failure
        return null;
      }
    );
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  async function updateCompanyInfos(data) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId"),
      {
        company_data: data.map((item) => JSON.stringify(item, null, 4))
      }
    );
    return promise.then(
      async function (response) {
        console.log(response); // Success
        return response;
      },
      function (error) {
        //   onDeleteLoadingChanged(false);
        setToastMessage({
          header: props.t("Echec"),
          body: error.message,
          key: "danger"
        });
        toggleToast();
        console.log(error); // Failure
        return null;
      }
    );
  }

  const onLoadingChanged = (val) => {
    setLoading(val);
  };

  const handleModalUpdate = async (notification) => {
    // Créez une nouvelle entrée à partir de modalInfo
    // const newItem = {
    //   id: Date.now(),
    //   statusText: modalInfo.statusText,
    //   information: modalInfo.information,
    //   date: Date.now()
    // };

    // const updatedList = [...topicList, newItem];
    // setItemStates(
    //   Array(updatedList.length).fill({
    //     spanVisible: false,
    //     buttonDisabled: false,
    //     element_loading: false,
    //   })
    // );
    // console.log({ updatedList });
    //  setTopicList(updatedList);
    // setModalInfo({
    //   id: "",
    //   statusText: "",
    //   information: "",
    //   date: ""
    // });

    onLoadingChanged(true);

    // const response = await updateCompanyInfos(updatedList);
    // if (!response) {
    //   onLoadingChanged(false);
    //   return;
    // }

    const response = await updateNotification(notification);

    const newList = allNotifications.filter(
      (notif) => notif.$id !== notification.$id
    );
    setAllNotifications(newList);
    setGroupedNotifications(groupNotifications(newList));

    // tog_list();
    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    let logData = {
      eventCreatedAt: response?.$createdAt,
      author_id: user_id,
      company_id: client_data.$id
    };
    let logdata = {
      event: "notification_disabled",
      author_id: user_id,
      company_id: client_data.$id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);
    console.log("response message: ", response);
    onLoadingChanged(false);
    toggleToast();
    setToastMessage({
      header: props.t("Succès"),
      body: props.t("Modifications effectuées"),
      key: "success"
    });
  };

  const formik = useFormik({
    initialValues: {
      status_field: "",
      information: ""
    },
    validationSchema: Yup.object({
      //   status_field: Yup.string().required("Veuillez sélectionner un tag."),
      information: Yup.string()
        .required("add-res_error_msg_2")
        .max(500, "add-res_error_msg_3")
    }),
    onSubmit: (values) => {
      handleModalUpdate();
    }
  });

  const onExpandedAlert = (id) => {
    setExpandedAlert(id);
  };
  // Fonction pour faire défiler vers le haut
  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const stringToColour = (str) => {
    let hash = 0;
    str.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }

    // Vérifiez si la couleur générée est dans la plage de jaune
    if (/^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(colour)) {
      const hexValue = colour.replace("#", "");
      const r = parseInt(hexValue.slice(0, 2), 16);
      const g = parseInt(hexValue.slice(2, 4), 16);
      const b = parseInt(hexValue.slice(4, 6), 16);

      // YELLOW
      if (r >= 200 && g >= 200 && b < 100) {
        colour = "#FF5733";
      }

      // WHITE
      const whiteThreshold = 200;
      if (r >= whiteThreshold && g >= whiteThreshold && b >= whiteThreshold) {
        colour = "#333333";
      }
    }

    return colour;
  };

  const companyId = localStorage.getItem("companyId");

  function toStartOfDay(date) {
    date.setHours(0, 0, 0, 0); // Début du jour
    return date;
  }

  function toEndOfDay(date) {
    date.setHours(23, 59, 59, 999); // Fin du jour
    return date;
  }

  async function getNotification(page, dates, searchType) {
    setLoading(true);
    try {
      if (!companyId) {
        return;
      }
      const client = new Client();
      const databases = new Databases(client, "default");
      client
        .setEndpoint(process.env.REACT_APP_DATABASEURL)
        .setProject(process.env.REACT_APP_PROJECTID);

      const cursorBefore = page < currentPage;
      const cursor = searchType
        ? undefined
        : allNotifications.length > 0
        ? cursorBefore
          ? allNotifications[0].$id
          : allNotifications[allNotifications.length - 1].$id
        : undefined;

      const Queries = props.from_dashboard
        ? [
            // Query.equal("company_id", companyId),
            Query.equal("company_id", [
              localStorage.getItem("companyId"),
              "all"
            ]),
            Query.equal("seen", true),
            Query.limit(100),
            Query.orderDesc("$createdAt")
          ]
        : [
            Query.equal("seen", false),
            // Query.equal("company_id", companyId),
            Query.equal("company_id", [
              localStorage.getItem("companyId"),
              "all"
            ]),
            Query.orderDesc("$createdAt"),
            Query.limit(limit),
            Query.greaterThanEqual(
              "$createdAt",
              toStartOfDay(new Date(dates ? dates.first : selectedDates.first))
            ),
            Query.lessThanEqual(
              "$createdAt",
              toEndOfDay(
                addDays(new Date(dates ? dates.last : selectedDates.last), 1)
              )
            )
          ];
      if (cursor && page) {
        if (cursorBefore) {
          Queries.push(Query.cursorBefore(cursor));
        } else {
          Queries.push(Query.cursorAfter(cursor));
        }
      }
      if (searchType) {
        Queries.push(Query.equal("type", searchType));
      }
      const response = await databases.listDocuments(
        "default",
        "notifications",
        Queries
      );
      setCurrentPage(page ?? 1);
      setTotalPages(Math.ceil(response.total / limit));
      setAllNotifications(response.documents);
      setGroupedNotifications(groupNotifications(response.documents));
    } catch (error) {
      console.error("Failed to fetch alert data:", {
        error,
        gte: toStartOfDay(new Date(dates ? dates.first : selectedDates.first)),
        lte: toEndOfDay(
          addDays(new Date(dates ? dates.last : selectedDates.last), 1)
        )
      });
    }
    setLoading(false);
  }

  function tog_list(alert) {
    setmodal_list(!modal_list);
    if (modal_list) {
      if (!formik.isValid) {
        formik.resetForm(formik.initialValues);
      }
    }
  }

  async function updateAlert(notif_id, group) {
    console.log("updateNotification", notif_id);
    const client = new Client();
    const databases = new Databases(client, "default");
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
    try {
      await databases.updateDocument("default", "notifications", notif_id, {
        seen: false
      });
      console.log("Alert deleted successfully");
      setGroupedNotifications({
        ...groupedNotifications,
        [group]: groupedNotifications[group].filter(
          (notif) => notif.$id !== notif_id
        )
      });
      console.log("updated alerts", {
        ...groupedNotifications,
        [group]: groupedNotifications[group].filter(
          (notif) => notif.$id !== notif_id
        )
      });
    } catch (error) {
      console.log("Error updating alert:", error);
    }
  }

  const handleNotificationConsult = (alert) => {
    handleNavigate("/mailbox", { id: alert.client_message_id });
  };
  const handleNavigate = (url, state) => {
    // Remplacez cela par la valeur que vous souhaitez passer
    navigate(url, {
      replace: true,
      state: state
    });
  };
  // Obtient la date d'aujourd'hui à minuit
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Groupe les Alertes par période depuis la réception
  const groupNotifications = (sortedNotifications) => {
    const sorte = sortedNotifications.reduce((groups, notif) => {
      const createdAt = new Date(notif.$createdAt);
      const diffTime = today - createdAt;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Différence en jours

      console.log({
        diffDays: {
          createdAt: createdAt,
          diffTime: diffTime,
          diffDays: diffDays
        }
      });

      let label = "";
      if (diffDays === 0) {
        label = props.t("Aujourd'hui");
      } else if (diffDays === 1) {
        label = props.t("Hier");
      } else if (diffDays <= 3) {
        label = "3 " + props.t("jours");
      } else if (diffDays <= 7) {
        label = "1 " + props.t("semaine");
      } else if (diffDays <= 14) {
        label = props.t("Plus d'une") + " " + props.t("semaine");
      } else if (diffDays <= 30) {
        label = "1 " + props.t("mois");
      } else if (diffDays <= 60) {
        label = props.t("Plus d'un") + " " + props.t("mois");
      } else if (diffDays <= 90) {
        label = "3 " + props.t("mois");
      } else {
        label = props.t("Ancien");
      }

      if (!groups[label]) {
        groups[label] = [];
      }
      groups[label].push(notif);
      return groups;
    }, {});
    console.log({ sortedGroup: sorte });
    return sorte;
  };

  const statusList = StaticData.tags.map((tag, index) => {
    return {
      id: index + 1,
      statusName: tag
    };
  });

  const handleDateChange = (date_first, date_last) => {
    console.log("entrie dates are: ", date_first, date_last);

    setSelectedDates({ first: date_first, last: date_last });
    getNotification(null, { first: date_first, last: date_last }, searchType);
  };

  useEffect(() => {
    getClientData();
  }, [setTopicList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNotification(1);
  }, []);

  // function formatDate(dateString) {
  //   const options = { day: "2-digit", month: "short", year: "2-digit", hour: "2-digit", minute: "2-digit" };
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), options);
  //   // return "";
  // }

  const getNotificationsIcons = (key) => {
    switch (key) {
      case "exhausted_credit":
        return <AlertTriangleGestalt width={18} height={18} />;
      case "maintenance":
        return <WrenchGestalt width={18} height={18} />;
      default:
        break;
    }
  };
  const getNotificationsColors = (key) => {
    switch (key) {
      case "exhausted_credit":
        return "#ff3d60";
      case "maintenance":
        return "rgb(86, 59, 255)";
      default:
        break;
    }
  };

  async function updateNotification(data) {
    const client = new Client();
    const databases = new Databases(client, "default");

    const notification = {
      seen: true
    };

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const response = await databases.updateDocument(
      "default",
      "notifications",
      data.$id,
      JSON.stringify(notification)
    );

    return response;
  }

  return (
    <React.Fragment>
      <div
        className="position-fixed bottom-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <CustomToast
          isOpen={toast}
          toggle={toggleToast}
          message={toastMessage}
        />
      </div>

      <div>
        <div
          className="page-content d-flex flex-column align-items-center"
          style={{ height: "100vh" }}
        >
          <Container
            fluid
            className="px-3"
            style={{
              padding: "0",
              width: "60%",
              overflow: "auto"
            }}
          >
            <div ref={divRef}></div>
            <div
              className="page-title-box d-sm-flex align-items-end justify-content-between"
              style={{
                paddingLeft: "0",
                paddingRight: "0"
              }}
            >
              <h4
                className="page-title font-primary"
                style={{ marginTop: "18px" }}
              >
                {props.t("Notifications")}{" "}
                {allNotifications
                  ? "(" +
                    (allNotifications.length > 99
                      ? "+100"
                      : allNotifications.length) +
                    ")"
                  : ""}
              </h4>
              <div
                className="d-flex gap-2"
                style={{
                  position: "relative"
                }}
              >
                <RangeDatepicker
                  range={selectedDates}
                  onChange={handleDateChange}
                />
                <Dropdown isOpen={btn} toggle={() => setbtn(!btn)}>
                  <DropdownToggle
                    tag="button"
                    className="btn p-2"
                    style={{
                      backgroundColor: "#FFEDBB",
                      color: "black",
                      fontWeight: "bold"
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 21.0001H21M9.99999 14.9996H6.99999M21 14.9996H14M9 9.00006H21M13 2.99982H3M21 2.99982H17"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {searchType && (
                      <span
                        style={{
                          position: "absolute",
                          height: "10px",
                          width: "10px",
                          borderRadius: "10px",
                          backgroundColor: "black",
                          top: "0",
                          transform: "translate(25%, -25%)"
                        }}
                      ></span>
                    )}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end p-0">
                    <DropdownItem disabled>
                      <span>{props.t("Trier")} :</span>
                    </DropdownItem>
                    {eventTypes.map((eventType) => (
                      <DropdownItem
                        onClick={() => {
                          if (searchType === eventType) {
                            setSearchType(undefined);
                            getNotification();
                          } else {
                            setSearchType(eventType);
                            getNotification(1, undefined, eventType);
                          }
                        }}
                        active={searchType === eventType}
                      >
                        <span>{props.t(eventType)}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                {searchType && (
                  <div
                    className="col-auto align-content-center justify-content-center"
                    style={{
                      backgroundColor: "#FFEDBB",
                      color: "black",
                      fontSize: "14px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setSearchType(undefined);
                      getNotification();
                    }}
                  >
                    {svgs.close_icon}
                  </div>
                )}
              </div>
            </div>
            <div className="mx-3 d-flex flex-column attention_requested">
              <Row className="h-100" style={{ minHeight: "500px" }}>
                <Card
                  style={{
                    background: "transparent",
                    boxShadow: "none",
                    padding: "0px",
                    marginBottom: "0"
                  }}
                >
                  <CardBody
                    className="d-flex flex-column"
                    style={{
                      padding: "0"
                    }}
                  >
                    <div
                      className="accordion accordion-flush px-0 font-primary"
                      id="accordionFlushExample"
                      style={{
                        flex: "1",
                        overflowY: "auto"
                      }}
                    >
                      {groupedNotifications &&
                        Object.entries(groupedNotifications).map(
                          ([label, notifications], idx) => (
                            <div key={label}>
                              <div className="w-100 d-flex justify-content-center">
                                <h2
                                  className="font-primary font-size-16 mb-4"
                                  style={{
                                    fontWeight: "600",
                                    color: "grey"
                                  }}
                                >{`— ${label} —`}</h2>
                              </div>

                              {notifications.map((notification) => (
                                <div key={idx}>
                                  <div className="text-reset notification-item">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <div className="avatar-xs">
                                          <span
                                            className="avatar-title rounded-circle font-size-16"
                                            style={{
                                              backgroundColor:
                                                getNotificationsColors(
                                                  notification.type
                                                )
                                            }}
                                          >
                                            {getNotificationsIcons(
                                              notification.type
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h6
                                          className="mb-1 font-primary text-muted"
                                          style={{
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                          }}
                                        >
                                          {notification.title}
                                        </h6>
                                        <div className="font-size-12 text-muted">
                                          <p
                                            className="mb-1 font-primary teaser"
                                            style={{
                                              maxWidth: "100%"
                                            }}
                                          >
                                            {notification.message}
                                          </p>
                                          <p className="mb-0 font-primary">
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            {formatDate(
                                              props,
                                              notification.$createdAt,
                                              true
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      {!notification.seen &&
                                        notification.company_id !== "all" && (
                                          <div className="flex-shrink-0 me-3">
                                            <div
                                              className="avatar-xs"
                                              onClick={() => {
                                                handleModalUpdate(notification);
                                                // updateNotification();
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="header-item noti-icon"
                                                style={{
                                                  color: "#FFA412",
                                                  marginTop: "20px"
                                                }}
                                              >
                                                {svgs.close_icon}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      {(!groupedNotifications || loading) && (
                        <div style={{ textAlign: "center", height: "100px" }}>
                          <Spinner color="primary">Loading...</Spinner>
                        </div>
                      )}
                      {!loading &&
                        allNotifications &&
                        allNotifications.length === 0 && (
                          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                            <div className="font-size-22 color-black font-primary">
                              {props.t("Aucun élément")}
                            </div>
                            <img src={logo_maaia} height={40} alt="" />
                          </div>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Row>
            </div>
          </Container>
          {allNotifications && allNotifications.length > 0 && (
            <div
              className="d-flex justify-content-end align-items-center gap-2 pt-2 px-3"
              style={{
                padding: "0",
                width: "60%"
              }}
            >
              <button
                type="button"
                style={{
                  backgroundColor: "black",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px"
                }}
                onClick={() => {
                  if (currentPage > 1) {
                    getNotification(currentPage - 1, undefined, searchType);
                    scrollToTop();
                  }
                }}
              >
                {<ChevronLeftOutlined width={24} />}
              </button>
              <span>{currentPage}</span>
              <span>{props.t("sur")}</span>
              <span>{totalPages}</span>
              <button
                type="button"
                style={{
                  backgroundColor: "black",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px"
                }}
                onClick={() => {
                  if (currentPage < totalPages) {
                    getNotification(currentPage + 1, undefined, searchType);
                    scrollToTop();
                  }
                }}
              >
                {<ChevronRightOutlined width={24} />}
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(AllNotification);
