export const FETCH_MAILS_REDUX = "fetch_mails_redux";
export const FETCH_SINGLE_MAIL_REDUX = "fetch_single_mail_redux";
export const SEARCH_MAILS_REDUX = "search_mails_redux";
export const APPLY_MAILS_SUCCESSFULL = "apply_mails_successfull";
export const APPLY_MAILS_ERROR = "apply_mails_error";
export const FETCH_FOLDERS_REDUX = "fetch_folders_redux";
export const APPLY_FOLDERS_SUCCESSFULL = "apply_folders_successfull";
export const APPLY_FOLDERS_ERROR = "apply_folders_error";
export const EMPTY_MAIL_BOX = "empty_mail_box";
export const CANCEL_REQUEST = "cancel_request";
