const notificationData = [
  {
    key: "high_trafic_alert",
    title: "Alertes de Volume Élevé",
    description: "add_user_notif_1",
  },
  {
    key: "email_transfer",
    title: "Notifications de Transfert de Mail",
    description: "add_user_notif_2",
  },
  {
    key: "urgent_response_alert",
    title: "Alertes de Réponse Urgente",
    description: "add_user_notif_3",
  },
  {
    key: "follow_up_reminder",
    title: "Rappels de Suivi",
    description: "add_user_notif_4",
  },
  {
    key: "tag_updates",
    title: "Mises à Jour des Catégories",
    description: "add_user_notif_5",
  },
  {
    key: "performance_updates",
    title: "Notfications de Performance",
    description: "add_user_notif_6",
  },
  {
    key: "security_alerts",
    title: "Alertes de Sécurité",
    description: "add_user_notif_7",
  },
  {
    key: "important_customer_feedback",
    title: "Feedback Client Important",
    description: "add_user_notif_8",
  },
  {
    key: "system_updates",
    title: "Mises à Jour du Système",
    description: "add_user_notif_9",
  },
  {
    key: "technical_issue_alerts",
    title: "Alertes de Problèmes Techniques",
    description: "add_user_notif_10",
  },
];
export default notificationData;
