export const USER_PLAN_SUCCESS = "USER_PLAN_SUCCESS";
export const USER_PLAN_STATUS = "USER_PLAN_STATUS";
export const FETCH_USER_SUBSCRIPTION = "FETCH_USER_SUBSCRIPTION";
export const USER_PLAN_FREE_TRANSLATION_LIMIT = "USER_PLAN_FREE_TRANSLATION_LIMIT";
export const SUBSCRIPTION_CREDITS = "SUBSCRIPTION_CREDITS";
export const SUBSCRIPTION_INCLUDED_CREDITS = "SUBSCRIPTION_INCLUDED_CREDITS";
export const SUBSCRIPTION_RESSOURCES = "SUBSCRIPTION_RESSOURCES";
export const SUBSCRIPTION_LANGUAGES = "SUBSCRIPTION_LANGUAGES";
export const EMPTY_SUBSCRIPTION = "EMPTY_SUBSCRIPTION";
export const USER_PLAN = "USER_PLAN";
export const LOADING_STATE = "LOADING_STATE";
