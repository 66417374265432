import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  createContext,
  useContext
} from "react";
import PropTypes from "prop-types";
import sidebarData from "./SidebarData";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../../components/Common/withRouter";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import { updateVisibility } from "../../store/actions";
import { UPDATE_VISIBILITY } from "../../store/modal/actionTypes";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { Client, Account, Databases, Query, ID } from "appwrite";
import axios from "axios";
import { UncontrolledTooltip } from "reactstrap";
import { fetchFoldersList, getClientData } from "../../helpers/api_helper";
import InfoMono from "../../assets/images/icons/InfoMono";
const Sidebar = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  //  const [currentEmployee, setCurrentEmployee] = useState(undefined);
  // const [ownerInfos, setOwnerInfos] = useState({});
  const [totalUnseen, setBoxesTotalUnseen] = useState(undefined);
  const [activeMenuIdx, setActiveMenuIdx] = useState(undefined);
  // const [client_data, setCompanyInfos] = useState(undefined);
  const iconRef = useRef(null);
  const { store } = useContext(ReactReduxContext);
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }
        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const getCurrentActive = () => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu-item");
    const items = ul.getElementsByTagName("a");
    // console.log({ items });
    removeActivation(items);
    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = { item: items[i], idx: i };
        break;
      }
    }
    if (matchingMenuItem) {
      return true;
    } else {
      return false;
    }
  };
  const activeMenu =
    // useCallback(
    () => {
      const pathName = props.router.location.pathname;
      const fullPath = pathName;
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu-item");
      const items = ul.getElementsByTagName("a");
      // console.log({ items });
      removeActivation(items);
      for (let i = 0; i < items.length; ++i) {
        if (fullPath === items[i].pathname) {
          matchingMenuItem = { item: items[i], idx: i };
          break;
        }
      }
      if (matchingMenuItem) {
        iconRef.current = matchingMenuItem;
        // console.log("iconRef.current is", iconRef.current);
        activateParentDropdown(matchingMenuItem.item);
        setActiveMenuIdx(matchingMenuItem.idx);
      }
    };
  // , [props.router.location.pathname, activateParentDropdown])
  async function getEmployeeLink() {
    var user_data_string = localStorage.getItem("authUser");

    if (!user_data_string) return;

    var user = JSON.parse(user_data_string);

    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    try {
      const result = await databases.listDocuments("default", "employee_link", [
        Query.equal("employee_id", user.userId)
      ]);
      console.log("Sidebar.js: Employee link is ", result.documents);
      return result.documents;
    } catch (error) {
      console.log("Error listing Employee link", error); // Failure
      return null;
    }
  }

  // function setDatas(client_data) {
  //   // console.log("setDatas entered", employeeData);
  //   if (client_data.collaborators.length > 0) {
  //     // console.log("employeeData.length > 0");
  //     if (localStorage.getItem("authUser")) {
  //       // // console.log('Got localStorage authUser');
  //       const userId = JSON.parse(localStorage.getItem("authUser")).userId;
  //       //  const employeesList = client_data.collaborators;
  //       const employees = client_data.collaborators;
  //       const currentEmployee = employees.filter((element) => element.id === userId);
  //       if (currentEmployee && currentEmployee.length > 0) {
  //         setCurrentEmployee(currentEmployee[0]);
  //       }
  //       // if (employeeData[0].owner) {
  //       //   console.log("owner is ", JSON.parse(employeeData[0].owner));
  //       //   setOwnerInfos(JSON.parse(employeeData[0].owner));
  //       // }
  //     } else {
  //       // console.log("localStorage authUser is null");
  //     }
  //   } else {
  //     // console.log("employeeData.length is 0");
  //     getUserAccount();
  //   }
  // }

  // async function getCompanyEmployeesData(company_id) {
  //   const client = new Client();
  //   const databases = new Databases(client, "default");

  //   client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  //   const promise = databases.listDocuments("default", "employee", [Query.equal("company_id", company_id)]);

  //   return promise.then(
  //     async function (response) {
  //       const employeeData = response.documents;
  //       if (response.documents.length > 0) {
  //         console.log("Got company employees data for id", company_id, response.documents);
  //       } else {
  //         // console.log("Empty company employees data");
  //         getUserAccount();
  //       }
  //       setDatas(employeeData);
  //       return employeeData;
  //     },
  //     function (error) {
  //       // console.log("Error listing company employees data", error);
  //       // console.log(error); // Failure
  //       return null;
  //     }
  //   );
  // }

  async function createEmployeeLink(employee_account_id, company_id) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.createDocument(
      "default",
      "employee_link",
      ID.unique(),
      { employee_id: employee_account_id, company_id: company_id }
    );

    return promise.then(
      async function (response) {
        // console.log("Employee link created ", response); // Failure
        return response;
      },
      function (error) {
        console.log("Error creating Employee link", error.message); // Failure
        return null;
      }
    );
  }

  async function triggerTimeout() {
    // console.log("Start timeout from Sidebar.js");
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await getUserAccount();
  }

  // async function createCompanyEmployeesData(owner, employees, company_id) {
  //   const client = new Client();
  //   const databases = new Databases(client, "default");

  //   client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  //   const promise = databases.createDocument("default", "employee", ID.unique(), {
  //     owner: owner,
  //     employees: employees.map((element) => JSON.stringify(element)),
  //     company_id: company_id
  //   });

  //   return promise.then(
  //     async function (response) {
  //       // console.log("Employees list created ", response); // Failure
  //       setDatas([response]);
  //       return response;
  //     },
  //     function (error) {
  //       // console.log("Error creating Employees list", error); // Failure
  //       return null;
  //     }
  //   );
  // }

  // const fetchMailBoxes = async function (company_infos_id) {
  //   // const apiUrl = "https://mailbox.semecloud.tech/get-boxes";
  //   // axios
  //   //   .get(apiUrl, {
  //   //     params: {
  //   //       company_id: company_infos_id,
  //   //     },
  //   //   })
  //   const response = await fetchFoldersList(company_infos_id);
  //   if (response && !response.detail) {
  //     // // console.log("all boxes (sidebar)", response);
  //     const theTotalUnseen = response.folders.reduce((total, folder) => {
  //       return (
  //         total +
  //         (folder.status && folder.status.UNSEEN ? folder.status.UNSEEN : 0)
  //       );
  //     }, 0);
  //     setBoxesTotalUnseen(theTotalUnseen);
  //   } else {
  //     // Handle the error
  //     // console.log("Boxes fetch error", error);
  //   }
  // };

  const { client_data, currentEmployee } = useSelector((state) => {
    const theClientData = state.GeneralStateRedux.client_data;
    let theTopicList = [];
    let theCurrentEmployee;
    if (theClientData) {
      console.log({
        theClientDataId: theClientData.$id,
        authUser: localStorage.getItem("authUser")
      });
      const employees = theClientData.collaborators ?? [];
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

      // const employees = employeesList.map((element) => JSON.parse(element));
      const emp = employees.filter((element) => element.id === user_id);
      if (emp && emp.length > 0) {
        // setCurrentEmployee(currentEmployee[0]);
        theCurrentEmployee = emp[0];
      }
    }

    return {
      client_data: theClientData,
      currentEmployee: theCurrentEmployee
    };
  });

  async function getUserAccount() {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = account.get();
    return promise.then(
      async function (response) {
        // console.log("userAccount is", response);
        // setRequest(false);
        //     if(client_data.mail_config){
        //    }
        //fetchMailBoxes(data);
        const user = response;
        // setUserInfos(user);

        let employeeLink = await getEmployeeLink();
        console.log({ the_employeeLink: employeeLink });

        if (!employeeLink) {
          triggerTimeout();
        } else if (employeeLink && employeeLink.length === 0) {
          if (user.prefs && user.prefs["owner"]) {
            const theEmployeeLink = await createEmployeeLink(
              user.$id,
              user.$id
            );
            if (theEmployeeLink) {
              localStorage.setItem("companyId", theEmployeeLink.company_id);
              //  fetchMailBoxes(theEmployeeLink.company_id);
              // await createCompanyEmployeesData(
              //   JSON.stringify({
              //     id: user.$id,
              //     last_name: user.name,
              //     first_name: "",
              //     email: user.email
              //   }),
              //   [],
              //   user.$id
              // );
            } else {
              triggerTimeout();
            }
          }
        } else if (employeeLink && employeeLink.length > 0) {
          localStorage.setItem("companyId", employeeLink[0].company_id);
          //  fetchMailBoxes(employeeLink[0].company_id);

          // const employees = await getCompanyEmployeesData(employeeLink[0].company_id);
          // console.log({ employees });
          // if (employees && employees.length === 0) {
          //   if (user.prefs && user.prefs["owner"]) {
          //     await createCompanyEmployeesData(
          //       JSON.stringify({
          //         id: user.$id,
          //         last_name: user.name,
          //         first_name: "",
          //         email: user.email
          //       }),
          //       [],
          //       user.$id
          //     );
          //   }
          // }
        }
      },
      function (error) {
        // console.log("Error getting user Account ", error);
        // console.log(error); // Failure
        // setRequest(false);
      }
    );
  }

  // const onCompanyInfoChanged = (val) => {
  //   setCompanyInfos(val);
  // };

  // async function fetchCompanyInfos() {
  //   console.log("start getClientData");

  //   const comp = await getClientData();
  //   onCompanyInfoChanged(comp);
  //   if (comp) {
  //     setDatas(comp);
  //   }
  // }

  useEffect(() => {
    ref.current.recalculate();
  }, []);
  useEffect(() => {
    new MetisMenu("#side-menu-item");
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  // useEffect(() => {
  //   fetchCompanyInfos();

  //   // if (localStorage.getItem("companyId")) {
  //   //   console.log("has companyId in localStorage", localStorage.getItem("companyId"));
  //   //   fetchMailBoxes(localStorage.getItem("companyId"));
  //   // } else {
  //   //   getUserAccount();
  //   // }
  // }, []);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <SimpleBar className="h-100" ref={ref}>
          <div
            id="sidebar-menu"
            className="sidebar-menu"
            style={{
              position: "fixed"
            }}
          >
            <ul
              className="metismenu list-unstyled font-primary h-100"
              id="side-menu-item"
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* {(sidebarData || []).map((item, sidebarDataIndex) =>
                item.isMainMenu ? (
                  <li key={sidebarDataIndex} className="menu-title font-primary">
                    {props.t(item.label)}
                  </li>
                ) : (currentEmployee && currentEmployee.autorizations && item.id && currentEmployee.autorizations.includes(item.id)) ||
                  (localStorage.getItem("authUser") && ownerInfos.id === JSON.parse(localStorage.getItem("authUser")).userId) ? (
                  <>
                    <li
                      id={"UncontrolledTooltip" + sidebarDataIndex}
                      key={sidebarDataIndex}
                      style={{
                        marginTop: item.id === "help" ? "auto" : "0",
                      }}>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={item.url ? item.url : "/#"}
                        className={item.issubMenubadge || item.isHasArrow ? " " : "has-arrow"}>
                        {props.t(item.label) !== "OnBoarding" ? (
                          <>{iconRef && iconRef.current && iconRef.current.idx === sidebarDataIndex ? item.icon_filled : item.icon_outlined}</>
                        ) : (
                          <i
                            className={item.icon}
                            style={{
                              marginRight: "5px",
                              color: "white !important",
                            }}></i>
                        )}

                        {props.t(item.label) === props.t("Email") && (
                          <span
                            className="position-absolute top-0 end-0 badge font-primary"
                            style={{
                              transform: "translateY(-68%)",
                              minWidth: "28px",
                              borderRadius: "6px",
                              boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.10)",
                              font: "normal 500 12px/16px Poppins, sans-serif",
                            }}>
                            {totalUnseen}
                          </span>
                        )}
                        <span className="menu-item-label">{props.t(item.label)}</span>
                      </Link>
                      {item.subItem && (
                        <ul key={sidebarDataIndex} className="sub-menu">
                          {item.subItem.map((item, item_index) => (
                            <li key={item_index}>
                              {item.action ? (
                                <Link
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                  to={item.link ? item.link : "#"}
                                  className={item.subMenu && "has-arrow waves-effect font-primary"}>
                                  <span
                                    className="font-primary"
                                    onClick={() => {
                                      dispatch(updateVisibility(true));
                                    }}>
                                    {props.t(item.sublabel)}
                                  </span>
                                </Link>
                              ) : (
                                <Link
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                  to={item.link ? item.link : "#"}
                                  className={item.subMenu && "has-arrow waves-effect"}>
                                  {" "}
                                  {props.t(item.sublabel)}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <UncontrolledTooltip
                      innerClassName="custom-inner-tooltip"
                      popperClassName="ms-2 custom-tooltip"
                      placement="right"
                      target={"UncontrolledTooltip" + sidebarDataIndex}>
                      {props.t(item.label)}
                    </UncontrolledTooltip>
                  </>
                ) : null
              )} */}
              {(sidebarData || []).map((item, sidebarDataIndex) => {
                const isAuthorized =
                  (currentEmployee &&
                    currentEmployee.autorizations &&
                    item.id &&
                    currentEmployee.autorizations.includes(item.id)) ||
                  (localStorage.getItem("authUser") &&
                    client_data &&
                    client_data.$id ===
                      JSON.parse(localStorage.getItem("authUser")).userId);
                //  const free_plan = (client_data && client_data.plan_id === "free") || (client_data && !client_data.plan_id);

                /*
                if (item.isMainMenu) {
                  return (
                    <li key={sidebarDataIndex} className="menu-title font-primary">
                      {props.t(item.label)}
                    </li>
                  );
                } else if (isAuthorized && client_data) {
                  */
                return (
                  <React.Fragment key={sidebarDataIndex}>
                    <li
                      id={"UncontrolledTooltip" + sidebarDataIndex}
                      style={{
                        marginTop: item.id === "help" ? "auto" : "0"
                      }}
                    >
                      <Link
                        onClick={
                          isAuthorized
                            ? () => {
                                window.scrollTo(0, 0);
                                if (item.action) {
                                  item.action();
                                }
                              }
                            : undefined
                        }
                        to={isAuthorized ? item.url : undefined}
                        className={
                          item.issubMenubadge || item.isHasArrow
                            ? " "
                            : "has-arrow"
                        }
                        style={{
                          // pointerEvents: free_plan && (item.id === "analytics" || item.id === "ressource_center") ? "none" : "auto"
                          cursor: isAuthorized ? "auto" : "not-allowed"
                        }}
                      >
                        {
                          //  props.t(item.label) !== "OnBoarding" ? (
                          <>
                            {iconRef &&
                            iconRef.current &&
                            iconRef.current.idx === sidebarDataIndex
                              ? item.icon_filled
                              : item.icon_outlined}
                          </>
                          //    ) : (
                          // <i
                          //   className={item.icon}
                          //   style={{
                          //     marginRight: "5px",
                          //     color: "white !important"
                          //   }}></i>
                          //    )
                        }
                        {props.t(item.label) === props.t("Email") && (
                          <span
                            className="position-absolute top-0 end-0 badge font-primary"
                            style={{
                              transform: "translateY(-68%)",
                              minWidth: "28px",
                              borderRadius: "6px",
                              boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.10)",
                              font: "normal 500 12px/16px Poppins, sans-serif"
                            }}
                          >
                            {totalUnseen}
                          </span>
                        )}
                        {/* {free_plan && item.id !== "dashboard" && item.id !== "email" && item.id !== "settings" && item.id !== "help" && (
                          <div
                            className="position-absolute bottom-0 end-0 ps-5"
                            style={{
                              transform: "translateY(0%)",

                              minWidth: "28px",
                              borderRadius: "6px",
                              boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.01)",
                              font: "normal 500 12px Poppins, sans-serif"
                              //background: "transparent !important",
                              //  backgroundColor: "transparent !important",
                            }}>
                            <span width={"18px"} height={"18px"} style={{ cursor: "default", color: "red !important" }}>
                              <svg
                                class="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                  fill-rule="evenodd"
                                  d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </div>
                        )} */}

                        <span className="menu-item-label">
                          {props.t(item.label)}
                        </span>
                      </Link>
                      {item.subItem && (
                        <ul className="sub-menu">
                          {item.subItem.map((subItem, item_index) => (
                            <li key={item_index}>
                              {subItem.action ? (
                                <Link
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                  to={subItem.link ? subItem.link : "#"}
                                  className={
                                    subItem.subMenu &&
                                    "has-arrow waves-effect font-primary"
                                  }
                                >
                                  <span
                                    className="font-primary"
                                    onClick={() => {
                                      dispatch(updateVisibility(true));
                                    }}
                                  >
                                    {props.t(subItem.sublabel)}
                                  </span>
                                </Link>
                              ) : (
                                <Link
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                  to={subItem.link ? subItem.link : "#"}
                                  className={
                                    subItem.subMenu && "has-arrow waves-effect"
                                  }
                                >
                                  {props.t(subItem.sublabel)}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <UncontrolledTooltip
                      innerClassName="custom-inner-tooltip"
                      popperClassName="ms-2 custom-tooltip"
                      placement="right"
                      target={"UncontrolledTooltip" + sidebarDataIndex}
                    >
                      {props.t(item.label)}
                    </UncontrolledTooltip>
                  </React.Fragment>
                );
                /*
                }
                return null;
                */
              })}
            </ul>
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};
export default withRouter(withTranslation()(Sidebar));
