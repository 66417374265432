// App.js

import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import EmailConfig from "./EmailConfig";
import Inbox2 from "./MainInbox";
import ReadEmail from "./ReadEmail";

function EmailPageRoot() {
  return (
    <BrowserRouter>
      <nav>
        <ul>
          <li>
            <Link to="/mailbox">Inbox-2</Link>
          </li>
          <li>
            <Link to="/read-email">Read Email</Link>
          </li>
        </ul>
      </nav>

      <Route path="/settings" component={EmailConfig} />
      <Route path="/mailbox" component={Inbox2} />
      <Route path="/read-email" component={ReadEmail} />
    </BrowserRouter>
  );
}

export default EmailPageRoot;
