export default function getBoxLocalName(folder) {
  switch (folder) {
    ///Drafts
    case "INBOX.SMARTAS.Drafts":
      return "Brouillon";
    case "SMARTAS.Drafts":
      return "Brouillon";
    case "Drafts":
      return "Brouillon";
    case "INBOX.INBOX.Drafts":
      return "Brouillon";
    case "INBOX.Drafts":
      return "Brouillon";

    ///Archive
    case "INBOX.SMARTAS.Archive":
      return "Archive";
    case "SMARTAS.Archive":
      return "Archive";
    case "Archive":
      return "Archive";
    case "INBOX.Archive":
      return "Archive";

    ///Junk
    case "INBOX.SMARTAS.Junk":
      return "Corbeille";
    case "INBOX.INBOX.Junk":
      return "Corbeille";
    case "INBOX.Junk":
      return "Corbeille";
    case "Junk":
      return "Corbeille";

    ///Trash
    case "INBOX.SMARTAS.Trash":
      return "Corbeille";
    case "Trash":
      return "Corbeille";
    case "INBOX.INBOX.Trash":
      return "Corbeille";

    ///Untreated
    case "INBOX.SMARTAS.Untreated":
      return "Non traités";
    case "Untreated":
      return "Non traités";
    case "INBOX.Untreated":
      return "Non traités";

    ///Treated
    case "INBOX.SMARTAS.Treated":
      return "Traités";
    case "Treated":
      return "Traités";
    case "INBOX.Treated":
      return "Traités";
    case "Spam":
      return "Spam";

    ///SENT
    case "INBOX.SMARTAS.Sent":
      return "Envoyés";
    case "Sent":
      return "Envoyés";
    case "INBOX.INBOX.Sent":
      return "Envoyés";
    case "INBOX.Sent":
      return "Envoyés";
    case "INBOX.Sent Messages":
      return "Envoyés";
    case "Sent Messages":
      return "Envoyés";
    case "INBOX.INBOX.Sent Items":
      return "Envoyés";
    case "Sent Items":
      return "Envoyés";

    ///Starred
    case "INBOX.SMARTAS.Starred":
      return "Suivis";
    case "Starred":
      return "Suivis";

    ///Deleted
    case "INBOX.SMARTAS.Deleted Messages":
      return "Supprimés";
    case "Deleted Messages":
      return "Supprimés";
    case "INBOX.Deleted Messages":
      return "Supprimés";
    case "Deleted":
      return "Supprimés";
    case "INBOX.Deleted":
      return "Supprimés";
    case "INBOX.Deleted Items":
      return "Supprimés";
    case "Deleted Items":
      return "Supprimés";

    default:
      return folder.includes("INBOX.SMARTAS")
        ? folder.slice(12).charAt(0).toUpperCase() + folder.slice(12).slice(1).toLowerCase()
        : folder.includes("SMARTAS")
        ? folder.slice(6).charAt(0).toUpperCase() + folder.slice(6).slice(1).toLowerCase()
        : folder.charAt(0).toUpperCase() + folder.slice(1).toLowerCase();
  }
}
