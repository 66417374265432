import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Toast,
  ToastHeader,
  ToastBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Spinner,
  CardTitle,
  InputGroup,
  Input,
  InputGroupText,
  Tooltip
} from "reactstrap";

import { Client, Databases } from "appwrite";
import getFrenchTag from "../../helpers/local_text_helper";
import { withTranslation } from "react-i18next";
import {
  createLog,
  getStripeSubscriptionPlanPriceId
} from "../../helpers/api_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import PlusOutlined from "../../assets/images/icons/PlusOutlined";
import CrossOutlined from "../../assets/images/icons/CrossOutlined";
import SearchOutlined from "../../assets/images/icons/SearchOutlined";
import EditFilled from "../../assets/images/icons/EditFilled";
import TrashFilled from "../../assets/images/icons/TrashFilled";
import Icons from "../../static/icons";
import CustomToast from "../../components/Common/customToast/CustomToast";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CalculatorModal from "../../components/Common/CalculatorModal/CalculatorModal";
import { useMixpanel } from "../../Hooks/useMixpanel";

const RessourcesPage = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log({ state });
  const { isUserPlanActive, number_of_ressources } = useSelector((state) => ({
    number_of_ressources: state.subscription.numberOfRessources,
    isUserPlanActive: state.subscription.isUserPlanActive
  }));

  const { mixPanelTrack } = useMixpanel();

  const [toastMessage, setToastMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [client_data, setCompanyInfos] = useState(undefined);
  const [site_infos_more, setSiteInfosMore] = useState("");
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list_edit, setmodal_list_edit] = useState(false);
  const [modal_topics, setModal_topics] = useState(false);
  const [modal_delete_confirm, setModal_DeleteConfirm] = useState(false);
  // let setMyNewList = [];
  const [topicList, setTopicList] = useState([]);
  const [ressourcesList, setRessourcesList] = useState(undefined);
  const [topics_to_show, setTopicsToShow] = useState([]);
  const [topics_to_delete, setTopicsToDelete] = useState(undefined);
  // const [itemStates, setItemStates] = useState(Array(myList.length).fill({ spanVisible: false, buttonDisabled: false, element_loading: false }));
  const [itemStates, setItemStates] = useState([]);
  const [tooltip, setTooltip] = useState(undefined);
  const [show_calculator, showCalculator] = useState(false);
  const [resourceMessage, setResourceMessage] = useState(undefined);

  const [recherche, setRecherche] = useState("");
  const onShowCalculator = () => {
    showCalculator(!show_calculator);
  };

  const handleClick = (val, mod) => {
    // Remplacez cela par la valeur que vous souhaitez passer
    navigate(`/settings`, {
      replace: true,
      state: { tab: val, modal: mod ?? "" }
    });
  };

  //
  const formik = useFormik({
    initialValues: {
      status_field: "",
      customername_field: ""
    },
    validationSchema: Yup.object({
      status_field: Yup.string().required("Veuillez sélectionner un tag."),
      customername_field: Yup.string()
        .required("add-res_error_msg_2")
        .max(500, "add-res_error_msg_3")
    }),
    onSubmit: (values) => {
      handleModalUpdate();
    }
  });

  const searchTopics = (textToResearch) => {
    const textToLowerCase = textToResearch.toLowerCase();
    // Filtrer le tableau pour trouver les éléments qui contiennent le texte de recherche
    const results = topicList.filter(
      (item) =>
        item.information.toLowerCase().includes(textToLowerCase) ||
        props.t(item.statusText).toLowerCase().includes(textToLowerCase)
    );
    console.log("results are: ", results);
    return results;
  };

  const handleBunttonClickForSearch = (research) => {
    let completeTagList = [...client_data.contact_reasons];
    topicList.forEach((topic) => {
      if (!completeTagList.includes(topic.statusText)) {
        completeTagList.push(topic.statusText);
      }
    });
    const updatedRessourcesList = [];
    // if (research.length > 0) {
    const filteredTopics = searchTopics(research);
    completeTagList.forEach((ressource) => {
      filteredTopics.forEach((topic) => {
        if (
          topic.statusText === ressource &&
          !updatedRessourcesList.includes(ressource)
        ) {
          updatedRessourcesList.push(ressource);
        }
      });
    });
    //}
    onRessourceListChanged(updatedRessourcesList, []);
  };

  const handleBunttonClickForSearchOver = (e) => {
    console.log("Here is handleBunttonClickForSearchOver");
    onRessourceListChanged(client_data.contact_reasons);
    setRecherche("");
  };

  // if (loading) {
  //     console.log("toto is: already loading", loading);
  // } else {

  // };

  function tog_list() {
    setmodal_list(!modal_list);
    if (modal_list) {
      if (!formik.isValid) {
        formik.resetForm(formik.initialValues);
      }
      if (state && state.alert_id) {
        navigate("/resources", {
          replace: true,
          state: null
        });
      }
    }
  }

  const tags = [
    "product",
    // "product_availability",
    // "product_advice",
    // "product_problems",
    // "product_price",
    // "promotions",
    // "order_process",
    "order",
    "payments",
    "refunds",
    // "disputes",
    // "billing",
    "delivery",
    "returns",
    "customer_account",
    // "password",
    // "security",
    // "privacy",
    // "customer_data",
    // "after_sales",
    // "technical_problems",
    // "website_feedback",
    // "special_programs",
    // "affiliation",
    "customer_loyalty",
    "feedback",
    // "complaints",
    "company_information",
    // "ethics_social_responsibility",
    // "subscription_management",
    // "store_services",
    "influencers"
    // "content_creators",
    // "spam",
    // "other",
    // "Treated",
    // "Untreated",
  ];

  const statusList = tags.map((tag, index) => {
    return {
      id: index + 1,
      statusName: tag
    };
  });

  const [modalInfo, setModalInfo] = useState({
    id: "", // Initialisez ces valeurs à vide
    statusText: "",
    information: "",
    date: ""
  });

  const handleModalUpdate = async () => {
    // Créez une nouvelle entrée à partir de modalInfo
    const newItem = {
      id: Date.now(),
      statusText: modalInfo.statusText,
      information: modalInfo.information,
      date: Date.now()
    };

    const updatedList = [...topicList, newItem];
    setItemStates(
      Array(updatedList.length).fill({
        spanVisible: false,
        buttonDisabled: false,
        element_loading: false
      })
    );
    console.log(updatedList);
    setTopicList(updatedList);
    setModalInfo({
      id: "", // Initialisez ces valeurs à vide
      statusText: "",
      information: "",
      date: ""
    });

    const response = await updateCompanyInfos(updatedList);
    if (!response) {
      onLoadingChanged(false);
      return;
    }

    if (state && state.alert_id) {
      await updateAlert(state.alert_id);
    }
    tog_list();
    onRessourceListChanged(client_data.contact_reasons, updatedList);
    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    let logData = {
      eventCreatedAt: response?.$createdAt,
      author_id: user_id,
      company_id: client_data.$id,
      topic_tag: newItem.statusText,
      topic_id: newItem.id
    };
    let logdata = {
      event: "topic_added",
      author_id: user_id,
      company_id: client_data.$id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);
    setToastMessage({
      header: props.t("Succès"),
      body: props.t("Modifications effectuées"),
      key: "success"
    });
    console.log("response message: ", response);
    onLoadingChanged(false);
    toggleToast();
  };

  const saveElementData = async () => {
    console.log("saveElementData clicked");
    const response = await updateCompanyInfos(topicList);
    if (!response) {
      onLoadingChanged(false);
      return;
    }
    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    let logData = {
      eventCreatedAt: response?.$createdAt,
      author_id: user_id,
      company_id: client_data.$id,
      topic_tag: editItem.statusText,
      topic_id: editItem.id
    };
    let logdata = {
      event: "topic_updated",
      author_id: user_id,
      company_id: client_data.$id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);
    setToastMessage({
      header: props.t("Succès"),
      body: props.t("Modifications effectuées"),
      key: "success"
    });
    console.log("response message: ", response);
    onLoadingChanged(false);
    toggleToast();
  };

  const removeData = async (id, statusText) => {
    onDeleteLoadingChanged(true);
    const updatedList = [...topicList];
    const elements = updatedList.filter((item) => item.id !== id);
    console.log("removeData", elements);
    const response = await updateCompanyInfos(elements);
    if (!response) {
      onDeleteLoadingChanged(false);
      return;
    }
    onRessourceListChanged(client_data.contact_reasons, elements);
    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    let logData = {
      eventCreatedAt: response?.$createdAt,
      author_id: user_id,
      company_id: client_data.$id,
      topic_tag: statusText
    };
    let logdata = {
      event: "topic_deleted",
      author_id: user_id,
      company_id: client_data.$id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);

    onTopicListChanged(elements);
    const the_topics_to_show = getTopics(statusText, elements);
    onTopicToShowChanged(the_topics_to_show);
    onDeleteLoadingChanged(false);
    setToastMessage({
      header: props.t("Succès"),
      body: props.t("Modifications effectuées"),
      key: "success"
    });
    console.log("response message: ", response);

    toggleToast();
  };

  async function updateCompanyInfos(data) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId"),
      {
        company_data: data.map((item) => JSON.stringify(item, null, 4))
      }
    );
    return promise.then(
      async function (response) {
        console.log(response); // Success
        return response;
      },
      function (error) {
        onDeleteLoadingChanged(false);
        setToastMessage({
          header: props.t("Echec"),
          body: error.message,
          key: "danger"
        });
        toggleToast();
        console.log(error); // Failure
        return null;
      }
    );
  }
  const getTopics = (statusText, updatedList) => {
    if (client_data && (updatedList ?? topicList)) {
      const topics = (updatedList ?? topicList).filter(
        (item) => item.statusText === statusText
      );
      return topics;
    } else {
      return [];
    }
  };

  const getClientData = async function () {
    onLoadingChanged(true);
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.getDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId")
    );

    promise.then(
      function (response) {
        console.log("getClientData", response);
        onCompanyInfoChanged(response);
        const clientInfos = response;
        console.log(
          "clientInfos.company_data avant le parsing :",
          clientInfos.company_data
        );

        const parsedCompanyData = clientInfos.company_data.map((item) => {
          try {
            return JSON.parse(item);
          } catch (error) {
            console.error("Erreur de parsing JSON :", error);
            setResourceMessage("Erreur inattendue");
            return null;
          }
        });
        setTopicList(parsedCompanyData);
        setItemStates(
          Array(parsedCompanyData.length).fill({
            spanVisible: false,
            buttonDisabled: false,
            element_loading: false
          })
        );

        setSiteInfosMore(clientInfos.more_info ?? "");
        onLoadingChanged(false);
        onRessourceListChanged(clientInfos.contact_reasons, parsedCompanyData);
        setResourceMessage("");
        // return response;
      },
      function (error) {
        onLoadingChanged(false);
        console.log(error); // Failure
        setResourceMessage("Erreur inattendue");
        return null;
      }
    );
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const onLoadingChanged = (val) => {
    setLoading(val);
  };

  const onRessourceListChanged = (val, topic_list) => {
    let completeTagList = [...val];
    (topic_list ?? topicList).forEach((topic) => {
      if (!completeTagList.includes(topic.statusText)) {
        completeTagList.push(topic.statusText);
      }
    });
    setRessourcesList(completeTagList);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const onTopicListChanged = (val) => {
    setTopicList(val);
  };

  const onTopicToShowChanged = (val) => {
    setTopicsToShow(val);
  };

  const topicToShowActions = (item) => {
    const the_topics_to_show = getTopics(item);
    onTopicToShowChanged(the_topics_to_show);
    tog_topics();
  };

  const RemoveTopicActions = (topic) => {
    setDeleteLoading(topic.id);
    removeData(topic.id, topic.statusText);
  };

  // function changeColor(tag) {
  //   switch (tag) {
  //     case "product":
  //       return "primary";
  //     case "product_availability":
  //       return "primary";
  //     case "product_advice":
  //       return "primary";
  //     case "product_problems":
  //       return "danger";
  //     case "product_price":
  //       return "primary";
  //     case "promotions":
  //       return "primary";
  //     case "order":
  //       return "danger";
  //     case "order_process":
  //       return "primary";
  //     case "payments":
  //       return "danger";
  //     case "refunds":
  //       return "danger";
  //     case "disputes":
  //       return "danger";
  //     case "billing":
  //       return "warning";
  //     case "delivery":
  //       return "danger";
  //     case "returns":
  //       return "warning";
  //     case "customer_account":
  //       return "info";
  //     case "password":
  //       return "info";
  //     case "security":
  //       return "info";
  //     case "privacy":
  //       return "info";
  //     case "customer_data":
  //       return "info";
  //     case "after_sales":
  //       return "info";
  //     case "technical_problems":
  //       return "primary";
  //     case "website_feedback":
  //       return "info";
  //     case "special_programs":
  //       return "info";
  //     case "affiliation":
  //       return "info";
  //     case "customer_loyalty":
  //       return "info";
  //     case "feedback":
  //       return "info";
  //     case "complaints":
  //       return "warning";
  //     case "company_information":
  //       return "info";
  //     case "ethics_social_responsibility":
  //       return "info";
  //     case "subscription_management":
  //       return "info";
  //     case "store_services":
  //       return "info";
  //     case "influencers":
  //       return "info";
  //     case "content_creators":
  //       return "info";
  //     case "spam":
  //       return "warning";
  //     case "other":
  //       return "info";
  //     default:
  //       return "warning";
  //   }
  // }

  useEffect(() => {
    getClientData();
  }, [setTopicList]);

  // modal

  const [editItem, setTopicToEdit] = useState({ information: "" });
  function tog_list_edit() {
    setmodal_list_edit(!modal_list_edit);
  }

  const onEditTopic = (item) => {
    setTopicToEdit(item);
  };

  const [deleteLoading, setDeleteLoading] = useState();

  const onDeleteLoadingChanged = (item) => {
    setDeleteLoading(item);
  };
  // modal topics
  function tog_topics() {
    setModal_topics(!modal_topics);
  }

  function tog_delete(data) {
    setTopicsToDelete(data);
    setModal_DeleteConfirm(!modal_delete_confirm);
  }

  async function updateAlert(alert_id) {
    // console.log("updateAlert", alert_id);
    const client = new Client();
    const databases = new Databases(client, "default");
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
    try {
      await databases.updateDocument("default", "alert", alert_id, {
        active: false
      });
      // console.log("Alert deleted successfully");
    } catch (error) {
      console.log("Error updating alert:", error);
    }
  }

  useEffect(() => {
    if (state && state.alert_id) {
      tog_list();
    }
  }, [state]);

  const [numberOfRessources, setNumberOfRessources] = useState(0);

  useEffect(() => {
    if (isUserPlanActive) {
      setNumberOfRessources(number_of_ressources);
    } else {
      setNumberOfRessources(0);
    }
  }, [isUserPlanActive, number_of_ressources]);

  return (
    <React.Fragment>
      {/* Topics Modal */}
      <Modal
        isOpen={modal_delete_confirm}
        toggle={() => {
          tog_delete(undefined);
        }}
        centered
        className="user-modal"
      >
        <ModalBody className="p-3">
          <Button
            className="close_button"
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "0px",
              position: "absolute",
              right: "1rem"
            }}
            onClick={() => {
              tog_delete(undefined);
            }}
          >
            <CrossOutlined height={26} />
          </Button>
          <h5
            className="modal_title"
            style={{ marginTop: "4%", marginBottom: "4%" }}
          >
            {props.t("Suppression de ressources")}
          </h5>
          <p className="text-muted">
            {props.t("Voulez-vous vraiment supprimer cette ressource ?")}
          </p>
        </ModalBody>
        <ModalFooter>
          <div
            className="hstack gap-2 justify-content-end"
            style={{ width: "100%" }}
          >
            <button
              type="button"
              className="view-button"
              style={{ color: "black", width: "100%" }}
              onClick={() => tog_delete(undefined)}
            >
              {props.t("Non")}
            </button>
            <button
              type="button"
              style={{ backgroundColor: "#563bff", width: "100%" }}
              className="view-button center font-primary"
              id="edit-btn"
              onClick={() => {
                tog_delete(topics_to_delete);
                RemoveTopicActions(topics_to_delete);
              }}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm me-2 font-primary"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                props.t("Oui")
              )}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modal_topics}
        toggle={tog_topics}
        centered
        className="help-page-modal modal_wrapper__100x100"
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <Button
            className="close_button"
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "0px",
              position: "absolute",
              right: "1rem"
            }}
            onClick={() => {
              tog_topics();
            }}
          >
            <CrossOutlined height={26} />
          </Button>
          <h1
            className="modal_title"
            style={{ marginTop: "4%", marginBottom: "4%" }}
          >
            {props.t("Ressources")}
          </h1>
          <Row
            className="gap-4 mb-5"
            style={{
              marginLeft: "8%",
              marginRight: "8%",
              flex: "1",
              overflow: "auto"
            }}
          >
            <Col
              xl={12}
              className="d-flex gap-3"
              style={{
                flexDirection: "column"
              }}
            >
              {topics_to_show.length > 0 ? (
                topics_to_show.map((topic, index) => (
                  <div
                    key={index}
                    className="help-page-modal-button d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex flex-column col-10">
                      <h3>{props.t(topic.statusText)}</h3>
                      <p
                        className="m-0 simple-text font-primary fw-normal"
                        style={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          "-webkit-box-orient": "vertical",
                          "-webkit-line-clamp": "2"
                        }}
                      >
                        {topic.information}
                      </p>
                    </div>
                    <div className="d-flex" style={{ flexWrap: "nowrap" }}>
                      <Button
                        className="me-xxl-2 me-xl-1"
                        style={{
                          backgroundColor: "transparent",
                          border: "none"
                        }}
                        onClick={() => {
                          onEditTopic(topic);
                          tog_list_edit();
                        }}
                      >
                        <EditFilled style={{ color: "#329993" }} height={26} />
                      </Button>
                      {deleteLoading === topic.id ? (
                        <Spinner animation="border" className="" />
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            padding: "0"
                          }}
                          onClick={() => tog_delete(topic)}
                        >
                          <TrashFilled
                            height={26}
                            style={{ color: "#F10944" }}
                          />
                        </Button>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="font-primary text-center"
                  style={{ fontSize: "18px" }}
                >
                  {props.t("Vide")}
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: "1005" }}
        >
          <CustomToast
            isOpen={toast}
            toggle={toggleToast}
            message={toastMessage}
          />
        </div>
      </Modal>

      <Row>
        <Col
          xs="12"
          xxl={9}
          xl={11}
          className="mx-auto"
          style={{
            marginTop: "40px"
          }}
        >
          <div className="ps-0 pe-0 page-title-box d-sm-flex align-items-center justify-content-between">
            <div
              className="d-flex flex-column"
              style={{ width: "fit-content" }}
            >
              <h4
                className="page-title font-primary"
                style={{ fontSize: "14px" }}
              >
                {props.t("Centre de ressources")}
              </h4>
              <p
                className="mb-0 mt-2 simple-text font-primary"
                style={{
                  color: "#666969",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px"
                }}
              >
                {props.t("Centre de ressources subtitle")}
              </p>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ height: "fit-content", fontSize: "14px" }}
            >
              <Tooltip
                placement="left"
                isOpen={
                  tooltip === "tooltips_add_ressource_button" &&
                  numberOfRessources <=
                    (ressourcesList ? ressourcesList.length : 0)
                }
                target={"tooltips_add_ressource_button"}
                toggle={() => {
                  if (tooltip === "tooltips_add_ressource_button") {
                    setTimeout(() => {
                      setTooltip(undefined);
                    }, 3000);
                  } else {
                    setTooltip("tooltips_add_ressource_button");
                  }
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTooltip(undefined);
                    console.log("yeeeee");
                    onShowCalculator();
                  }}
                >
                  {props.t(
                    "Upgradez votre plan pour pouvoir ajouter plus de ressources"
                  )}
                </span>
              </Tooltip>
              <button
                id={"tooltips_add_ressource_button"}
                className="view-button d-flex align-items-center ressouceAdd-button"
                disabled={
                  numberOfRessources <=
                  (ressourcesList ? ressourcesList.length : 0)
                }
                onClick={() => tog_list()}
                style={{
                  border: "none",
                  backgroundColor: "#563BFF",
                  marginLeft: "auto",
                  cursor:
                    numberOfRessources <=
                    (ressourcesList ? ressourcesList.length : 0)
                      ? "not-allowed"
                      : "pointer"
                }}
              >
                <PlusOutlined height={20} /> {props.t("Ajouter")}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <CalculatorModal
        isOpen={show_calculator}
        toggle={onShowCalculator}
        upgrade_downgrade={true}
      />

      {/* Add Modal */}
      <Modal isOpen={modal_list} toggle={tog_list} centered>
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={tog_list}
        >
          {props.t("Ajouter une ressource")}
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div>
              <label htmlFor="status_field" className="form-label">
                {props.t("Catégories")}
              </label>
              <select
                className={`form-control ${
                  formik.touched.status_field &&
                  formik.errors.status_field &&
                  "border border-danger"
                }`}
                data-trigger
                name="status_field"
                id="status_field"
                value={modalInfo.statusText}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setModalInfo({
                    ...modalInfo,
                    statusText: e.target.value
                    //   information: e.target.value,
                  });
                }}
              >
                <option value="" disabled>
                  {props.t("Aucun")}
                </option>
                {statusList.map((item, index) => (
                  <option key={index} value={item.statusName}>
                    {props.t(item.statusName)}
                  </option>
                ))}
              </select>
              {formik.touched.status_field && formik.errors.status_field && (
                <span className="font-primary text-danger">
                  {props.t("add-res_error_msg_1")}
                </span>
              )}
            </div>
            <div className="my-3">
              <label htmlFor="customername_field" className="form-label">
                {props.t("Informations")}
              </label>
              <textarea
                id="customername_field"
                name="customername_field"
                style={{
                  border: "none",
                  position: "relative" // Rendre le textarea positionnel
                }}
                required
                className={`text-area col-12 form-control ${
                  formik.touched.customername_field &&
                  formik.errors.customername_field &&
                  "border border-danger"
                }`}
                placeholder={props.t("Entrer les informations")}
                rows="3"
                cols="45"
                value={modalInfo.information}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setModalInfo({ ...modalInfo, information: e.target.value });
                }}
              ></textarea>
              {
                <span
                  className={`font-primary mt-1 ${
                    formik.touched.customername_field &&
                    formik.errors.customername_field
                      ? "text-danger"
                      : ""
                  }`}
                  style={{ float: "right" }}
                >{`${modalInfo.information.length}/500`}</span>
              }
              {formik.touched.customername_field &&
                formik.errors.customername_field && (
                  <span className="font-primary text-danger">
                    {props.t(formik.errors.customername_field)}
                  </span>
                )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div
              className="hstack gap-2 justify-content-end"
              style={{ width: "100%" }}
            >
              <button
                type="button"
                className="view-button "
                style={{ color: "black", width: "100%" }}
                onClick={tog_list}
              >
                {props.t("Annuler")}
              </button>
              <button
                type="button"
                className="view-button "
                style={{ backgroundColor: "#563bff", width: "100%" }}
                id="edit-btn"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {props.t("Ajouter")}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <div
        className="position-fixed bottom-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <CustomToast
          isOpen={toast}
          toggle={toggleToast}
          message={toastMessage}
        />
      </div>

      {/* Edit Modal */}
      <Modal isOpen={modal_list_edit} toggle={tog_list_edit} centered>
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={tog_list_edit}
        >
          {props.t("Modifier la ressource")}
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div className="mb-3 d-flex flex-column">
              <label htmlFor="customername-field" className="form-label">
                {props.t("Informations")}
              </label>
              <textarea
                id="customername-field"
                style={{
                  border: "none",
                  position: "relative", // Rendre le textarea positionnel
                  minHeight: "300px"
                }}
                required
                className={`text-area col-12 form-control ${
                  editItem &&
                  editItem.information.length > 500 &&
                  "border border-danger"
                }`}
                rows="3"
                cols="45"
                value={editItem.information}
                onChange={(e) => {
                  const updatedList = [...topicList];
                  const foundIndex = updatedList.findIndex(
                    (item) => item.id === editItem.id
                  );
                  updatedList[foundIndex].information = e.target.value;
                  setTopicList(updatedList);
                }}
              ></textarea>
              <div className="">
                {editItem && editItem.information.length > 500 && (
                  <span className="font-primary text-danger">
                    {props.t("add-res_error_msg_3")}
                  </span>
                )}
                <span
                  style={{ float: "right" }}
                  className={`font-primary mt-1 ${
                    editItem && editItem.information.length > 500
                      ? "text-danger"
                      : ""
                  }`}
                >{`${editItem && editItem.information.length}/500`}</span>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div
              className="hstack gap-2 justify-content-end"
              style={{ width: "100%" }}
            >
              <button
                type="button"
                className="view-button "
                style={{ color: "black", width: "100%" }}
                onClick={tog_list_edit}
              >
                {props.t("Annuler")}
              </button>
              <button
                type="button"
                className="view-button "
                disabled={editItem && editItem.information.length > 500}
                id="edit-btn"
                style={{
                  backgroundColor: "#563bff",
                  width: "100%",
                  cursor:
                    editItem && editItem.information.length > 500
                      ? "not-allowed"
                      : "pointer"
                }}
                onClick={() => {
                  saveElementData();
                  tog_list_edit();
                  toggleToast();
                }}
              >
                {props.t("Modifier")}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      <div className="help-page">
        <div className="mx-auto col-xxl-9 col-xl-11">
          <Card
            style={{
              borderRadius: "10px",
              background: "#563BFF",
              boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.05)",
              paddingTop: "40px"
            }}
          >
            {/* <CardTitle
              className="mb-0 font-primary"
              style={{
                color: "#FFF",
                textAlign: "center",
                fontSize: " 14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "112.5%",
              }}>
              {props.t("Recherche rapide")}
            </CardTitle> */}
            <CardBody className="p-0">
              <InputGroup
                className="mx-auto"
                style={{
                  backgroundColor: "#F9FAFB",
                  borderRadius: "13.511px",
                  marginTop: "40px",
                  marginBottom: "69px",
                  maxWidth: "625px",
                  paddingTop: "10px",
                  paddingLeft: "10px",
                  paddingBottom: "10px"
                }}
              >
                <Input
                  className="font-primary"
                  placeholder={props.t("Recherche rapide")}
                  style={{
                    font: "normal 400 15.2px/normal",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#737791"
                  }}
                  value={recherche}
                  onChange={(e) => {
                    setRecherche(e.target.value);
                    if (e.target.value.length > 2) {
                      handleBunttonClickForSearch(e.target.value);
                    } else if (e.target.value === "") {
                      onRessourceListChanged(client_data.contact_reasons);
                    }
                  }}
                />
                {/* <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}>
                  {recherche.length > 0 ? (
                    // Close Button
                    <button className="btn" style={{ padding: "0", border: "none", color: "#FF7049" }} onClick={handleBunttonClickForSearchOver}>
                      <CrossOutlined height={26} />
                    </button>
                  ) : (
                    // Research Button
                    <button className="btn" style={{ padding: "0", border: "none", color: "#FF7049" }} onClick={handleBunttonClickForSearch}>
                      <SearchOutlined height={26} />
                    </button>
                  )}
                </InputGroupText> */}
              </InputGroup>
            </CardBody>
          </Card>
          <Col style={{ marginTop: "60px", marginBottom: "60px" }}>
            {ressourcesList && ressourcesList !== undefined && (
              <Row className="row-gap-4">
                {ressourcesList.map((item, index) => (
                  <Col xl={4} key={index}>
                    <Card
                      className="mb-0 help__section_card"
                      onClick={() => topicToShowActions(item)}
                    >
                      <CardBody className="d-flex">
                        <span className="me-xl-2 me-xxl-3 my-auto">
                          {Icons[item]}
                        </span>
                        <div
                          className="d-flex flex-column"
                          style={{ height: "fit-content" }}
                        >
                          <div
                            className="help__section_title mb-2 font-primary d-flex flex-wrap gap-2"
                            style={{ fontSize: "14px" }}
                          >
                            <span className="font-size-16">
                              {props.t(item)}
                            </span>
                          </div>
                          <p
                            className="help__section_articles mb-0"
                            style={{ fontSize: "14px" }}
                          >
                            {getTopics(item).length > 1
                              ? `${getTopics(item).length} ${props.t(
                                  "ressources"
                                )}`
                              : `${getTopics(item).length} ${props.t(
                                  "ressource"
                                )}`}
                          </p>
                        </div>
                        <div
                          className="ms-auto simple-text font-primary"
                          style={{ fontSize: "14px" }}
                        >
                          {client_data.contact_reasons.includes(item) ? (
                            <span
                              className="badge badge-soft-success"
                              style={{ fontSize: "14px" }}
                            >
                              {props.t("Actif")}
                            </span>
                          ) : (
                            <span
                              className="badge badge-soft-danger"
                              style={{ fontSize: "14px" }}
                            >
                              {props.t("Inactif")}
                            </span>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
            {resourceMessage === undefined ? (
              <div style={{ textAlign: "center", fontSize: "14px" }}>
                <Spinner
                  className="me-1"
                  style={{ height: "15px", width: "15px" }}
                  animation="border"
                />
                {props.t("Chargement") + "..."}
              </div>
            ) : resourceMessage !== "" ? (
              <div style={{ textAlign: "center", fontSize: "14px" }}>
                {props.t(resourceMessage)}
              </div>
            ) : (
              ressourcesList &&
              ressourcesList.length === 0 && (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h4 className="font-primary">
                    {props.t("Rajoutez ici des informations pour SYNIBOX")}
                  </h4>
                  <p className="font-primary">
                    {props.t("Commencez d'abord par activer une catégorie.")}
                  </p>
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "fit-content", fontSize: "14px" }}
                  >
                    <button
                      className="view-button d-flex align-items-center ressouceAdd-button"
                      onClick={() => {
                        handleClick("general");
                      }}
                      style={{ border: "none", backgroundColor: "#563BFF" }}
                    >
                      {props.t("Activer des ressources")}
                    </button>
                  </div>
                </div>
              )
            )}
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(RessourcesPage);
