import CryptoJS from "crypto-js";

export default function decryptPasswordLocal(encryptedPassword) {
  var secretKey = "7SUfCZgTQ1XxnGKo7SUfCZgTQ1XxnGKo"; // Utilisez une clé secrète
  secretKey = CryptoJS.enc.Utf8.parse(secretKey);
  const ivStatic = CryptoJS.enc.Utf8.parse("1234567890123456");
  const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, {
    iv: ivStatic,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
  //   console.log({ encryptPassword: decryptedPassword });
  return decryptedPassword;
}
