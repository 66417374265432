import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { Client, Account, Databases, Query } from "appwrite";
import { Link, useNavigate } from "react-router-dom";
import getFrenchTag from "../../helpers/local_text_helper";
import Flatpickr from "react-flatpickr";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Line } from "react-chartjs-2";
import logo_maaia from "../../assets/images/companies/img-3.png";
import clients_satisfaction from "../../assets/images/clients_satisfaction.png";
import { withTranslation } from "react-i18next";

const option = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    dataset: [
      {
        display: false,
        gridLines: {
          drawBorder: false,
          offset: true
        },
        ticks: {
          fontColor: "#686868"
        }
      }
    ],
    dataset1: [
      {
        barPercentage: 0.7,
        categoryPercentage: 0.5,

        ticks: {
          fontColor: "#7b919e"
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    ]
  }
};

const SentimentChart = (props) => {
  // let data = null;

  const navigate = useNavigate();
  const processStateMap = new Map();
  const dataMap = new Map();
  const [formattedDataList, setformattedDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allMessages, setallMessages] = useState();
  const [treatedMessages, settreatedMessages] = useState();
  const [untreatedMessages, setuntreatedMessages] = useState();
  const [selectedDates, setSelectedDates] = useState([Date.now() - 7 * 24 * 60 * 60 * 1000, Date.now()]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  function convertDate(milliseconds) {
    const date = new Date(milliseconds);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const dateFormated = `${day}/${month}/${year}`;
    return dateFormated;
  }

  const theStatistics = props.statistics;

  // console.log("theMessages is", theMessages);

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];

      const timeDifference = endDate.getTime() - startDate.getTime();

      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor(timeDifference / millisecondsInADay);

      console.log("Durée en jours:", daysDifference);
      if (daysDifference > 12) {
        console.log("On affiche en mois");
      } else if (daysDifference > 30) {
        console.log("On affiche en Années");
      }
    }
  };

  function generateDateList() {
    const dateList = [];
    const today = new Date();

    for (let i = 0; i < 8; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);

      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Les mois commencent à 0 dans JavaScript, donc nous ajoutons 1
      const year = currentDate.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      dateList.push(formattedDate);
    }

    return dateList;
  }

  function generateTimestampList() {
    const timestampList = [];
    const today = new Date();

    for (let i = 0; i < 8; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);

      const timestamp = currentDate.getTime(); // Obtient le timestamp en millisecondes

      timestampList.push(timestamp);
    }

    return timestampList;
  }

  function formatGraphDate(dateString, period, locale = "en", length) {
    const date = new Date(dateString);

    switch (period) {
      case "H4":
        return new Intl.DateTimeFormat(locale, { year: "2-digit", month: "2-digit", day: "2-digit" }).format(date); // Formate en jour (DD/MM/YYYY ou autre selon locale)

      case "WEEK":
        // Trouver le lundi de la semaine
        const dayOfWeek = date.getUTCDay();
        const diff = date.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Ajuster pour que la semaine commence le lundi
        const monday = new Date(date);
        monday.setUTCDate(diff);
        return `Week of ${new Intl.DateTimeFormat(locale, { year: "2-digit", month: "2-digit", day: "2-digit" }).format(monday)}`; // Formate en semaine

      case "MONTH":
        return new Intl.DateTimeFormat(locale, { year: "2-digit", month: "2-digit" }).format(date); // Formate en mois (MM/YYYY ou autre selon locale)

      default:
        throw new Error("Invalid period. Please choose between 'H4', 'WEEK', or 'MONTH'.");
    }
  }

  const getData = (theStatistics) => {
    let messages_sentiments_list = [];

    if (theStatistics.length > 0) {
      messages_sentiments_list = theStatistics.map((statistic) => {
        return { period: statistic.period, date: statistic.$updatedAt, ...JSON.parse(statistic.messages_sentiments) };
      });
    } else {
      messages_sentiments_list = getLast7Days().map((date) => {
        return { period: "H4", date: date, happy: 0, neutral: 0, angry: 0 };
      });
    }

    messages_sentiments_list.reverse().forEach((messages_sentiments) => {
      dataMap.set(formatGraphDate(messages_sentiments.date, messages_sentiments.period, props.i18n.language, messages_sentiments.length), {
        sentiments_happy_total: messages_sentiments.happy,
        sentiments_sad_total: messages_sentiments.angry,
        sentiments_neutral_total: messages_sentiments.neutral
      });
    });
    console.log("dataMap is", dataMap);

    /*
    const formattedDataListLocal = [];

    // Parcourez les jours de la semaine dans l'ordre
    const daysOfWeek = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    daysOfWeek.forEach((dayOfWeek) => {
      const values = dataMap.get(dayOfWeek) || {
        sentiments_happy_total: 0,
        sentiments_sad_total: 0,
        sentiments_neutral_total: 0,
      };
      formattedDataListLocal.push([
        dayOfWeek,
        [
          values.sentiments_happy_total,
          values.sentiments_sad_total,
          values.sentiments_neutral_total,
        ],
      ]);
    });
    */

    console.log("dataMap.keys() is", Array.from(dataMap.keys()));
    console.log("dataMap.values() is", Array.from(dataMap.values()));

    /*
    GRAPHIQUE EN BATONNETS
    const dataForGraphic = Array.from(dataMap.keys()).map(element => {
      console.log("element is", element);
      console.log("dataMap is", dataMap);
      console.log("dataMap[element] is", dataMap.get(element));
      return {
        name: element,
        Mécontent: dataMap.get(element).sentiments_sad_total,
        Satisfait: dataMap.get(element).sentiments_happy_total,
        Neutre: dataMap.get(element).sentiments_neutral_total,
      };
    },);
    */

    const data = {
      labels: Array.from(dataMap.keys()),
      datasets: [
        {
          label: "Content",
          lineTension: 0.2,
          borderColor: ["#329993"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#329993",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).sentiments_happy_total)
        },
        {
          label: "Mécontent",
          lineTension: 0.2,
          backgroundColor: "rgba(235, 239, 242, 0)",
          borderColor: ["#ff7049"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#ff7049",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).sentiments_sad_total)
        },
        {
          label: "Neutre",
          lineTension: 0.2,
          backgroundColor: "rgba(235, 239, 242, 0)",
          borderColor: ["grey"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "grey",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).sentiments_neutral_total)
        }
      ]
    };

    console.log("dataForGraphic is", data);
    setformattedDataList(data);
    // console.log("formattedDataListLocal is", formattedDataListLocal);

    setLoading(false);
  };

  var option = {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            // max: 200,
            // min: 0,
            stepSize: 50
            // zeroLineColor: "#7b919e",
            // borderDash: [3, 3],
          }
        }
      ]
    },
    maintainAspectRatio: false
  };
  function getLast7Days() {
    const dates = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i); // Soustrait `i` jours à la date actuelle
      dates.push(date.toISOString().split("T")[0]); // Formate en YYYY-MM-DD
    }

    return dates;
  }
  useEffect(() => {
    getData(theStatistics);
  }, [theStatistics]);
  return (
    <div
      className="font-primary"
      style={{
        position: "relative",
        display: "grid",
        gridTemplateRows: "minmax(0,3fr)",
        height: formattedDataList && formattedDataList.labels?.length > 0 ? "86%" : "100%"
      }}>
      {formattedDataList && formattedDataList.labels?.length > 0 ? (
        <Line data={formattedDataList} options={option} />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + clients_satisfaction + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "119%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}>
          <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
          <img src={logo_maaia} alt="logo_maaia" height={40} />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SentimentChart);
