import { Account, Client } from "appwrite";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  Row,
  Toast,
  ToastBody,
  ToastHeader
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createLog } from "../../helpers/api_helper";
import CustomToast from "../../components/Common/customToast/CustomToast";
import { useMixpanel } from "../../Hooks/useMixpanel";

const svgs = {
  close_icon: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.37 19.6142C19.0771 19.8983 18.778 20.038 18.4725 20.0334C18.1504 20.0115 17.8473 19.8542 17.5632 19.5613L12.9511 14.8068L8.27403 19.3437C7.98118 19.6278 7.68204 19.7675 7.3766 19.7629C7.07117 19.7582 6.78476 19.6181 6.5174 19.3425C6.25003 19.0668 6.11866 18.7763 6.12331 18.4709C6.12795 18.1654 6.2767 17.8707 6.56955 17.5866L11.2466 13.0497L6.78484 8.45016C6.48405 8.14008 6.33598 7.83232 6.34062 7.52688C6.34527 7.22145 6.50263 6.91834 6.81271 6.61755C7.10556 6.33347 7.39609 6.2021 7.6843 6.22346C7.98973 6.2281 8.29284 6.38546 8.59364 6.69554L13.0554 11.295L17.7324 6.75812C18.0252 6.47404 18.3244 6.33432 18.6298 6.33896C18.9353 6.34361 19.2217 6.48374 19.489 6.75937C19.7564 7.03499 19.8878 7.32552 19.8831 7.63096C19.8785 7.93639 19.7297 8.23115 19.4369 8.51523L14.7598 13.0522L19.372 17.8067C19.6561 18.0996 19.8041 18.4073 19.8162 18.73C19.8288 19.0187 19.68 19.3135 19.37 19.6142Z"
        fill="currentColor"
      />
    </svg>
  ),
  eye_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className=""
      style={{ width: "24px" }}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
  ),
  eye_slash_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className=""
      style={{ width: "24px" }}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  )
};
const UpdatePassword = ({ passwordModal, tog_password_modal, t, getUserAccount }) => {
  const { mixPanelTrack } = useMixpanel();

  const [alert, setAlert] = useState(null);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const togglePasswordToast = (val) => {
    console.log("setToast before: " + toast);
    setToast(val);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const onRequestIsLoading = (value) => {
    setRequestIsLoading(value);
  };

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true);
  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordHidden(!isNewPasswordHidden);
  };

  const validation = useFormik({
    initialValues: {
      password: "" || "",
      new_password: "" || "",
      confirm_password: "" || ""
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Veuillez entrer votre mot de passe"),
      new_password: Yup.string()
        .required("Veuillez entrer votre nouveau mot de passe")
        .min(8, "Veuillez entrer un mot de passe d'au moins 8 charactères"),
      confirm_password: Yup.string()
        .required("Veuillez confirmer votre mot de passe")
        .test("passwords-match", "Les mots de passe ne correspondent pas", function (value) {
          return this.parent.new_password === value;
        })
    }),
    onSubmit: async (values) => {
      onRequestIsLoading(true);
      await updateUserPassword(values.password, values.new_password);
      getUserAccount();
    }
  });

  async function updateUserPassword(current_password, new_password) {
    const client = new Client();

    const account = new Account(client);

    client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

    const promise = account.updatePassword(new_password, current_password);

    return promise.then(
      function (response) {
        onRequestIsLoading(false);
        setAlert(null);
        togglePasswordToast({ title: t("Succès"), body: t("Moddification éffectuée"), key: "success" });
        // Créer le log
        var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
        var company_id = localStorage.getItem("companyId");
        let logData = {
          eventCreatedAt: response?.$createdAt,
          author_id: user_id,
          company_id: company_id
        };
        let logdata = {
          event: "password_updated",
          author_id: user_id,
          company_id: company_id,
          log_data: JSON.stringify(logData)
        };
        createLog(logdata, mixPanelTrack);
        validation.resetForm();
      },
      function (error) {
        onRequestIsLoading(false);
        setAlert(error.message);
        console.log("error on userPhone update ", error); // Failure
      }
    );
  }

  return (
    <Modal isOpen={passwordModal} toggle={tog_password_modal} centered className="help-page-modal">
      <ModalBody>
        <Button
          className="close_button"
          style={{ backgroundColor: "transparent", border: "none", padding: "0px", float: "right" }}
          onClick={() => {
            validation.resetForm();
            tog_password_modal();
          }}>
          {svgs.close_icon}
        </Button>
        <h1 className="modal_title" style={{ marginTop: "8%", marginBottom: "6%", textTransform: "none", fontSize: "14px" }}>
          {t("Modifier votre mot de passe")}
        </h1>
        <Row className="gap-4 mb-5" style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          {alert ? (
            <Alert color="danger" className="col-10 mx-auto">
              <div style={{ fontSize: "14px" }}>{alert}</div>
            </Alert>
          ) : null}
          <Form
            className="form-horizontal col-10 mx-auto"
            onSubmit={(e) => {
              e.preventDefault();

              validation.handleSubmit();
              return false;
            }}>
            <div>
              <label className="form-label">{t("Ancien mot de passe")}</label>
              <div className="form-control p-0">
                <InputGroup>
                  <Input
                    name="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={validation.touched.password && validation.errors.password ? true : false}
                    type={isPasswordHidden ? "password" : "text"}
                    placeholder={t("Entrer Mot de passe")}
                  />
                  <InputGroupText
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "0"
                    }}>
                    <button
                      className="btn h-100 form-control"
                      type="button"
                      onClick={togglePasswordVisibility}
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0"
                      }}>
                      {isPasswordHidden ? svgs.eye_icon : svgs.eye_slash_icon}
                    </button>
                  </InputGroupText>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      <div style={{ fontSize: "14px" }}>{validation.errors.password}</div>
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </div>
            </div>
            <div className="mt-4">
              <label className="form-label" style={{ fontSize: "14px" }}>
                {t("Nouveau mot de passe")}
              </label>
              <div className="form-control p-0">
                <InputGroup>
                  <Input
                    name="new_password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.new_password || ""}
                    invalid={validation.touched.new_password && validation.errors.new_password ? true : false}
                    type={isNewPasswordHidden ? "password" : "text"}
                    placeholder={t("Entrer le nouveau mot de passe")}
                  />
                  <InputGroupText
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "0"
                    }}>
                    <button
                      className="btn h-100 form-control"
                      type="button"
                      onClick={toggleNewPasswordVisibility}
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0"
                      }}>
                      {isNewPasswordHidden ? svgs.eye_icon : svgs.eye_slash_icon}
                    </button>
                  </InputGroupText>
                  {validation.touched.new_password && validation.errors.new_password ? (
                    <FormFeedback type="invalid">
                      <div style={{ fontSize: "14px" }}>{validation.errors.new_password}</div>
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </div>
            </div>
            <Label className="form-label mt-4" style={{ fontSize: "14px" }}>
              {t("Confirmer Mot de Passe")}
            </Label>
            <InputGroup>
              <Input
                name="confirm_password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.confirm_password || ""}
                invalid={validation.touched.confirm_password && validation.errors.confirm_password ? true : false}
                type={isNewPasswordHidden ? "password" : "text"}
                placeholder={t("Confirmer Mot de Passe")}
              />
              <InputGroupText
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: "0"
                }}>
                <button
                  className="btn h-100 form-control"
                  type="button"
                  onClick={toggleNewPasswordVisibility}
                  style={{
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0"
                  }}>
                  {isNewPasswordHidden ? svgs.eye_icon : svgs.eye_slash_icon}
                </button>
              </InputGroupText>
              {validation.touched.confirm_password && validation.errors.confirm_password ? (
                <FormFeedback type="invalid">
                  <div style={{ fontSize: "14px" }}>{validation.errors.confirm_password}</div>
                </FormFeedback>
              ) : null}
            </InputGroup>

            <button type="submit" className="inputCustom__Button mt-5" style={{ border: "none", fontSize: "14px" }}>
              {requestIsLoading ? t("Chargement") + "..." : <>{t("Modifier")}</>}
            </button>
          </Form>
        </Row>
      </ModalBody>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <CustomToast isOpen={toast} toggle={togglePasswordToast} message={{ body: t("Action effectuée") }} />
      </div>
    </Modal>
  );
};

export default withTranslation()(UpdatePassword);
