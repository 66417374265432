import CryptoJS from "crypto-js";

export default function encryptPasswordLocal(password) {
  var secretKey = "7SUfCZgTQ1XxnGKo7SUfCZgTQ1XxnGKo"; // Utilisez une clé secrète
  secretKey = CryptoJS.enc.Utf8.parse(secretKey);
  const ivStatic = CryptoJS.enc.Utf8.parse("1234567890123456");
  const encrypted = CryptoJS.AES.encrypt(password, secretKey, {
    iv: ivStatic,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  // console.log({ encryptPassword: encrypted });
  return encrypted;
}
