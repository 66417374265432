import React from "react";
import { withTranslation } from "react-i18next";
import RessourcesPage from "./RessourcesPage";
const Resources = (props) => {
  document.title = props.t("Ressources") + " | Synibox - Smart Assistant";
  return (
    <React.Fragment>
      <div
        className="page-content ressources-content"
        style={{ paddingLeft: "0" }}
      >
        <RessourcesPage />
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Resources);
