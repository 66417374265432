import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { FETCH_USER_SUBSCRIPTION } from "./actionTypes";
import {
  applyLoadingState,
  applyUserSubscriptionSuccess,
  setFreeTranslationLimitState,
  setSubscriptionCreditsState,
  setSubscriptionIncludedCreditsState,
  setSubscriptionLanguagesState,
  setSubscriptionRessourcesState,
  setUserSubscription,
  setUserSubscriptionState
} from "./actions";
import { getCompanyInfosPromise, getStripeSubscriptionPromise, SMARTAS } from "../../helpers/api_helper";

function* fetchSubscription({}) {
  yield put(applyLoadingState(true));

  // try {
  let price_id;
  let user_plan;
  let user_plan_translation_limit = 0;
  let is_user_plan_active = false;
  let subscription = undefined;
  let credits_included_in_plan = 0;
  let number_of_ressources = 0;
  let number_of_languages = 0;
  let plan_item;
  const client_data = yield call(getCompanyInfosPromise);
  console.log(`getCompanyInfosPromise is`, client_data);

  if (client_data) {
    if (client_data.direct_plan) {
      console.log(`direct_plan is: ${client_data.direct_plan}`);
      price_id = client_data.direct_plan;
      is_user_plan_active = true;
    } else {
      const exec_response = yield call(getStripeSubscriptionPromise, client_data.plan_id, client_data.stripe_region);
      console.log(`getStripeSubscriptionPromise exec_response is`, exec_response);
      if (exec_response.responseStatusCode === 400) {
        console.log(`Error getStripeSubscriptionPromise: ${exec_response.responseBody}`);
      } else {
        // L'exécution a réussi, traiter la réponse
        console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
        subscription = JSON.parse(exec_response.responseBody).subscription;
        if (subscription) {
          plan_item = subscription.items.data.find(
            (item) =>
              item.price.id === SMARTAS.STARTER_WORLD ||
              item.price.id === SMARTAS.STARTER_US ||
              item.price.id === SMARTAS.BUSINESS_WORLD ||
              item.price.id === SMARTAS.BUSINESS_US ||
              item.price.id === SMARTAS.GROWTH_WORLD ||
              item.price.id === SMARTAS.GROWTH_US ||
              item.price.id === SMARTAS.PRO_WORLD ||
              item.price.id === SMARTAS.PRO_US ||
              item.price.id === SMARTAS.PAUSE_WORLD ||
              item.price.id === SMARTAS.PAUSE_US
          );

          // subscription = subscription;
          is_user_plan_active = subscription.status === "active" || subscription.status === "trialing" ? true : false;
          console.log({ user_plan_price_id: price_id, is_user_plan_active, number_of_credits: credits_included_in_plan });

          if (plan_item) {
            console.log(`plan_item exist: ${plan_item}`);
            price_id = plan_item?.price?.id;
          }
        } else {
          console.log("Erreur inattendue");
        }
      }
    }

    console.log(`price_id is: ${price_id}`);

    switch (price_id) {
      case SMARTAS.STARTER_WORLD:
        user_plan = "MAAIA_PLAN_STARTER";
        credits_included_in_plan = 3000;
        user_plan_translation_limit = 5;
        number_of_ressources = 30;
        number_of_languages = 1;
        break;
      case SMARTAS.STARTER_US:
        user_plan = "MAAIA_PLAN_STARTER";
        credits_included_in_plan = 3000;
        user_plan_translation_limit = 5;
        number_of_ressources = 30;
        number_of_languages = 1;
        break;
      case SMARTAS.GROWTH_WORLD:
        user_plan = "MAAIA_PLAN_GROWTH";
        credits_included_in_plan = 10000;
        user_plan_translation_limit = 10;
        number_of_ressources = 100;
        number_of_languages = 3;
        break;
      case SMARTAS.GROWTH_US:
        user_plan = "MAAIA_PLAN_GROWTH";
        credits_included_in_plan = 10000;
        user_plan_translation_limit = 10;
        number_of_ressources = 100;
        number_of_languages = 3;
        break;
      case SMARTAS.BUSINESS_WORLD:
        user_plan = "MAAIA_PLAN_BUSINESS";
        credits_included_in_plan = 20000;
        user_plan_translation_limit = 200;
        number_of_ressources = 200;
        number_of_languages = 5;
        break;
      case SMARTAS.BUSINESS_US:
        user_plan = "MAAIA_PLAN_BUSINESS";
        credits_included_in_plan = 20000;
        user_plan_translation_limit = 200;
        number_of_ressources = 200;
        number_of_languages = 5;
        break;
      case SMARTAS.PRO_WORLD:
        user_plan = "MAAIA_PLAN_PRO";
        credits_included_in_plan = 40000;
        user_plan_translation_limit = 200;
        number_of_ressources = 400;
        number_of_languages = 10;
        break;
      case SMARTAS.PRO_US:
        user_plan = "MAAIA_PLAN_PRO";
        credits_included_in_plan = 40000;
        user_plan_translation_limit = 200;
        number_of_ressources = 400;
        number_of_languages = 10;
        break;
      case SMARTAS.PAUSE_WORLD:
        user_plan = "MAAIA_PLAN_PAUSE";
        break;
      case SMARTAS.PAUSE_US:
        user_plan = "MAAIA_PLAN_PAUSE";
        break;
      case SMARTAS.DIRECT_WORLD:
        user_plan = "MAAIA_PLAN_DIRECT_WORLD";
        credits_included_in_plan = client_data.offered_credits ?? 0;
        user_plan_translation_limit = 200;
        number_of_ressources = 400;
        number_of_languages = 10;
        break;
      default:
        user_plan = undefined;
        break;
    }

    console.log(`user_plan is: ${user_plan}`);

    if (subscription) {
      subscription = { ...subscription, pause: user_plan === "MAAIA_PLAN_PAUSE" };
    }

    let credits = 0;
    if (client_data.direct_plan) {
      credits = credits_included_in_plan;
    } else if (subscription) {
      if (subscription.status === "trialing") {
        credits = SMARTAS.MAIL_COST[user_plan] * 50 + (client_data.offered_credits ?? 0);
      } else if (!is_user_plan_active) {
        credits = (client_data.direct_credit ?? 0) + (client_data.offered_credits ?? 0);
      } else {
        credits =
          credits_included_in_plan + (client_data.direct_credit ?? 0) + (client_data.credit_carried_over ?? 0) + (client_data.offered_credits ?? 0);
      }
    }

    yield put(applyUserSubscriptionSuccess(user_plan));
    yield put(setUserSubscriptionState(is_user_plan_active));
    yield put(setUserSubscription(subscription));
    yield put(setFreeTranslationLimitState(user_plan_translation_limit));
    yield put(setSubscriptionCreditsState(credits));
    yield put(setSubscriptionIncludedCreditsState(credits_included_in_plan));
    yield put(setSubscriptionRessourcesState(number_of_ressources));
    yield put(setSubscriptionLanguagesState(number_of_languages));

    console.log({
      subbs: {
        user_plan,
        is_user_plan_active,
        user_plan_translation_limit,
        credit: credits,
        number_of_credits: credits_included_in_plan,
        number_of_ressources,
        number_of_languages,
        subscription
      }
    });
  } else {
    console.log("Erreur inattendue: No client data");
  }

  yield put(applyLoadingState(false));
  // } catch (error) {
  //   yield put(applyLoadingState(false));
  //   let message;
  //   switch (error.code) {
  //     case 500:
  //       message = "Erreur interne du serveur";
  //       break;
  //     default:
  //       message = "Erreur inattendue";
  //   }
  //   console.log({ error_message: error });
  // }
}

function* subscriptionSaga() {
  yield takeEvery(FETCH_USER_SUBSCRIPTION, fetchSubscription);
}

export default subscriptionSaga;
