import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { map } from "lodash";

//i18n
import i18n from "../../../i18n";

import { withTranslation } from "react-i18next";

import languages from "../../../CommonData/languages";
import { createLog, getBrowserLanguage, klaviyoUpdateProfile } from "../../../helpers/api_helper";
import { Account, Client } from "appwrite";
import { useMixpanel } from "../../../Hooks/useMixpanel";

const LanguageDropdown = (props) => {
  const [singlebtn, setSinglebtn] = useState(false);
  const [selectLang, setselectLang] = useState(localStorage.getItem("I18N_LANGUAGE") || "en");
  const [userPrefs, setUserPrefs] = useState();

  const { mixPanelTrack } = useMixpanel();

  useEffect(() => {
    getUserPrefs();
  }, []);

  // useEffect(() => {
  //   // Fonction pour mettre à jour la valeur lorsque l'événement est déclenché
  //   const handleValeurMiseAJour = (event) => {
  //     setselectLang(event.detail ?? "en");
  //   };

  //   // Écouter l'événement personnalisé
  //   window.addEventListener("valeurMiseAJour", handleValeurMiseAJour);

  //   // Nettoyer l'écouteur d'événement lors du démontage du composant
  //   return () => {
  //     window.removeEventListener("valeurMiseAJour", handleValeurMiseAJour);
  //   };
  // }, [navigator]);

  async function updateUserPrefs(userPrefs) {
    const client = new Client();

    const account = new Account(client);

    client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

    const promise = account.updatePrefs(userPrefs);

    return promise.then(
      function (response) {
        console.log("new userPrefs is ", response); // Success
        return response;
      },
      function (error) {
        console.log("error on userPrefs update ", error); // Failure
        return null;
      }
    );
  }

  async function getUserPrefs() {
    const client = new Client();
    const account = new Account(client);

    client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

    const promise = account.getPrefs();
    return await promise.then(
      async function (response) {
        // console.log("userInfos is", response);
        setUserPrefs(response);
        if (response.lang) {
          i18n.changeLanguage(response.lang);
          localStorage.setItem("I18N_LANGUAGE", response.lang);
          setselectLang(response.lang);
        }
      },
      function (error) {
        console.log(error);
      }
    );
  }
  const changeLanguageAction = async (lang) => {
    console.log("sss langue from dropdown is ", lang);

    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setselectLang(lang);

    if (userPrefs) {
      const new_prefs = userPrefs;
      new_prefs["lang"] = lang;
      const response = await updateUserPrefs(new_prefs);

      // Créer le log
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
      var company_id = localStorage.getItem("companyId");

      const updates = {
        properties: {
          language: lang
        }
      };
      klaviyoUpdateProfile(company_id, updates);

      let logData = {
        eventCreatedAt: response?.$createdAt,
        author_id: user_id,
        company_id: company_id,
        new_lang: lang
      };
      let logdata = {
        event: "lang_updated",
        author_id: user_id,
        company_id: company_id,
        log_data: JSON.stringify(logData)
      };
      createLog(logdata, mixPanelTrack);
    }
    // // Émettre un événement personnalisé
    // const event = new CustomEvent("valeurMiseAJour", { detail: lang });
    // window.dispatchEvent(event);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={singlebtn} toggle={() => setSinglebtn(!singlebtn)} className="d-inline-block d-sm-inline-block">
        <DropdownToggle className="header-item waves-effect p-0" tag="button" style={{ backgroundColor: "transparent" }}>
          <img
            src={selectLang && languages[selectLang] ? languages[selectLang].flag : languages["en"].flag}
            alt="iAssistant"
            height="20"
            className=""
          />
          {props.hasArrow && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M7 10l5 5 5-5z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          )}
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem key={key} onClick={() => changeLanguageAction(key)} className={`notify-item ${selectLang === key ? "active" : "none"}`}>
              <img src={languages && languages[key] ? languages[key].flag : languages["en"].flag} alt="iAssistant" className="" width="24.38" />
              <span className="align-middle"> {props.t(languages[key].label)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(LanguageDropdown);
