import React, { useState, useEffect } from "react";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createStripeCheckoutSession,
  createStripePaymentEntent,
  stripe_payment_return_url,
  prestashop_payment_return_url,
  createStripeCustomer,
  synibox_dashboard_url,
  getStripeSubscription,
  createStripeSubscripion as createStripeSubscription,
  applyStripeDiscountCode,
  updateStripeSubscripion,
  retrieveStripeUpComingInvoice,
  getStripeProduct,
  SMARTAS,
  createStripeSetupIntent,
  updateStripeCustomer,
  createOnShotPreview,
  finalizePayment,
  createLog,
  klaviyoUpdateProfile,
  formatDateMillisec
} from "../../helpers/api_helper";
import logo_maaia from "../../assets/images/icons-infos-purple.png";
import { Col, Alert } from "reactstrap";
import "./PaymentForm.scss";
import InfoMono from "../../assets/images/icons/InfoMono";
import PlusOutlined from "../../assets/images/icons/PlusOutlined";
import ArrowLeftOutlined from "../../assets/images/icons/ArrowLeftOutlined";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Client, Account, Databases, ID, Query } from "appwrite";
import { withTranslation } from "react-i18next";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useMixpanel } from "../../Hooks/useMixpanel";

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [country, setCountry] = useState(null);

  const handleError = (error) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Confirmer le paiement en utilisant stripe.confirmPayment
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: stripe_payment_return_url // URL de redirection après succès
      },
      redirect: "if_required"
    });

    if (error) {
      // Gérer les erreurs de paiement
      handleError(error);
    } else {
      // Paiement réussi
      console.log("Payment confirmed:", paymentIntent);

      // Finaliser avec la capture du paiement si nécessaire (si capture_method est 'manual')
      if (paymentIntent.capture_method === "manual") {
        const res = await finalizePayment(props.region, paymentIntent.id);

        if (!res) {
          setErrorMessage("system_error");
          setIsLoading(false);
          return;
        } else {
          window.location.href = stripe_payment_return_url;
        }
      }

      // Exécuter vos actions de succès ici (ex: mise à jour de l'interface utilisateur)
      console.log("Payment finalized successfully");
    }

    setIsLoading(false);
  };

  console.log({ the_passed_region: props.region });

  const defaultAdd =
    props.region === "US"
      ? { name: props.customer_fullName, address: { country: "US" } }
      : {
          name: props.customer_fullName,
          address: { country: props.region || "FR" }
        };

  const handleAddressChange = (event) => {
    let the_country;
    console.log("Address changed:", event);
    if (event.elementType === "address" && event.elementMode === "billing") {
      the_country = event.value.address.country;
      const fullName = event.value.name;
      console.log("Address changed: country is", the_country);
      console.log("Name is", event.value.name);
      props.setCustomerFullName(fullName);
    }

    if (
      (the_country && the_country === "US" && props.region !== "US") ||
      (props.region === "US" && the_country && the_country !== "US")
    ) {
      console.log("Need refresh");
      props.refreshStripePaymentIntent(the_country);
    } else {
      console.log("No need refresh", the_country);
    }
    setCountry(the_country);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="payment-form d-flex"
      style={{ height: "100%" }}
    >
      <div className="my-auto w-100 py-4">
        <div className="form-group">
          <AddressElement
            options={{
              mode: "billing",
              defaultValues: defaultAdd
            }}
            onChange={handleAddressChange}
          />
        </div>

        {errorMessage && (
          <Alert color="danger" className="font-primary font-size-12 mb-3">
            {props.t(errorMessage)}
          </Alert>
        )}
        <div className="form-group">
          <PaymentElement
            options={{
              layout: {
                type: "accordion",
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: false
              }
            }}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || props.isRefreshing}
          className="r_button"
          id="submit"
        >
          <span id="button-text">
            {isLoading || props.isRefreshing ? (
              <span
                className="spinner-border spinner-border-sm me-2 font-primary"
                role="status"
                aria-hidden="true"
              />
            ) : (
              props.t("Pay now")
            )}
          </span>
        </button>
      </div>
      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};

const StripePauseCheckout = (props) => {
  // const [stripePromise, setLoadStripe] = useState(undefined);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntent, setpaymentIntent] = useState(null);
  const [stripeCustomerID, setStripeCustomerID] = useState(undefined);
  const [customer_email, setCustomerEmail] = useState(null);
  const [customer_fullName, setCustomerFullName] = useState(null);
  const [customerIsLoading, setCustomerIsLoading] = useState(null);
  const [client_data, setCompanyInfos] = useState(undefined);

  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState(undefined);
  const [couponRequestMessage, setCouponRequestMessage] = useState("");
  const [couponRequestLoading, setCouponRequestLoading] = useState(false);

  const [showUpdateUI, setShowUpdateUI] = useState(false);

  let navigate = useNavigate();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region") || undefined;

  let stripePromise;
  let pause_plan_id;
  if (region && region === "US") {
    console.log("Load strip for US");

    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_USA);
    pause_plan_id = SMARTAS.PAUSE_US;
  } else {
    console.log("Load strip for World");
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_WORLD);
    pause_plan_id = SMARTAS.PAUSE_WORLD;
  }

  const handleEmailChange = (email) => {
    console.log("Email changed: email is", email);
    setCustomerEmail(email);
  };

  const refreshStripePaymentIntent = async (region) => {
    console.log("start refreshStripePaymentIntent");

    if (region === "US") {
      window.location.href = `${synibox_dashboard_url}/standby-checkout?region=US`;
    } else {
      window.location.href = `${synibox_dashboard_url}/standby-checkout`;
    }
  };

  const fetchClientSecret = async (
    region,
    pause_plan_id,
    credits_pack,
    the_stripe_customer_id,
    company_id
  ) => {
    setPageLoading(true);

    const response = await createOnShotPreview(
      region,
      pause_plan_id,
      credits_pack,
      the_stripe_customer_id,
      company_id
    );

    setPageLoading(false);

    if (response) {
      const paymentIntent = response.paymentIntent;
      setpaymentIntent(paymentIntent);
      setClientSecret(paymentIntent.client_secret);
    }
  };

  useEffect(() => {
    initCustomer();
  }, []);

  const initCustomer = async () => {
    setCustomerIsLoading(true);
    const user_string = localStorage.getItem("user");
    const user = user_string ? JSON.parse(user_string) : undefined;

    if (
      user_string &&
      user &&
      user.prefs &&
      user.prefs["owner"] &&
      (user.prefs["owner"] === "true" || user.prefs["owner"] === true)
    ) {
      const the_client_data = await getClientData();

      if (!the_client_data) {
        console.log("No company infos");
        return;
      }

      setCompanyInfos(the_client_data);

      console.log({ synibox_user_name: user });
      handleEmailChange(user.email);
      setCustomerFullName(
        [user.prefs["first_name"], user.prefs["last_name"]]
          .filter(Boolean)
          .join(" ")
      );
      const stripe_customer_id = the_client_data.stripe_customer_id;
      if (stripe_customer_id) {
        console.log({ stripe_customer_id });

        setStripeCustomerID(stripe_customer_id);

        const subscription_plan = await getStripeSubscription(
          the_client_data.plan_id,
          the_client_data.stripe_region,
          true,
          true
        );
        console.log({ subscription_plan });

        if (subscription_plan && pause_plan_id !== subscription_plan.price_id) {
          setShowUpdateUI(subscription_plan);
        } else if (
          subscription_plan &&
          pause_plan_id === subscription_plan.price_id
        ) {
          navigate(`/settings`, {
            replace: true,
            state: { tab: "billing", modal: "" }
          });
        }
      }
    } else {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
    setCustomerIsLoading(false);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const getClientData = async function () {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    try {
      const response = await databases.getDocument(
        "default",
        "client_infos",
        localStorage.getItem("companyId")
      );
      console.log("getClientData", response);
      onCompanyInfoChanged(response);
      return response;
    } catch (error) {
      console.log(error); // Failure
      return null;
    }
  };

  const options = {
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
    appearance: {
      theme: "stripe"
    },
    clientSecret,
    layout: {
      type: "accordion",
      defaultCollapsed: false
    },
    locale: props.i18n.language
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{
        height: "100vh",
        backgroundColor: "white"
      }}
    >
      {showUpdateUI && (
        <PauseUI
          region={
            client_data && client_data.stripe_region
              ? client_data.stripe_region
              : region
          }
          the_client_data={client_data}
          subscription_plan={showUpdateUI}
          preview_items={[
            {
              id: showUpdateUI.sub_item_id,
              price: pause_plan_id
            }
          ]}
          items={[
            {
              id: showUpdateUI.sub_item_id,
              price: pause_plan_id,
              quantity: 1
            }
          ]}
        />
      )}
    </div>
  );
};

export const PauseUI = withTranslation()((props) => {
  const { region, the_client_data, subscription_plan, items, preview_items } =
    props;
  const [invoicePreview, setInvoicePreview] = useState(undefined);
  const [planDetails, setPlanDetails] = useState(undefined);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [decompte, setDecompte] = useState(15);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (decompte === 0) {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
  }, [decompte]);

  useEffect(() => {
    if (redirect) {
      let interval;
      interval = setInterval(() => {
        setDecompte((prevDecompte) => prevDecompte - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [redirect]);

  const { mixPanelTrack } = useMixpanel();

  let navigate = useNavigate();

  const getStripeUpComingInvoice = async (
    region,
    stripe_customer_id,
    subscription_plan_id,
    items
  ) => {
    setPageLoading(true);
    const response = await retrieveStripeUpComingInvoice(
      region,
      stripe_customer_id,
      subscription_plan_id,
      items,
      "none"
    );

    console.log({ retrieveStripeUpComingInvoiceResponse: response });

    if (response) {
      const invoice = response.invoice;
      const plan_details = invoice.lines.data.filter((line) => !line.proration);
      const totalAmount = plan_details.reduce(
        (total, line) => total + line.amount,
        0
      );

      console.log({ invoice });
      console.log({ plan_details });

      setInvoicePreview({
        ...invoice,
        subtotal: totalAmount,
        total: totalAmount
      });
      setPlanDetails(plan_details);
    } else {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
    setPageLoading(false);
  };

  const handleUpdateSubscriptipon = async () => {
    const metadata = {
      trial_end: "now"
    };
    setLoading(true);
    const response = await updateStripeSubscripion(
      region,
      subscription_plan.id,
      items,
      metadata,
      "none"
    );
    setLoading(false);
    console.log({ updateStripeSubscripion: response });
    if (response) {
      const company_id = localStorage.getItem("companyId");
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

      const updates = {
        properties: {
          plan: SMARTAS.PLAN_NAME[subscription_plan.price_id],
          plan_activation_date: formatDateMillisec(Date.now()),
          on_pause: true
        }
      };
      klaviyoUpdateProfile(company_id, updates);

      let logData = {
        eventCreatedAt: Date.now(),
        author_id: user_id,
        company_id: company_id,
        plan: SMARTAS.PLAN_NAME[subscription_plan.price_id],
        direct_credit: props.credits_pack * 1000
      };
      let logdata = {
        event: "subscription_paused",
        author_id: user_id,
        company_id: company_id,
        log_data: JSON.stringify(logData)
      };
      createLog(logdata, mixPanelTrack);
      setRedirect(true);
    }
  };

  useEffect(() => {
    const company_id = localStorage.getItem("companyId");
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    if (!company_id || !user_id) {
      window.location.href = stripe_payment_return_url;
    }
    getStripeUpComingInvoice(
      region,
      the_client_data.stripe_customer_id,
      subscription_plan.id,
      preview_items
    );
  }, []);

  return (
    <>
      <Col
        className=""
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          overflow: "auto"
        }}
      >
        <div
          className="d-flex px-3 pt-5"
          style={{
            flexDirection: "column",
            width: "600px",
            height: "100%",
            overflow: "auto"
          }}
        >
          <div className="my-auto">
            {redirect && (
              <Alert className="text-center" color="success">
                {`${props.t("Opération réussie !")} ${props.t(
                  "Vous serez redirigé dans"
                )} ${decompte}sec...`}
              </Alert>
            )}
            <div
              className={`d-flex align-items-center gap-1 mb-3 ${
                invoicePreview ? "" : "justify-content-center"
              }`}
            >
              <img alt="logo_maaia" src={logo_maaia} height={30} />
              <p
                className="font-size-16 mb-0 ms-1"
                style={{ fontWeight: "500" }}
              >
                Synibox
              </p>
            </div>

            <div
              className={`mb-3 font-size-16 d-flex ${
                invoicePreview ? "" : "justify-content-center"
              }`}
            >
              {props.t("Mettre en pause")}
            </div>

            {!pageLoading ? (
              <>
                <div
                  className="d-flex mb-3"
                  style={{ flexDirection: "column" }}
                >
                  <p
                    className="font-size-16 mb-0"
                    style={{ fontWeight: "500" }}
                  >
                    Synibox
                  </p>
                  <div>
                    <div className="mt-3">
                      {planDetails &&
                        planDetails.map((detail, idx) => (
                          <div className="pb-3" id={idx}>
                            {ProductCard(detail, true)}
                          </div>
                        ))}
                      {invoicePreview && (
                        <div
                          className="d-flex align-items-center justify-content-between pb-3"
                          style={{
                            borderBottom: "1px solid rgba(15, 15, 15, 0.1)"
                          }}
                        >
                          <div className="product_name">
                            {props.t("Subtotal")}
                          </div>
                          <span className="line_item_price">
                            {" "}
                            {invoicePreview.currency === "usd" ? "$" : "€"}
                            {invoicePreview.subtotal / 100}
                          </span>
                        </div>
                      )}
                    </div>
                    {invoicePreview && (
                      <div className="mt-3">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ fontWeight: "600" }}
                        >
                          <div className="product_name">
                            {props.t("Total to pay today")}
                          </div>
                          <span className="line_item_price">
                            {invoicePreview.currency === "usd" ? "$" : "€"}
                            {invoicePreview.total / 100}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="r_button py-2 px-2 font-size-14 mt-2"
                  onClick={() => {
                    handleUpdateSubscriptipon();
                  }}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2 font-primary"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    props.t("Confirmer")
                  )}
                </button>
              </>
            ) : (
              <div
                className="mt-4 font-size-16 gap-1"
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <span
                  className="spinner-border spinner-border-md me-2 font-primary text-center"
                  role="status"
                  aria-hidden="true"
                />
                {props.t("Chargement") + "..."}
              </div>
            )}
          </div>
        </div>
        <div style={{ alignSelf: "end" }}>
          <LanguageDropdown hasArrow />
        </div>
      </Col>
    </>
  );
  function ProductCard(plan, is_plan) {
    return (
      <div className={is_plan ? "addon_card" : ""}>
        <div className="my-2 w-100">
          <div className="d-flex">
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column"
              }}
            >
              <div className="d-flex justify-content-between w-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div className="product_name">
                    {plan.description
                      .split(" ")
                      .map((word) => props.t(word))
                      .join(" ")}
                  </div>
                  <div>
                    <select className="qty_select">
                      {plan.plan.interval === "month" ? (
                        <option value="Monthly">{props.t("Monthly")}</option>
                      ) : (
                        <option value="Yearly">{props.t("Yearly")}</option>
                      )}
                    </select>
                  </div>
                </div>
                <span className="line_item_price" style={{ fontWeight: "600" }}>
                  {plan.plan.currency === "usd" ? "$" : "€"}
                  {plan.amount / 100}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default withTranslation()(StripePauseCheckout);
