import {
  EMPTY_SUBSCRIPTION,
  FETCH_USER_SUBSCRIPTION,
  USER_PLAN,
  SUBSCRIPTION_CREDITS,
  SUBSCRIPTION_INCLUDED_CREDITS,
  SUBSCRIPTION_LANGUAGES,
  SUBSCRIPTION_RESSOURCES,
  USER_PLAN_FREE_TRANSLATION_LIMIT,
  USER_PLAN_STATUS,
  USER_PLAN_SUCCESS,
  LOADING_STATE
} from "./actionTypes";

export const applyUserSubscriptionSuccess = (userPlan) => {
  return {
    type: USER_PLAN_SUCCESS,
    payload: userPlan
  };
};

export const applyLoadingState = (loading_state) => {
  return {
    type: LOADING_STATE,
    payload: loading_state
  };
};

export const setUserSubscriptionState = (isUserPlanActive) => {
  return {
    type: USER_PLAN_STATUS,
    payload: isUserPlanActive
  };
};

export const setUserSubscription = (subscription) => {
  return {
    type: USER_PLAN,
    payload: subscription
  };
};

export const setFreeTranslationLimitState = (freeTranslationLimit) => {
  return {
    type: USER_PLAN_FREE_TRANSLATION_LIMIT,
    payload: freeTranslationLimit
  };
};

export const setSubscriptionCreditsState = (subscriptionCredits) => {
  return {
    type: SUBSCRIPTION_CREDITS,
    payload: subscriptionCredits
  };
};

export const setSubscriptionIncludedCreditsState = (subscriptionCredits) => {
  return {
    type: SUBSCRIPTION_INCLUDED_CREDITS,
    payload: subscriptionCredits
  };
};

export const setSubscriptionRessourcesState = (subscriptionRessources) => {
  return {
    type: SUBSCRIPTION_RESSOURCES,
    payload: subscriptionRessources
  };
};

export const setSubscriptionLanguagesState = (subscriptionLanguages) => {
  return {
    type: SUBSCRIPTION_LANGUAGES,
    payload: subscriptionLanguages
  };
};

export const fetchUserSubscription = () => {
  return {
    type: FETCH_USER_SUBSCRIPTION,
    payload: {}
  };
};

export const emptySubscriptionStorage = () => {
  return {
    type: EMPTY_SUBSCRIPTION,
    payload: {}
  };
};
