import React from "react";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import CrossOutlined from "../../../assets/images/icons/CrossOutlined";
import Calculator from "../../../Pages/Settings/component/Calculator";
import { calculator_id, calculator_id_downgrade, calculator_id_upgrade } from "../../../helpers/api_helper";
import { calculator_id_upgrade_downgrade } from "../../../helpers/api_helper";
import { useSelector, useDispatch } from "react-redux";
import CustomCalculator from "../../../Pages/Settings/component/CustomCalculator";

function CalculatorModal(props) {
  const { userPlan, isUserPlanActive } = useSelector((state) => ({
    userPlan: state.subscription.userPlan,
    isUserPlanActive: state.subscription.isUserPlanActive
  }));

  const id = props.upgrade_downgrade ? (userPlan === "MAAIA_PLAN_INFINITY" ? calculator_id_downgrade : calculator_id_upgrade) : calculator_id;

  const handleInteraction = (event) => {
    console.log("Interaction event:", event);
  };

  const handleSubmit = (event) => {
    console.log("Submit event:", event);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered className="modal_wrapper__90x50">
      <ModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%"
        }}>
        <Button
          className="close_button"
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0px",
            position: "absolute",
            right: "1rem"
          }}
          onClick={() => {
            props.toggle();
          }}>
          <CrossOutlined height={26} />
        </Button>
        <Row className={`${userPlan ? "mb-4" : "mb-5"} plan-details h-100`} style={{ paddingLeft: "2%", paddingRight: "2%", overflow: "auto" }}>
          {/* <Calculator calculatorId={id} onInteraction={handleInteraction} onSubmit={handleSubmit} /> */}
          <CustomCalculator client_data={props.client_data} has_consumed_trial={props.has_consumed_trial} />
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default CalculatorModal;
