import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { FETCH_MAILS_REDUX, FETCH_SINGLE_MAIL_REDUX, FETCH_FOLDERS_REDUX, SEARCH_MAILS_REDUX } from "./actionTypes";
import { applyMailsSuccessful, applyMailsError, applyFoldersSuccessful, applyFoldersError } from "./actions";

//Include Both Helper File with needed methods
import { fetchMailsList, fetchFoldersList, fetchSingleMail, fetchSearchedMails } from "../../helpers/api_helper";

// Is user register successfull then direct plot user in redux.
function* getMails({ payload: { company_id, currentBox, currentPage, pageSize, old_messages } }) {
  console.log("started mails fectch");

  try {
    const data = yield call(fetchMailsList, company_id, currentBox, currentPage, pageSize, old_messages);

    if (!data || data.detail) {
      console.log("fetchMailError", data);
      yield put(applyMailsError({ message: data === "" ? "Échec de chargement" : data.detail, currentBox, currentPage, old_messages }));
    } else {
      console.log("Mails fetched successfully", data);
      yield put(applyMailsSuccessful({ data, currentBox, currentPage, old_messages }));
    }

    /*
    const { response, cancel } = yield race({
      response: call(fetchMailsList, company_id, currentBox, currentPage, pageSize, old_messages),
      cancel: take(cancelRequest) // Écouter l'action d'annulation
    });
    console.log("sssssss", response, cancel);

    if (response) {
      if (!response.data || response.data.detail) {
        console.log("fetchMailError", response.data);
        yield put(
          applyMailsError({ message: response.data === "" ? "Échec de chargement" : response.data.detail, currentBox, currentPage, old_messages })
        );
      } else {
        console.log("Mails fetched successfully", response.data);
        yield put(applyMailsSuccessful({ data: response.data, currentBox, currentPage, old_messages }));
      }
    } else if (cancel) {
      // Requête annulée
      console.log("Requête annulée");
    }
    */
  } catch (error) {
    console.log("Mails fetche failed", error);

    // Affiche la structure complète de l'erreur dans la console
    console.log("Full error details:", JSON.stringify(error, Object.getOwnPropertyNames(error), 2));

    if (error.response) {
      // Erreur HTTP, accès à l'objet "detail" de l'API
      console.error("Error detail:", error.response.data.detail);
      alert(`Error: ${error.response.data.detail}`);
    } else if (error.request) {
      // Erreur liée à la requête
      console.error("No response received:", error.request);
    } else {
      // Autre type d'erreur
      console.error("Error:", error.message);
    }

    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      case 401:
        message = "E-mail ou mot de passe incorrect";
        break;
      case 409:
        message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
        break;
      default:
        message = error.message;

        yield put(applyMailsError({ message, currentBox, currentPage, old_messages }));
    }
  }
}

function* getSingleMail({ payload: { message_id, company_id, box } }) {
  console.log("started single mail fectch");

  try {
    const data = yield call(fetchSingleMail, message_id, company_id, box);
    // if (!data || data === "" || (typeof data === "string" && data.includes("error"))) {
    if (!data || data.detail) {
      yield put(applyMailsError({ message: data === "" ? "Échec de chargement" : data, data, box }));
    } else {
      console.log("Single Mail fetched successfully", data);
      yield put(applyMailsSuccessful({ data, box }));
    }
  } catch (error) {
    console.log("Mails fetched failed", error);
    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      case 401:
        message = "E-mail ou mot de passe incorrect";
        break;
      case 409:
        message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
        break;
      default:
        message = error.message;

        yield put(applyMailsError({ message, box }));
    }
  }
}

function* searchMails({ payload: { search, company_id, currentBox, currentPage, pageSize, old_messages } }) {
  console.log("started mails sarch");

  try {
    const data = yield call(fetchSearchedMails, search, company_id, currentBox, currentPage, pageSize, old_messages);
    // if (!data || data === "" || (typeof data === "string" && data.includes("error"))) {
    if (!data || data.detail) {
      yield put(applyMailsError({ message: data === "" ? "Échec de chargement" : data, data, currentBox, currentPage, old_messages }));
    } else {
      console.log("Mails search successfully", data);
      yield put(applyMailsSuccessful({ data, currentBox, currentPage, old_messages }, true));
    }
  } catch (error) {
    console.log("Mails search failed", error);
    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      case 401:
        message = "E-mail ou mot de passe incorrect";
        break;
      case 409:
        message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
        break;
      default:
        message = error.message;

        yield put(applyMailsError({ message, currentBox, currentPage, old_messages }));
    }
  }
}

function* getFolders({ payload: { company_id } }) {
  // console.log("started folders fetch for id", company_id);

  try {
    const data = yield call(fetchFoldersList, company_id);
    // if (!data || (data && typeof data === "string" && data.includes("error"))) {
    if (!data || data.detail) {
      console.log("1. Boxes fetch error", data);
      yield put(applyFoldersError(data));
    } else {
      console.log("getFolders", data);
      yield put(applyFoldersSuccessful({ data, id: company_id }));
    }
  } catch (error) {
    console.log("2. Boxes fetch error", error);
    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      case 401:
        message = "E-mail ou mot de passe incorrect";
        break;
      case 409:
        message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
        break;
      default:
        message = error.message;

        yield put(applyFoldersError(message));
    }
  }
}

export function* watchFetchMails() {
  yield takeEvery(FETCH_MAILS_REDUX, getMails);
}
export function* watchFetchSingleMail() {
  yield takeEvery(FETCH_SINGLE_MAIL_REDUX, getSingleMail);
}
export function* watchSearchMails() {
  yield takeEvery(SEARCH_MAILS_REDUX, searchMails);
}
export function* watchFetchFolders() {
  yield takeEvery(FETCH_FOLDERS_REDUX, getFolders);
}

function* mailsSaga() {
  yield all([fork(watchFetchMails), fork(watchFetchSingleMail), fork(watchSearchMails), fork(watchFetchFolders)]);
}

export default mailsSaga;
