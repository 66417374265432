const StaticData = {
  contact_reasons_array: [
    {
      contact_reasons: "product",
      explanation:
        "Demandes d'informations supplémentaires sur un produit spécifique, comme ses caractéristiques et matériaux. Ceci exclut tout conseil sur son utilisation ou problème technique.",
      french_text: "INFORMATION PRODUIT"
    },
    {
      contact_reasons: "delivery",
      explanation:
        "Demandes uniquement liées au processus de livraison, sans référence au retour de produit ou au processus de commande.",
      french_text: "LIVRAISON"
    },
    {
      contact_reasons: "returns",
      explanation:
        "Demandes spécifiquement liées au retour d'un produit, sans référence à la livraison ou au service après-vente.",
      french_text: "RETOUR"
    },
    {
      contact_reasons: "customer_account",
      explanation:
        "Demandes liées aux informations du compte, hors problèmes de mot de passe ou problèmes de sécurité.",
      french_text: "COMPTE CLIENT"
    },
    {
      contact_reasons: "feedback",
      explanation:
        "Demandes de feedbacks ou commentaires sur les produits, sans référence aux plaintes.",
      french_text: "FEEDBACKS"
    },
    {
      contact_reasons: "company_information",
      explanation:
        "Demandes d'informations générales sur l'entreprise, sans référence à l'éthique ou à la responsabilité sociale de l'entreprise.",
      french_text: "INFORMATION ENTREPRISE"
    },
    {
      contact_reasons: "influencers",
      explanation:
        "Demandes liées aux collaborations avec des influenceurs, sans référence aux collaborations avec des créateurs de contenu non-influenceurs.",
      french_text: "INFLUENCEURS"
    },
    {
      contact_reasons: "payments",
      explanation:
        "Demandes spécifiques aux problèmes liés au paiement d'une commande, sans référence au processus de commande ou au remboursement.",
      french_text: "PAIEMENTS"
    },
    {
      contact_reasons: "refunds",
      explanation:
        "Demandes spécifiques aux remboursements et non aux autres aspects de la facturation ou du paiement.",
      french_text: "REMBOURSEMENTS"
    },
    {
      contact_reasons: "order",
      explanation:
        "Questions sur le processus de commande, excluant les problèmes de paiement, de remboursement et de livraison.",
      french_text: "PROCESSUS COMMANDE"
    }

    // {
    //   contact_reasons: "product",
    //   explanation:
    //     "Demandes d'informations supplémentaires sur un produit spécifique, comme ses caractéristiques et matériaux. Ceci exclut tout conseil sur son utilisation ou problème technique.",
    //   french_text: "INFORMATION PRODUIT",
    // },
    // {
    //   contact_reasons: "product_availability",
    //   explanation: "Demandes concernant la disponibilité en stock d'un produit spécifique, sans référence à son prix ou à toute promotion.",
    //   french_text: "DISPONIBILITÉ PRODUIT",
    // },
    // {
    //   contact_reasons: "product_advice",
    //   explanation:
    //     "Demandes de conseils pour l'utilisation d'un produit, hors problèmes techniques ou questions concernant les caractéristiques spécifiques du produit.",
    //   french_text: "CONSEILS PRODUITS",
    // },
    // {
    //   contact_reasons: "product_problems",
    //   explanation:
    //     "Demandes liées aux problèmes de produit qui ne sont pas liées à la livraison ou au service après-vente. Ce tag ne concerne pas les problèmes liés à l'utilisation du produit, qui relèvent des conseils sur un produit.",
    //   french_text: "PROBLÈMES PRODUITS",
    // },
    // {
    //   contact_reasons: "product_price",
    //   explanation: "Demandes d'informations sur les prix des produits sans référence aux promotions ou à la disponibilité du produit.",
    //   french_text: "PRIX",
    // },
    // {
    //   contact_reasons: "promotions",
    //   explanation: "Questions spécifiquement liées aux promotions en cours, sans référence au prix normal du produit.",
    //   french_text: "PROMOTIONS",
    // },
    // {
    //   contact_reasons: "order_process",
    //   explanation: "Questions sur le processus de commande, excluant les problèmes de paiement, de remboursement et de livraison.",
    //   french_text: "PROCESSUS COMMANDE",
    // },
    // {
    //   contact_reasons: "payments",
    //   explanation: "Demandes spécifiques aux problèmes liés au paiement d'une commande, sans référence au processus de commande ou au remboursement.",
    //   french_text: "PAIEMENTS",
    // },
    // {
    //   contact_reasons: "refunds",
    //   explanation: "Demandes spécifiques aux remboursements et non aux autres aspects de la facturation ou du paiement.",
    //   french_text: "REMBOURSEMENTS",
    // },
    // {
    //   contact_reasons: "disputes",
    //   explanation:
    //     "Demandes liées à des litiges concernant des transactions commerciales, sans référence directe aux remboursements, aux paiements ou au processus de commande.",
    //   french_text: "LITIGES",
    // },
    // {
    //   contact_reasons: "billing",
    //   explanation: "Demandes liées à la facturation hors remboursements, paiements ou litiges.",
    //   french_text: "FACTURATION",
    // },
    // {
    //   contact_reasons: "delivery",
    //   explanation: "Demandes uniquement liées au processus de livraison, sans référence au retour de produit ou au processus de commande.",
    //   french_text: "LIVRAISON",
    // },
    // {
    //   contact_reasons: "returns",
    //   explanation: "Demandes spécifiquement liées au retour d'un produit, sans référence à la livraison ou au service après-vente.",
    //   french_text: "RETOUR",
    // },
    // {
    //   contact_reasons: "customer_account",
    //   explanation: "Demandes liées aux informations du compte, hors problèmes de mot de passe ou problèmes de sécurité.",
    //   french_text: "COMPTE CLIENT",
    // },
    // {
    //   contact_reasons: "password",
    //   explanation: "Demandes spécifiques à la réinitialisation d'un mot de passe, sans référence à d'autres aspects de la gestion du compte client.",
    //   french_text: "MOT DE PASSE",
    // },
    // {
    //   contact_reasons: "security",
    //   explanation:
    //     "Demandes concernant la sécurité du site et les problèmes de fraude, sans référence à la confidentialité des données ou à la gestion du compte client.",
    //   french_text: "SÉCURITÉ",
    // },
    // {
    //   contact_reasons: "privacy",
    //   explanation:
    //     "Demandes sur la politique de confidentialité et l'utilisation des données par l'entreprise, sans référence à la sécurité du site ou à la manière dont les données sont stockées.",
    //   french_text: "CONFIDENTIALITÉ",
    // },
    // {
    //   contact_reasons: "customer_data",
    //   explanation:
    //     "Demandes sur la manière dont les données des clients sont stockées et protégées, sans référence à la politique de confidentialité ou à la sécurité du site.",
    //   french_text: "DONNÉES CLIENTS",
    // },
    // {
    //   contact_reasons: "after_sales",
    //   explanation:
    //     "Demandes adressées au service après-vente, exclusivement pour les réparations. Cela exclut les problèmes liés aux produits eux-mêmes.",
    //   french_text: "SAV",
    // },
    // {
    //   contact_reasons: "technical_problems",
    //   explanation: "Demandes liées aux problèmes techniques spécifiques au site web, sans référence à la sécurité du site.",
    //   french_text: "PROBLÈMES TECHNIQUES",
    // },
    // {
    //   contact_reasons: "website_feedback",
    //   explanation: "Demandes pour donner des commentaires sur le site web, sans référence aux problèmes techniques.",
    //   french_text: "COMMENTAIRES SITE WEB",
    // },
    // {
    //   contact_reasons: "special_programs",
    //   explanation: "Demandes liées à des programmes qui ne sont pas liés à la fidélisation ou à l'affiliation.",
    //   french_text: "PROGRAMMES SPÉCIAUX",
    // },
    // {
    //   contact_reasons: "affiliation",
    //   explanation: "Demandes spécifiques à l'affiliation commerciale, sans référence aux programmes spéciaux ou à la fidélisation.",
    //   french_text: "AFFILIATION",
    // },
    // {
    //   contact_reasons: "customer_loyalty",
    //   explanation: "Demandes liées aux programmes de fidélisation, excluant les autres types de programmes et les programmes spéciaux.",
    //   french_text: "FIDÉLISATION CLIENTÈLE",
    // },
    // {
    //   contact_reasons: "feedback",
    //   explanation: "Demandes de feedbacks ou commentaires sur les produits, sans référence aux plaintes.",
    //   french_text: "FEEDBACKS",
    // },
    // {
    //   contact_reasons: "complaints",
    //   explanation: "Demandes spécifiques liées à des plaintes de la part des clients, sans référence aux feedbacks.",
    //   french_text: "PLAINTES",
    // },
    // {
    //   contact_reasons: "company_information",
    //   explanation: "Demandes d'informations générales sur l'entreprise, sans référence à l'éthique ou à la responsabilité sociale de l'entreprise.",
    //   french_text: "INFORMATION ENTREPRISE",
    // },
    // {
    //   contact_reasons: "ethics_social_responsibility",
    //   explanation:
    //     "Demandes concernant la responsabilité sociale de l'entreprise et les pratiques éthiques, sans référence aux informations générales sur l'entreprise.",
    //   french_text: "ÉTHIQUE & RSE",
    // },
    // {
    //   contact_reasons: "subscription_management",
    //   explanation:
    //     "Demandes spécifiques à la gestion des abonnements, comme par exemple l'inscription à la newsletter, sans référence à la gestion du compte client.",
    //   french_text: "GESTION ABONNEMENTS",
    // },
    // {
    //   contact_reasons: "store_services",
    //   explanation: "Demandes spécifiques aux services proposés en magasin, sans référence aux produits ou aux services en ligne.",
    //   french_text: "SERVICES MAGASINS",
    // },
    // {
    //   contact_reasons: "influencers",
    //   explanation:
    //     "Demandes liées aux collaborations avec des influenceurs, sans référence aux collaborations avec des créateurs de contenu non-influenceurs.",
    //   french_text: "INFLUENCEURS",
    // },
    // {
    //   contact_reasons: "content_creators",
    //   explanation: "Demandes liées aux collaborations avec des créateurs de contenu qui ne sont pas des influenceurs.",
    //   french_text: "CRÉATEURS CONTENUS",
    // },
    // {
    //   contact_reasons: "spam",
    //   explanation: "Demandes où Synibox détecte des comportements de spam, sans référence à d'autres problèmes de sécurité.",
    //   french_text: "SPAM",
    // },
  ],
  colors: [
    "#0bb197",
    "#564ab1",
    "#5664d2",
    "#e83e8c",
    "#ff3d60",
    "#f1734f",
    "#fcb92c",
    "#0ac074",
    "#050505",
    "#4aa3ff"
  ],
  stylePlan: [
    { background: "#7b7b7b", color: "white" },
    { background: "#eff0fe", color: "black" },
    { background: "#5e69f5", color: "white" },
    { background: "#eff0fe", color: "black" }
  ],
  getStylePlan: (index) => {
    if (index % 2 === 0) {
      return { background: "#eff0fe", color: "black" };
    } else {
      return { background: "#5e69f5", color: "white" };
    }
  },
  features: [
    {
      id: "0000",
      plan_id: "trial",
      name: "Support email humain(maximum)",
      description: "",
      type: "number",
      value: 48,
      suffixe: "h",
      on_divide: "sous",
      position: 25
    },
    {
      id: "0001",
      plan_id: "basic",
      name: "Support email humain(maximum)",
      description: "",
      type: "number",
      value: 48,
      suffixe: "h",
      on_divide: "sous",
      position: 25
    },
    {
      id: "0002",
      plan_id: "advanced",
      name: "Support email humain(maximum)",
      description: "",
      type: "number",
      value: 24,
      suffixe: "h",
      on_divide: "sous",
      position: 25
    },
    {
      id: "0003",
      plan_id: "pro",
      name: "Support email humain(maximum)",
      description: "",
      type: "number",
      value: 12,
      suffixe: "h",
      on_divide: "sous",
      position: 25
    },
    {
      id: "0004",
      plan_id: "trial",
      name: "Emails inclus/mois",
      description: "",
      type: "number",
      value: 5,
      suffixe: "emails",
      on_divide: "",
      position: 1
    },
    {
      id: "0005",
      plan_id: "basic",
      name: "Emails inclus/mois",
      description: "",
      type: "number",
      value: 150,
      suffixe: "emails",
      on_divide: "",
      position: 1
    },
    {
      id: "0006",
      plan_id: "advanced",
      name: "Emails inclus/mois",
      description: "",
      type: "number",
      value: 700,
      suffixe: "emails",
      on_divide: "",
      position: 1
    },
    {
      id: "0007",
      plan_id: "pro",
      name: "Emails inclus/mois",
      description: "",
      type: "number",
      value: 2800,
      suffixe: "emails",
      on_divide: "",
      position: 1
    },

    {
      id: "0008",
      plan_id: "trial",
      name: "Allumage/jour",
      description: "",
      type: "number",
      value: 8,
      suffixe: "/",
      on_divide: "24h",
      position: 6
    },
    {
      id: "0009",
      plan_id: "basic",
      name: "Allumage/jour",
      description: "",
      type: "number",
      value: 8,
      suffixe: "/",
      on_divide: "24h",
      position: 6
    },
    {
      id: "0010",
      plan_id: "advanced",
      name: "Allumage/jour",
      description: "",
      type: "number",
      value: 16,
      suffixe: "/",
      on_divide: "24h",
      position: 6
    },
    {
      id: "0011",
      plan_id: "pro",
      name: "Allumage/jour",
      description: "",
      type: "number",
      value: 24,
      suffixe: "/",
      on_divide: "24h",
      position: 6
    },
    {
      id: "0016",
      plan_id: "trial",
      name: "Analyse de la boutique",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "semaine",
      position: 14
    },
    {
      id: "0017",
      plan_id: "basic",
      name: "Analyse de la boutique",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "semaine",
      position: 14
    },
    {
      id: "0018",
      plan_id: "advanced",
      name: "Analyse de la boutique",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "jour",
      position: 14
    },
    {
      id: "0019",
      plan_id: "pro",
      name: "Analyse de la boutique",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "heure",
      position: 14
    },

    {
      id: "0020",
      plan_id: "trial",
      name: "Résumé (email/whatsapp)",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "mois",
      position: 16
    },
    {
      id: "0021",
      plan_id: "basic",
      name: "Résumé (email/whatsapp)",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "mois",
      position: 16
    },
    {
      id: "0022",
      plan_id: "advanced",
      name: "Résumé (email/whatsapp)",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "semaine",
      position: 16
    },
    {
      id: "0023",
      plan_id: "pro",
      name: "Résumé (email/whatsapp)",
      description: "",
      type: "number",
      value: "1",
      suffixe: "x/",
      on_divide: "jour",
      position: 16
    },
    {
      id: "0024",
      plan_id: "trial",
      name: "Nombre d'utilisateurs",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 2
    },
    {
      id: "0025",
      plan_id: "basic",
      name: "Nombre d'utilisateurs",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 2
    },
    {
      id: "0026",
      plan_id: "advanced",
      name: "Nombre d'utilisateurs",
      description: "",
      type: "number",
      value: 3,
      suffixe: "",
      on_divide: "",
      position: 2
    },
    {
      id: "0027",
      plan_id: "pro",
      name: "Nombre d'utilisateurs",
      description: "",
      type: "number",
      value: 10,
      suffixe: "",
      on_divide: "",
      position: 2
    },
    {
      id: "0028",
      plan_id: "trial",
      name: "Langues parlées par Synibox",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 3
    },
    {
      id: "0029",
      plan_id: "basic",
      name: "Langues parlées par Synibox",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 3
    },
    {
      id: "0030",
      plan_id: "advanced",
      name: "Langues parlées par Synibox",
      description: "",
      type: "number",
      value: 4,
      suffixe: "",
      on_divide: "",
      position: 3
    },
    {
      id: "0031",
      plan_id: "pro",
      name: "Langues parlées par Synibox",
      description: "",
      type: "number",
      value: "18",
      suffixe: "",
      on_divide: "",
      position: 3
    },
    {
      id: "0032",
      plan_id: "trial",
      name: "Nombre d'emails",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 4
    },
    {
      id: "0033",
      plan_id: "basic",
      name: "Nombre d'emails",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 4
    },
    {
      id: "0034",
      plan_id: "advanced",
      name: "Nombre d'emails",
      description: "",
      type: "number",
      value: 5,
      suffixe: "",
      on_divide: "",
      position: 4
    },
    {
      id: "0035",
      plan_id: "pro",
      name: "Nombre d'emails",
      description: "",
      type: "string",
      value: "illimité",
      suffixe: "",
      on_divide: "",
      position: 4
    },
    {
      id: "0036",
      plan_id: "trial",
      name: "Nombre d'agents Synibox",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 5
    },
    {
      id: "0037",
      plan_id: "basic",
      name: "Nombre d'agents Synibox",
      description: "",
      type: "number",
      value: 1,
      suffixe: "",
      on_divide: "",
      position: 5
    },
    {
      id: "0038",
      plan_id: "advanced",
      name: "Nombre d'agents Synibox",
      description: "",
      type: "number",
      value: 10,
      suffixe: "",
      on_divide: "",
      position: 5
    },
    {
      id: "0039",
      plan_id: "pro",
      name: "Nombre d'agents Synibox",
      description: "",
      type: "string",
      value: "illimité",
      suffixe: "",
      on_divide: "",
      position: 5
    },
    {
      id: "0040",
      plan_id: "trial",
      name: "Paramétrage Premium",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 7
    },
    {
      id: "0041",
      plan_id: "basic",
      name: "Paramétrage Premium",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 7
    },
    {
      id: "0042",
      plan_id: "advanced",
      name: "Paramétrage Premium",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 7
    },
    {
      id: "0043",
      plan_id: "pro",
      name: "Paramétrage Premium",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 7
    },
    {
      id: "0044",
      plan_id: "trial",
      name: "Processus d'apprentissage",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 8
    },
    {
      id: "0045",
      plan_id: "basic",
      name: "Processus d'apprentissage",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 8
    },
    {
      id: "0046",
      plan_id: "advanced",
      name: "Processus d'apprentissage",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 8
    },
    {
      id: "0047",
      plan_id: "pro",
      name: "Processus d'apprentissage",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 8
    },
    {
      id: "0048",
      plan_id: "trial",
      name: "Pics influence",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 9
    },
    {
      id: "0049",
      plan_id: "basic",
      name: "Pics influence",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 9
    },
    {
      id: "0050",
      plan_id: "advanced",
      name: "Pics influence",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 9
    },
    {
      id: "0051",
      plan_id: "pro",
      name: "Pics influence",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 9
    },
    {
      id: "0052",
      plan_id: "trial",
      name: "Imitation du style",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 10
    },
    {
      id: "0053",
      plan_id: "basic",
      name: "Imitation du style",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 10
    },
    {
      id: "0054",
      plan_id: "advanced",
      name: "Imitation du style",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 10
    },
    {
      id: "0055",
      plan_id: "pro",
      name: "Imitation du style",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 10
    },
    {
      id: "0056",
      plan_id: "trial",
      name: "Attribution auto",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 11
    },
    {
      id: "0057",
      plan_id: "basic",
      name: "Attribution auto",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 11
    },
    {
      id: "0058",
      plan_id: "advanced",
      name: "Attribution auto",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 11
    },
    {
      id: "0059",
      plan_id: "pro",
      name: "Attribution auto",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 11
    },
    {
      id: "0060",
      plan_id: "trial",
      name: "Traduction auto",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 12
    },
    {
      id: "0061",
      plan_id: "basic",
      name: "Traduction auto",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 12
    },
    {
      id: "0062",
      plan_id: "advanced",
      name: "Traduction auto",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 12
    },
    {
      id: "0063",
      plan_id: "pro",
      name: "Traduction auto",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 12
    },
    {
      id: "0064",
      plan_id: "trial",
      name: "Traitement Retours",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 13
    },
    {
      id: "0065",
      plan_id: "basic",
      name: "Traitement Retours",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 13
    },
    {
      id: "0066",
      plan_id: "advanced",
      name: "Traitement Retours",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 13
    },
    {
      id: "0067",
      plan_id: "pro",
      name: "Traitement Retours",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 13
    },
    {
      id: "0072",
      plan_id: "trial",
      name: "Statistiques détaillées",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 15
    },
    {
      id: "0073",
      plan_id: "basic",
      name: "Statistiques détaillées",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 15
    },
    {
      id: "0074",
      plan_id: "advanced",
      name: "Statistiques détaillées",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 15
    },
    {
      id: "0075",
      plan_id: "pro",
      name: "Statistiques détaillées",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 15
    },
    {
      id: "0078",
      plan_id: "trial",
      name: "Alertes générées par Synibox (Attentions requises)",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 17
    },
    {
      id: "0079",
      plan_id: "basic",
      name: "Alertes générées par Synibox (Attentions requises)",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 17
    },
    {
      id: "0080",
      plan_id: "advanced",
      name: "Alertes générées par Synibox (Attentions requises)",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 17
    },
    {
      id: "0081",
      plan_id: "pro",
      name: "Alertes générées par Synibox (Attentions requises)",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 17
    },
    {
      id: "0082",
      plan_id: "trial",
      name: "Protection Optimale (antiSPAM)",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 18
    },
    {
      id: "0083",
      plan_id: "basic",
      name: "Protection Optimale (antiSPAM)",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 18
    },
    {
      id: "0084",
      plan_id: "advanced",
      name: "Protection Optimale (antiSPAM)",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 18
    },
    {
      id: "0085",
      plan_id: "pro",
      name: "Protection Optimale (antiSPAM)",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 18
    },
    {
      id: "0086",
      plan_id: "trial",
      name: "Ajout de ressources (Topics)",
      description: "",
      type: "number",
      value: 3,
      suffixe: "",
      on_divide: "",
      position: 19
    },
    {
      id: "0087",
      plan_id: "basic",
      name: "Ajout de ressources (Topics)",
      description: "",
      type: "number",
      value: 3,
      suffixe: "",
      on_divide: "",
      position: 19
    },
    {
      id: "0088",
      plan_id: "advanced",
      name: "Ajout de ressources (Topics)",
      description: "",
      type: "number",
      value: 50,
      suffixe: "",
      on_divide: "",
      position: 19
    },
    {
      id: "0089",
      plan_id: "pro",
      name: "Ajout de ressources (Topics)",
      description: "",
      type: "number",
      value: 200,
      suffixe: "",
      on_divide: "",
      position: 19
    },
    {
      id: "0090",
      plan_id: "trial",
      name: "Tags actifs en même temps",
      description: "",
      type: "number",
      value: 5,
      suffixe: "",
      on_divide: "",
      position: 20
    },
    {
      id: "0091",
      plan_id: "basic",
      name: "Tags actifs en même temps",
      description: "",
      type: "number",
      value: 5,
      suffixe: "",
      on_divide: "",
      position: 20
    },
    {
      id: "0092",
      plan_id: "advanced",
      name: "Tags actifs en même temps",
      description: "",
      type: "number",
      value: 35,
      suffixe: "",
      on_divide: "",
      position: 20
    },
    {
      id: "0093",
      plan_id: "pro",
      name: "Tags actifs en même temps",
      description: "",
      type: "number",
      value: 35,
      suffixe: "",
      on_divide: "",
      position: 20
    },
    {
      id: "0094",
      plan_id: "trial",
      name: "Formation Vidéo",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 21
    },
    {
      id: "0095",
      plan_id: "basic",
      name: "Formation Vidéo",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 21
    },
    {
      id: "0096",
      plan_id: "advanced",
      name: "Formation Vidéo",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 21
    },
    {
      id: "0097",
      plan_id: "pro",
      name: "Formation Vidéo",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 21
    },
    {
      id: "0098",
      plan_id: "trial",
      name: "Help Center",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 22
    },
    {
      id: "0099",
      plan_id: "basic",
      name: "Help Center",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 22
    },
    {
      id: "0100",
      plan_id: "advanced",
      name: "Help Center",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 22
    },
    {
      id: "0101",
      plan_id: "pro",
      name: "Help Center",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 22
    },
    {
      id: "0102",
      plan_id: "trial",
      name: "Synibox Chat d'aide illimité",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 23
    },
    {
      id: "0103",
      plan_id: "basic",
      name: "Synibox Chat d'aide illimité",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 23
    },
    {
      id: "0104",
      plan_id: "advanced",
      name: "Synibox Chat d'aide illimité",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 23
    },
    {
      id: "0105",
      plan_id: "pro",
      name: "Synibox Chat d'aide illimité",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 23
    },
    {
      id: "0106",
      plan_id: "trial",
      name: "Support téléphone",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 24
    },
    {
      id: "0107",
      plan_id: "basic",
      name: "Support téléphone",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 24
    },
    {
      id: "0108",
      plan_id: "advanced",
      name: "Support téléphone",
      description: "",
      type: "boolean",
      value: false,
      suffixe: "",
      on_divide: "",
      position: 24
    },
    {
      id: "0109",
      plan_id: "pro",
      name: "Support téléphone",
      description: "",
      type: "boolean",
      value: true,
      suffixe: "",
      on_divide: "",
      position: 24
    }
  ],
  featureTitle: [
    "Emails inclus/mois",
    "Nombre d'utilisateurs",
    "Langues parlées par Synibox",
    "Nombre d'emails",
    "Nombre d'agents Synibox",
    "Allumage/jour",
    "Paramétrage Premium",
    "Processus d'apprentissage",
    "Pics influence",
    "Imitation du style",
    "Attribution auto",
    "Traduction auto",
    "Traitement Retours",
    "Analyse de la boutique",
    "Statistiques détaillées",
    "Résumé (email/whatsapp)",
    "Alertes générées par Synibox (Attentions requises)",
    "Protection Optimale (antiSPAM)",
    "Ajout de ressources (Topics)",
    "Tags actifs en même temps",
    "Formation Vidéo",
    "Help Center",
    "Synibox Chat d'aide illimité",
    "Support téléphone",
    "Support email humain(maximum)"
  ],
  authorisations: [
    {
      id: 1,
      parents: [],
      text: "Sélectionner toutes les autorisations",
      value: "Toutes les autorisations sont sélectionnées",
      status: false
    },
    // { id: 2, parents: [1], text: "Accueil", value: "dashboard", status: false },
    { id: 3, parents: [1], text: "E-mail", value: "email", status: false },
    // { id: 4, parents: [1], text: "Chat", value: "chat", status: false },
    // {
    //   id: 5,
    //   parents: [1],
    //   text: "Analytiques",
    //   value: "analytics",
    //   status: false,
    // },
    {
      id: 6,
      parents: [1],
      text: "Paramètres",
      value: "settings",
      status: false
    },
    // {
    //   id: 7,
    //   parents: [1, 2],
    //   text: "Accueil",
    //   value: "dashboard",
    //   status: false
    // },
    {
      id: 8,
      parents: [1, 3],
      text: "Boite de réception",
      value: "inbox",
      status: false
    },
    {
      id: 9,
      parents: [1, 3],
      text: "Mail traités",
      value: "treated",
      status: false
    },
    {
      id: 10,
      parents: [1, 3],
      text: "Mail non-traité",
      value: "untreated",
      status: false
    },
    {
      id: 11,
      parents: [1, 3],
      text: "Créer/Modifier dossier",
      value: "create_box",
      status: false
    },
    {
      id: 12,
      parents: [1, 3],
      text: "Copier/Déplacer/Supprimer message",
      value: "manage_messages",
      status: false
    },
    { id: 13, parents: [1, 4], text: "Chat", value: "chat", status: false },
    // {
    //   id: 14,
    //   parents: [1, 5],
    //   text: "Analytiques",
    //   value: "analytics",
    //   status: false
    // },
    {
      id: 15,
      parents: [1, 6],
      text: "E-mail",
      value: "settings_email",
      status: false
    },
    {
      id: 16,
      parents: [1, 6],
      text: "Collaborateurs",
      value: "collaborateurs",
      status: false
    },
    {
      id: 17,
      parents: [1, 6],
      text: "Facturation",
      value: "billing",
      status: false
    },
    // {
    //   id: 18,
    //   parents: [1, 6],
    //   text: "Forfait",
    //   value: "package",
    //   status: false
    // }
    {
      id: 19,
      parents: [1, 6],
      text: "Synibox",
      value: "maaia",
      status: false
    },

    {
      id: 20,
      parents: [1, 6],
      text: "General",
      value: "general",
      status: false
    }
  ],
  tagsList: [
    { ID: 1, Name: "product" },
    // { ID: 2, Name: "product_availability" },
    // { ID: 3, Name: "product_advice" },
    // { ID: 4, Name: "product_problems" },
    // { ID: 5, Name: "product_price" },
    // { ID: 6, Name: "promotions" },
    { ID: 7, Name: "order" },
    // { ID: 8, Name: "order_process" },
    { ID: 9, Name: "payments" },
    { ID: 10, Name: "refunds" },
    // { ID: 11, Name: "disputes" },
    // { ID: 12, Name: "billing" },
    { ID: 13, Name: "delivery" },
    { ID: 14, Name: "returns" },
    { ID: 15, Name: "customer_account" },
    // { ID: 16, Name: "password" },
    // { ID: 17, Name: "security" },
    // { ID: 18, Name: "privacy" },
    // { ID: 19, Name: "customer_data" },
    // { ID: 20, Name: "after_sales" },
    // { ID: 21, Name: "technical_problems" },
    // { ID: 22, Name: "website_feedback" },
    // { ID: 23, Name: "special_programs" },
    // { ID: 24, Name: "affiliation" },
    { ID: 25, Name: "customer_loyalty" },
    { ID: 26, Name: "feedback" },
    // { ID: 27, Name: "complaints" },
    { ID: 28, Name: "company_information" },
    // { ID: 29, Name: "ethics_social_responsibility" },
    // { ID: 30, Name: "subscription_management" },
    // { ID: 31, Name: "store_services" },
    { ID: 32, Name: "influencers" }
    // { ID: 33, Name: "content_creators" },
    // { ID: 34, Name: "spam" },
    //  { ID: 35, Name: "other" },
  ],
  tags: [
    "product",
    // "product_availability",
    // "product_advice",
    // "product_problems",
    // "product_price",
    // "promotions",
    // "order_process",
    "order",
    "payments",
    "refunds",
    // "disputes",
    // "billing",
    "delivery",
    "returns",
    "customer_account",
    // "password",
    // "security",
    // "privacy",
    // "customer_data",
    // "after_sales",
    // "technical_problems",
    // "website_feedback",
    // "special_programs",
    // "affiliation",
    "customer_loyalty",
    "feedback",
    // "complaints",
    "company_information",
    // "ethics_social_responsibility",
    // "subscription_management",
    // "store_services",
    "influencers"
    // "content_creators",
    // "spam",
    // "other",
    // "Treated",
    // "Untreated",
  ]
};
export default StaticData;
