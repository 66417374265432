import React from "react";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import CrossOutlined from "../../../assets/images/icons/CrossOutlined";
import { useSelector } from "react-redux";
import CustomOneShotCalculator from "../../../Pages/Settings/component/CustomOneShotCalculator";

function CalculatorModal(props) {
  const { userPlan } = useSelector((state) => ({
    userPlan: state.subscription.userPlan
  }));

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered className="modal_wrapper__50x50">
      <ModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%"
        }}>
        <Button
          className="close_button"
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0px",
            position: "absolute",
            right: "1rem"
          }}
          onClick={() => {
            props.toggle();
          }}>
          <CrossOutlined height={26} />
        </Button>
        <Row className={`${userPlan ? "mb-4" : "mb-5"} plan-details h-100`} style={{ paddingLeft: "2%", paddingRight: "2%", overflow: "auto" }}>
          <CustomOneShotCalculator />
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default CalculatorModal;
