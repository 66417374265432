import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Client, Databases, Query } from "appwrite";
import logo_shopify from "./../../assets/images/logo_shopify.png";
import { withTranslation } from "react-i18next";
import { getShopifyConfigurations } from "../../helpers/api_helper";

const ShopifyOauth2 = (props) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const code = urlParams.get("code");
  const shop = urlParams.get("shop");

  const [message, setMessage] = useState({ type: null, text: null });
  const [loading, setLoading] = useState(null);
  const [domain, setDomain] = useState("");
  const onLoadingChanged = (val) => {
    setLoading(val);
  };

  async function updateCompanyInfos(domaine) {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId"),
      {
        shopify_token: code,
        shopify_store_url: shop,
        company_website_url: domaine
      }
    );

    promise.then(
      async function (response) {
        // console.log({ response });

        onLoadingChanged(false);
        setMessage({ type: "success", text: "Enregistrement réusssi." });
        navigate(`/settings`);
      },
      function (error) {
        console.log(error); // Failure
        onLoadingChanged(false);
        setMessage({ type: "error", text: "Erreur inattendue" });
      }
    );
  }
  async function shopifyStoreUrlVerification() {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    try {
      const response = await databases.listDocuments(
        "default",
        "client_infos",
        [Query.equal("shopify_store_url", shop)]
      );

      const company_id = localStorage.getItem("companyId");
      let urlAlreadyExist = true;

      if (response.total > 0) {
        urlAlreadyExist = true;
        const client_infos = response.documents;
        for (let index = 0; index < client_infos.length; index++) {
          const client_info = client_infos[index];
          if (client_info.$id === company_id) {
            urlAlreadyExist = false;
            break;
          }
        }
      } else {
        urlAlreadyExist = false;
      }
      return urlAlreadyExist;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  const handleOaut = async () => {
    onLoadingChanged(true);
    setDomain("");
    setMessage({ type: null, text: null });
    const urlAlreadyExist = await shopifyStoreUrlVerification();
    console.log({ isUrlAlreadyExist: urlAlreadyExist });
    if (urlAlreadyExist === undefined) {
      setMessage({ type: "error", text: props.t("Erreur inattendue") });
      onLoadingChanged(false);
    } else if (urlAlreadyExist !== undefined && !urlAlreadyExist) {
      const shopifyConf = await getShopifyConfigurations(code, shop);
      if (shopifyConf && shopifyConf.shop) {
        updateCompanyInfos(shopifyConf.shop.primaryDomain.host);
      } else {
        setDomain(undefined);
        setMessage({ type: "error", text: props.t("shopify_busy_shop_url") });
        onLoadingChanged(false);
      }
    } else {
      setMessage({ type: "error", text: props.t("shopify_busy_shop_url") });
      onLoadingChanged(false);
    }
  };
  useEffect(() => {
    console.log({ code });
    if (code && shop) {
      handleOaut();
    } else {
      // navigate(`/settings`);
    }
  }, []);

  return (
    <>
      {/* {code && shop && ( */}
      <React.Fragment>
        <div className="bg-pattern" style={{ height: "100vh" }}>
          <div className="account-pages pt-5">
            <Container>
              <Row className="w-100 justify-content-center">
                <Col lg={12} md={6} xl={6}>
                  <Card className="mt-5">
                    <CardBody className="p-4">
                      <div className="">
                        <div className="text-center">
                          <Link to="/" className="">
                            <div
                              className=""
                              style={{
                                marginTop: "3vh",
                                color: "#151d48",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "32px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "140%"
                              }}
                            >
                              {props.t("Connexion")}{" "}
                              {message.type === "error" ? "" : "..."}
                            </div>
                          </Link>
                        </div>
                        <form className="form-horizontal" action="#">
                          <Row>
                            <Col className="d-flex justify-content-center">
                              {/* <i className="fab fa-shopify" style={{ fontSize: "100px", color: "#96BF48" }} /> */}
                              <div className="d-flex align-items-center justify-content-between">
                                <img
                                  src={logo_shopify}
                                  style={{ height: "35px" }}
                                  alt="logo_cms"
                                />
                              </div>
                            </Col>
                            <Col
                              className="d-flex flex-column align-items-center"
                              md={12}
                            >
                              {loading && (
                                <span
                                  className="spinner-border spinner-border-xxl me-2 mt-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              {loading && !message.text && (
                                <p className="mt-3 font-primary text-center font-size-16">
                                  Nous avons presque terminé, veuillez patienter
                                  s'il vous plait...
                                </p>
                              )}
                              {!loading && message.type === "success" && (
                                <p className="mt-3 font-primary text-center font-size-16">
                                  Super, nous avons terminé, nous vous
                                  redirigeons...
                                </p>
                              )}
                              {!loading && message.type === "error" && (
                                <div className="alert alert-danger text-center font-primary">
                                  {props.t(message.text)}
                                </div>
                              )}

                              {message.type === "error" &&
                              domain === undefined ? (
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="view-button d-flex align-items-center ressouceAdd-button ml-auto"
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "auto",

                                      backgroundColor: "#563BFF"
                                    }}
                                    onClick={() => {
                                      handleOaut();
                                    }}
                                  >
                                    {props.t("Réessayer")}
                                  </button>
                                </div>
                              ) : (
                                message.type === "error" && (
                                  <div className="col-auto">
                                    <button
                                      type="button"
                                      className="view-button d-flex align-items-center ressouceAdd-button ml-auto"
                                      style={{
                                        marginTop: "10px",
                                        marginLeft: "auto",

                                        backgroundColor: "#563BFF"
                                      }}
                                      onClick={() => {
                                        navigate(`/dashboard`);
                                      }}
                                    >
                                      {props.t("Retour")}
                                    </button>
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p className="simple-text font-primary">
                      © {new Date().getFullYear()} Synibox. By Synibox
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
      {/* )} */}
    </>
  );
};

export default withTranslation()(ShopifyOauth2);
