import React from "react";

function EmailFilled(props) {
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        d="M9.25 2.25C6.44974 2.25 5.04961 2.25 3.98005 2.79497C3.03924 3.27433 2.27433 4.03924 1.79497 4.98005C1.25 6.04961 1.25 7.44974 1.25 10.25V13.75C1.25 16.5503 1.25 17.9504 1.79497 19.02C2.27433 19.9608 3.03924 20.7257 3.98005 21.205C5.04961 21.75 6.44974 21.75 9.25 21.75H14.75C17.5503 21.75 18.9504 21.75 20.02 21.205C20.9608 20.7257 21.7257 19.9608 22.205 19.02C22.75 17.9504 22.75 16.5503 22.75 13.75V10.25C22.75 7.44974 22.75 6.04961 22.205 4.98005C21.7257 4.03924 20.9608 3.27433 20.02 2.79497C18.9504 2.25 17.5503 2.25 14.75 2.25H9.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.37592 7.58393C5.60568 7.23929 6.07134 7.14616 6.41598 7.37592L7.41598 8.04259C9.06626 9.14277 9.7633 9.59808 10.4854 9.81565C11.4732 10.1133 12.5267 10.1133 13.5145 9.81565C14.2366 9.59808 14.9337 9.14277 16.5839 8.04259L17.5839 7.37592C17.9286 7.14616 18.3942 7.23929 18.624 7.58393C18.8538 7.92858 18.7606 8.39423 18.416 8.624L17.416 9.29066C17.3768 9.31679 17.338 9.34265 17.2996 9.36825C15.8013 10.3673 14.9097 10.9619 13.9472 11.2519C12.6772 11.6345 11.3227 11.6345 10.0527 11.2519C9.09022 10.9619 8.19859 10.3673 6.7003 9.36825C6.66191 9.34265 6.62313 9.31679 6.58393 9.29066L5.58393 8.624C5.23929 8.39423 5.14616 7.92858 5.37592 7.58393Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EmailFilled;
