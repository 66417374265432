import usFlag from "../assets/images/flags/us.png";
import spain from "../assets/images/flags/spain.png";
import germany from "../assets/images/flags/germany.jpg";
import italy from "../assets/images/flags/italy.jpg";
import russia from "../assets/images/flags/russia.jpg";
import france from "../assets/images/flags/french.png";

const languages = {
  fr: {
    label: "French",
    flag: france
  },
  // es: {
  //   label: "Spanish",
  //   flag: spain,
  // },
  // de: {
  //   label: 'German',
  //   flag: germany,
  // },
  // ru: {
  //   label: 'Russian',
  //   flag: russia,
  // },
  // it: {
  //   label: 'Italian',
  //   flag: italy,
  // },
  en: {
    label: "English",
    flag: usFlag
  }
};

export default languages;
