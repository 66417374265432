import React, { useEffect, useState } from "react";
import "./chat.scss";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import { Collapse } from "reactstrap";
import { useSelector } from "react-redux";
import { formatDate } from "../../helpers/api_helper";

function TrialInfoBubble(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { next_billed_at, active_subscription_id, client_data } = useSelector((state) => {
    return {
      next_billed_at: state.subscription.subscription.current_period_end,
      active_subscription_id: state.subscription.subscription.id,
      client_data: state.GeneralStateRedux.client_data
    };
  });
  const [numberOfDays, setNumberOfDays] = useState(0);

  // function formatDate(millisec, short = false) {
  //   const options = short ? { day: "2-digit", month: "short", year: "2-digit" } : { day: "2-digit", month: "long", year: "numeric" };
  //   const date = new Date(millisec);
  //   return date.toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), options);
  //   // return "";
  // }

  useEffect(() => {
    if (next_billed_at) {
      setNumberOfDays(Math.round(Math.abs(new Date(next_billed_at) - new Date()) / (24 * 60 * 60 * 1000)));
    }
  }, [next_billed_at]);
  return (
    next_billed_at &&
    active_subscription_id &&
    client_data && (
      <div
        id="trial_info_bubble"
        style={{
          position: "fixed",
          bottom: "75px",
          right: "20px",
          zIndex: "99999",
          maxWidth: "360px"
        }}>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFlushOne" style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}>
              <button
                className={classnames("accordion-button", "", { collapsed: !isOpen })}
                type="button"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                style={{ cursor: "pointer", fontWeight: "500" }}>
                {props.t("Il vous reste")} {numberOfDays} {numberOfDays > 1 ? `${props.t("jours")}` : `${props.t("jour")}`} {props.t("d'essai")}
              </button>
            </h2>

            <Collapse isOpen={isOpen} className="accordion-collapse">
              <div
                className="accordion-body px-3 pt-0"
                style={{
                  fontWeight: "500",
                  color: "white"
                }}>
                {props.t("Votre essai se termine le")} {formatDate(props, next_billed_at, true, true)}.<br />
                {props.t("Écourtez votre essai et bénéficiez dès maintenant de tous les avantages du plan choisi.")}
                <a
                  href={
                    client_data.stripe_region === "US"
                      ? `/trial-interruption?region=US&id=${active_subscription_id}`
                      : `/trial-interruption?id=${active_subscription_id}`
                  }
                  className="mt-2 mb-2 view-button simple-text font-primary"
                  style={{
                    backgroundColor: "white"
                  }}>
                  {props.t("Écourter l'essai")}
                </a>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    )
  );
}
export default withTranslation()(TrialInfoBubble);
