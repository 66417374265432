import React, { useEffect, useState } from "react";
import Slider from "react-rangeslider";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { SMARTAS } from "../../../helpers/api_helper";

const CustomOneShotCalculator = (props) => {
  const { userPlan } = useSelector((state) => ({
    userPlan: state.subscription.userPlan
  }));

  const [numberOfCreditsPacks, setNumberOfCreditsPacks] = useState(10);
  const { client_data } = useSelector((state) => {
    return {
      client_data: state.GeneralStateRedux.client_data
    };
  });

  return (
    <div className="mx-auto d-flex" style={{ flexDirection: "column", width: "94%", userSelect: "none" }}>
      <div className="mx-auto mt-4" style={{ width: "100%" }}>
        <h4>{props.t("Number of Credits")}</h4>
        <div className="w-100 d-flex gap-2" style={{ flexDirection: "column" }}>
          <span className="font-size-16" style={{ fontWeight: "bold" }}>{`${numberOfCreditsPacks * 100} ${props.t("credits")}`}</span>
          <Slider
            min={0}
            max={100}
            step={10}
            tooltip={false}
            value={numberOfCreditsPacks}
            className="my-0 w-100 custom_range_slider"
            onChange={(val) => {
              if (val > 0) {
                setNumberOfCreditsPacks(val);
              }
            }}
          />
          <div className="d-flex justify-content-between w-100">
            <span className="float-left simple-text font-primary" style={{ userSelect: "none" }}>
              0
            </span>
            <span className="float-right simple-text font-primary" style={{ userSelect: "none" }}>
              10.000
            </span>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex align-items-center mt-4 gap-4" style={{ flex: "1", flexDirection: "column" }}>
        <div className="d-flex" style={{ flexDirection: "column" }}>
          <div className="cont-formula mt-3 mb-2" style={{ fontSize: "1.5rem", color: "rgb(35, 0, 87)" }}>
            <div className="prefix-field" style={{ fontWeight: "600", lineHeight: "initial" }}>
              {props.t("Total à payer")} $
            </div>
            <div className="rendered-formula" hide-decimals="true" style={{ lineHeight: "initial" }}>
              {SMARTAS.CREDITS_PACK_PRICE[userPlan] * (numberOfCreditsPacks / 10)}
            </div>
          </div>
          <div className="d-flex font-size-16">
            <div className="" style={{ fontWeight: "600" }}>
              {SMARTAS.CREDITS_PACK_PRICE[userPlan]}
            </div>
            <div className="d-flex">
              <div style={{ fontStyle: "italic" }}>$ / 1000 {props.t("credits")}</div>
            </div>
          </div>
          <div className="d-flex font-size-16 gap-1">
            <div className="" style={{ fontWeight: "600" }}>
              {numberOfCreditsPacks * 100}
            </div>
            <div className="d-flex">
              <div>{props.t("credits")}</div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <a
            className="pricing_button-2 w-100"
            style={{ textAlign: "center" }}
            href={
              client_data.stripe_region === "US"
                ? `/one-shot-checkout?c=${numberOfCreditsPacks / 10}&p=${userPlan}&region=US`
                : `/one-shot-checkout?c=${numberOfCreditsPacks / 10}&p=${userPlan}`
            }>
            <strong className="font-size-16">{props.t("Pay now")}</strong>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CustomOneShotCalculator);
