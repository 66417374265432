import React, { useState, useEffect } from "react";
import GaugeChart from "react-gauge-chart";
import {
  getUserCompanyInfos,
  createAgentFunctionExecution,
  getClientData,
  checkAIAgentFunction,
  checkStatisticsFunction,
  createStatisticsFunction,
  checkClientDataFunction,
  createClientInfo
} from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { applyConsumedCredit, fetchClientInfos } from "../../store/actions";
import { fetchUserSubscription } from "../../store/subscription/actions";

const LiveGaugeChart = (props) => {
  //  const [client_data, setCompanyInfos] = useState(undefined);
  const dispatch = useDispatch();

  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [loadingChargebeeInfos, setLoadingChargebeeInfos] = useState(false);
  // const [emailCount, setEmailCount] = useState(0);

  const {
    consumed_email_count,
    client_data,
    number_of_credits,
    is_user_plan_active,
    subscription,
    userPlan
  } = useSelector((state) => {
    return {
      subscription: state.subscription.subscription,
      userPlan: state.subscription.userPlan,
      is_user_plan_active: state.subscription.isUserPlanActive,
      number_of_credits: state.subscription.subscriptionCredits,
      consumed_email_count: state.GeneralStateRedux.consumed_email_count,
      client_data: state.GeneralStateRedux.client_data
    };
  });

  const [loadingStripeInfos, setLoadingStripeInfos] = useState(false);

  // console.log({ client_data });
  // console.log({ credit });

  // async function checkAIAgent() {
  //   const agentsList = client_data.agents;

  //   if (agentsList && agentsList.length > 0) {
  //     const agents = agentsList.map((element) => JSON.parse(element));
  //     const result = await checkAIAgentFunction(agents[0].id, client_data.$id);
  //     console.log("checkAIAgentFunction result", result);
  //     if (result && result.error_code && result.error_code === 404) {
  //       console.log("checkAIAgentFunction no function found", result.error_code);
  //       await createAgentFunctionExecution(client_data.$id, agents[0].id);
  //     }
  //   }
  // }

  async function checkStatistics() {
    const result = await checkStatisticsFunction(client_data.$id);

    if (result && result.error_code && result.error_code === 404) {
      console.log(
        "checkStatisticsFunction no function found",
        result.error_code
      );
      await createStatisticsFunction(client_data.$id);
    }
  }

  async function checkClientData() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log({ user });
    const result = await checkClientDataFunction(user.$id);

    if (result && result.error_code && result.error_code === 404) {
      console.log("checkClientDataFunction no document found", result);
      const data = await createClientInfo(user.$id, { company: "" });
      if (data) {
        dispatch(applyConsumedCredit(data.consumed_email_count, data));
      }
    }
  }

  const handleUserCompanyInfo = async () => {
    try {
      const userId = localStorage.getItem("companyId");
      if (userId) {
        const response = await getClientData();
        if (response) {
          if (!client_data) {
            // setCompanyInfos(response);
          } else if (
            response.consumed_email_count !== client_data.consumed_email_count
          ) {
            //  setCompanyInfos(response);
          }
        }
      }
    } catch (error) {}
  };
  // Simulate live data updates

  // const getEmailCount = (subscriptionData) => {
  //   const emailItem = subscriptionData.subscription_items.find((item) => item.item_price_id === "nb-emails-EUR-Monthly");
  //   console.log({ emailItem });
  //   setEmailCount(emailItem ? emailItem.quantity : 0);
  // };

  // const handleFetchChargeBeeInfos = async () => {
  //   console.log("gaugechart handleFetchChargeBeeInfos");
  //   setLoadingChargebeeInfos(true);
  //   try {
  //     //
  //     const customers = await getCustomerChargeBee(undefined, props.userInfos.email);
  //     console.log({ customers });
  //     if (customers.length) {
  //       const subscriptions = await getSubscriptionsChargeBee(undefined, customers[0].id);
  //       console.log({ gaugechart_subscriptions: subscriptions });
  //       setSubscriptions(subscriptions);
  //       const active_subscription = subscriptions.find((subscription) => subscription.status === "active");
  //       console.log({ gaugechart_activeSubscription: active_subscription });
  //       setActiveSubscription(active_subscription);
  //       getEmailCount(active_subscription);
  //     }
  //     setLoadingChargebeeInfos(false);
  //   } catch (error) {
  //     setLoadingChargebeeInfos(false);
  //     // console.log({ error });
  //   }
  // };

  useEffect(() => {
    dispatch(fetchClientInfos());
    dispatch(fetchUserSubscription());
  }, [props.userInfos]);

  useEffect(() => {
    if (
      client_data &&
      number_of_credits &&
      ((subscription && is_user_plan_active && !subscription.pause) ||
        userPlan === "MAAIA_PLAN_DIRECT_WORLD")
    ) {
      // setEmailCount(number_of_credits);
      // checkAIAgent();
      //   checkStatistics();
    } else if (!client_data) {
      checkClientData();
    }
  }, [client_data, number_of_credits, is_user_plan_active]);

  return (
    <div className="d-flex align-items-center">
      {client_data && (
        <div>
          {" "}
          <div style={{ width: "77px", height: "20px", marginBottom: "20px" }}>
            <GaugeChart
              id="gauge-chart1"
              style={{
                width: "100%"
              }}
              marginInPercent={0}
              cornerRadius={0}
              nrOfLevels={6}
              // percent={value}
              percent={
                /* credit && */ number_of_credits &&
                number_of_credits > 0 &&
                client_data
                  ? consumed_email_count >= number_of_credits
                    ? 0
                    : (number_of_credits - consumed_email_count) /
                      number_of_credits
                  : 0
              }
              arcPadding={0.03}
              arcWidth={0.23}
              colors={[
                "#FF7B69",
                "#FFB293",
                "#C8DFFF",
                "#C4CDFF",
                "#B497FF",
                "#6C67FF"
              ]}
              needleColor={"#151D48"}
              needleBaseColor={"#151D48"}
              hideText={true}
              // arcsLength={[0.3, 0.17, 0.17, 0.17, 0.17, 0.3]}
              animate={false}
              // animDelay={500}
              // animateDuration={3000}
              // formatTextValue={null}
            />
          </div>
          <div
            className="gauge-stats-container mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <span
              className="gauge-stats__number"
              style={{
                color: "#9DA4B1",
                fontFamily: "Poppins, sans-serif",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal"
              }}
            >
              {number_of_credits && number_of_credits > 0
                ? consumed_email_count >= number_of_credits
                  ? "0"
                  : number_of_credits - consumed_email_count
                : "0"}
              {" / "}
            </span>
            <span
              className="gauge-stats__total"
              style={{
                color: "#151D48",
                fontFamily: "Poppins, sans-serif",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal"
              }}
            >
              {number_of_credits && number_of_credits > 0
                ? number_of_credits
                : 0}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveGaugeChart;
