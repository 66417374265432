import {
  AGENT_ACTIVE_LOADING,
  SHOW_AGENT_STATUS,
  APPLY_CONSUMED_CREDIT,
  FETCH_CONSUMED_CREDIT as FETCH_CLIENT_INFOS,
  EMPTY_GENERAL_STATE
} from "./actionTypes";

export const isActivatedAgentStatus = (active) => ({
  type: SHOW_AGENT_STATUS,
  payload: active
});

export const isAgentActiveLoading = (load) => ({
  type: AGENT_ACTIVE_LOADING,
  payload: load
});

export const applyConsumedCredit = (count, company_data) => ({
  type: APPLY_CONSUMED_CREDIT,
  payload: { consumed_email_count: count, client_data: company_data }
});

export const fetchClientInfos = () => {
  return {
    type: FETCH_CLIENT_INFOS,
    payload: {}
  };
};

export const emptyGeneralStateStorage = () => {
  return {
    type: EMPTY_GENERAL_STATE,
    payload: {}
  };
};
