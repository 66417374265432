import React, { useState } from "react";
import "./EmailForm.css"; // Importez votre fichier CSS pour les styles ici
import { Col, Row, Container } from "reactstrap";
import axios from "axios";

const EmailForm = ({ onSubmit, formData, onChange }) => {
  const { email_subject, email_body, email_sender } = formData;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form className="email-form" onSubmit={handleSubmit}>
      <label>E-mail client (e-mail unique)</label>
      <input
        className="input-field"
        type="text"
        name="email_sender"
        value={email_sender}
        onChange={handleInputChange}
        required
      />
      <label>Objet du message</label>
      <input
        className="input-field"
        type="text"
        name="email_subject"
        value={email_subject}
        onChange={handleInputChange}
        required
      />
      <label>Message</label>
      <textarea
        className="input-field"
        name="email_body"
        value={email_body}
        onChange={handleInputChange}
        required
      />
    </form>
  );
};

const TestEmail = () => {
  const [send_email_request, setSendEmailRequestValue] = useState(false);
  const [ia_response, showResult] = useState(undefined);

  const [formsData, setFormsData] = useState([
    {
      email_id: "",
      email_subject: "",
      email_body: "",
      email_sender: ""
    }
  ]);

  const onSendEmailRequestChange = (value) => {
    setSendEmailRequestValue(value);
  };

  const onShowResult = (value) => {
    showResult(value);
  };

  const addEmail = (newEmail) => {
    setFormsData([...formsData, newEmail]);
  };

  const handleFormChange = (formData, index) => {
    formData["email_id"] = formData["email_sender"];
    const updatedFormsData = [...formsData];
    updatedFormsData[index] = formData;
    setFormsData(updatedFormsData);
  };

  async function generateJSON() {
    let list = [];
    formsData.forEach((element) => {
      if (element.email_sender && element.email_body) {
        const item = {
          email_id: element.email_sender,
          email_subject: element.email_subject,
          email_body: element.email_body,
          email_sender: element.email_sender
        };
        list.push(item);
      }
    });

    console.log("jsonData is ", list);

    console.log("AIResponse called");

    onSendEmailRequestChange(true);

    axios
      .post(
        "https://ai.semecloud.tech/email-response",
        {
          message: {
            emails: list,
            company_id: localStorage.getItem("companyId")
          }
        }
        /*
              {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET,OPTIONS,PATCH,DELETE,POST,PUT',
                  'Access-Control-Allow-Headers': 'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version'
                }
              }
              */
      )
      .then((response) => {
        // Traiter les données de la réponse

        onShowResult(response);
        onSendEmailRequestChange(false);
        console.log(
          "formData.email_id is",
          formsData.map((formData) => formData.email_id)
        );
        console.log(
          "response.result is",
          formsData.map((formData) => response.result[formData.email_id])
        );
      })
      .catch((error) => {
        // Gérer l'erreur
        let errorMessage;
        if (error.response) {
          // La requête a été reçue, mais le serveur a répondu avec un code d'erreur
          console.log(
            "// La requête a été reçue, mais le serveur a répondu avec un code d'erreur"
          );
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          errorMessage = error.response.data;
        } else if (error.request) {
          console.log(
            "// La requête a été envoyée, mais aucune réponse n'a été reçue"
          );
          console.log(error.request);
          errorMessage = "Echec";
        } else {
          console.log(
            "// Une erreur est survenue lors de la création de la requête"
          );
          console.log("Erreur", error.message);
          errorMessage = error.message;
        }
        // console.log("errorMessage is", errorMessage);
        onSendEmailRequestChange(false);
        onShowResult({ error: errorMessage });
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true} style={{ marginTop: "60px" }}>
          <Row>
            <Col xl={8}>
              <div className="email-container">
                <Row>
                  <Col xl={8}>
                    <h4 className="header">Formulaire E-mails</h4>
                  </Col>
                  <Col xl={4}>
                    <button
                      className="add-button"
                      onClick={() =>
                        addEmail({
                          email_id: "",
                          email_subject: "",
                          email_body: "",
                          email_sender: ""
                        })
                      }
                    >
                      Ajouter un message
                    </button>
                  </Col>
                </Row>
                <Row>
                  {formsData.map((formData, index) => (
                    <Col xl={formsData.length > 1 ? 6 : 12}>
                      <div className="email-form-wrapper" key={index}>
                        <EmailForm
                          onSubmit={addEmail}
                          formData={formData}
                          onChange={(newFormData) =>
                            handleFormChange(newFormData, index)
                          }
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
                {send_email_request ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <button className="generate-button" onClick={generateJSON}>
                    ENVOYER
                  </button>
                )}
              </div>
            </Col>
            <Col xl={4}>
              <h4 className="added-emails-header">Réponses de Synibox</h4>
              <br></br>
              {send_email_request ? (
                <span></span>
              ) : ia_response ? (
                ia_response.result ? (
                  <div>
                    {" "}
                    <ul className="email-list">
                      {" "}
                      {formsData.map((formData, index) =>
                        formData["email_id"] !== "" &&
                        ia_response.result[formData["email_id"]] ? (
                          <li className="email-item" key={index}>
                            <hr></hr>
                            <div>
                              <strong>Réponse Mail {index + 1}:</strong>
                              <br></br>
                              {
                                ia_response.result[formData["email_id"]]
                                  .mail_response
                              }
                            </div>
                            {ia_response.result[formData["email_id"]]
                              .failure_reason &&
                              ia_response.result[formData["email_id"]]
                                .failure_reason.length > 0 && (
                                <div style={{ paddingLeft: "25px" }}>
                                  <strong>
                                    Raison de l'échec {index + 1}:
                                  </strong>
                                  <br></br>
                                  {
                                    ia_response.result[formData["email_id"]]
                                      .failure_reason
                                  }
                                </div>
                              )}
                            {ia_response.result[formData["email_id"]]
                              .missing_data &&
                              ia_response.result[formData["email_id"]]
                                .missing_data.length > 0 && (
                                <div style={{ paddingLeft: "25px" }}>
                                  <strong>
                                    Données manquante pour le traitement de la
                                    demande {index + 1}:
                                  </strong>
                                  <br></br>
                                  {
                                    ia_response.result[formData["email_id"]]
                                      .missing_data
                                  }
                                </div>
                              )}
                          </li>
                        ) : (
                          <div></div>
                        )
                      )}
                    </ul>
                    <br></br>
                    <div style={{ fontSize: " 14px" }}>
                      <strong>Analyse Synibox:</strong>
                      <br></br>Entrée: {ia_response.token_analyse.prompt_tokens}{" "}
                      ({" "}
                      {(
                        (ia_response.token_analyse.prompt_tokens * 0.03) /
                        1000
                      ).toFixed(3)}
                      € )<br></br>Sortie:{" "}
                      {ia_response.token_analyse.completion_tokens} ({" "}
                      {(
                        (ia_response.token_analyse.completion_tokens * 0.06) /
                        1000
                      ).toFixed(3)}
                      € )<br></br>Total:{" "}
                      {ia_response.token_analyse.prompt_tokens +
                        ia_response.token_analyse.completion_tokens}{" "}
                      ({" "}
                      {(
                        (ia_response.token_analyse.prompt_tokens * 0.03) /
                          1000 +
                        (ia_response.token_analyse.completion_tokens * 0.06) /
                          1000
                      ).toFixed(3)}
                      € )
                    </div>
                    <br></br>
                    <div style={{ fontSize: " 14px" }}>
                      <strong>RÉPONSE Email:</strong>
                      <br></br>Entrée:{" "}
                      {ia_response.token_email_response.prompt_tokens} ({" "}
                      {(
                        (ia_response.token_email_response.prompt_tokens *
                          0.03) /
                        1000
                      ).toFixed(3)}
                      € )<br></br>Sortie:{" "}
                      {ia_response.token_email_response.completion_tokens} ({" "}
                      {(
                        (ia_response.token_email_response.completion_tokens *
                          0.06) /
                        1000
                      ).toFixed(3)}
                      € )<br></br>Total:{" "}
                      {ia_response.token_email_response.prompt_tokens +
                        ia_response.token_email_response.completion_tokens}{" "}
                      ({" "}
                      {(
                        (ia_response.token_email_response.prompt_tokens *
                          0.03) /
                          1000 +
                        (ia_response.token_email_response.completion_tokens *
                          0.06) /
                          1000
                      ).toFixed(3)}
                      € )
                    </div>
                    <br></br>
                    <div style={{ fontSize: " 14px" }}>
                      <strong>Token Total:</strong> {ia_response.token_total} ({" "}
                      {(
                        (ia_response.token_analyse.prompt_tokens * 0.03) /
                          1000 +
                        (ia_response.token_analyse.completion_tokens * 0.06) /
                          1000 +
                        ((ia_response.token_email_response.prompt_tokens *
                          0.03) /
                          1000 +
                          (ia_response.token_email_response.completion_tokens *
                            0.06) /
                            1000)
                      ).toFixed(3)}{" "}
                      €)
                    </div>{" "}
                  </div>
                ) : (
                  <div>Erreur: {ia_response.error}</div>
                )
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TestEmail;
