import React, { useState, useEffect } from "react";

import { Row } from "reactstrap";
import LANGAGE_CODE_NAME from "../Utility/LangageCodeName";
import ReactApexChart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import languages from "../../assets/images/languages.png";
import logo_maaia from "../../assets/images/companies/img-3.png";

const LangageStateChart = (props) => {
  let options = null;
  const [loading, setLoading] = useState(true);
  const [labels, setlabelsList] = useState();
  const [series, setseriesList] = useState();
  const [dataGraph, setDataGraph] = useState();
  const theMessages = props.messages;
  const theStatistics = props.statistics;
  const [total, settotal] = useState();

  const getFrenchTransation = (tag) => {
    switch (tag) {
      case "fr":
        return "Français";
      case "en":
        return "Anglais";
      case "es":
        return "Espagnol";
      case "zh":
        return "Chinois";
      case "it":
        return "Italien";
      case "de":
        return "Allemand";
      case "pt":
        return "Portugais";
      default:
        return "-";
    }
  };

  const getLanguageColor = (tag) => {
    switch (tag) {
      case "fr":
        return "#563BFF";
      case "en":
        return "#FF7049";
      case "es":
        return "#FFA412";
      case "zh":
        return "#aaed24";
      case "it":
        return "#0fd112";
      case "de":
        return "#9d0fd1";
      case "pt":
        return "#d10faa";
      default:
        return "#FF7049";
    }
  };

  async function getMessageData(datas) {
    let languageCounts = { "-": 1 };

    if (datas && datas.length) {
      let the_latest_data = datas[0];
      console.log({ the_latest_data });

      const dataToSet = the_latest_data && the_latest_data.language_usages ? JSON.parse(the_latest_data.language_usages) : undefined;
      if (dataToSet["-"] === undefined) {
        languageCounts = dataToSet;
      }
    }

    setlabelsList(
      Object.keys(languageCounts).sort((a, b) => {
        const valA = languageCounts[a];
        const valB = languageCounts[b];
        return valB - valA;
      })
    );
    const languageCountsValues = Object.values(languageCounts);
    const totalSum = languageCountsValues.reduce((partialSum, a) => partialSum + a, 0);
    settotal(total);
    const percentages = languageCountsValues.map((value) => ((value / totalSum) * 100).toFixed(2));
    console.log("languageCounts is: ", Object.values(languageCounts));
    console.log("languages are: ", Object.keys(languageCounts));
    console.log("languages map is: ", languageCounts);

    console.log("percentages is: ", percentages);
    console.log("percentages is: ", total);
    setseriesList(percentages);
    setLoading(false);

    const dountchartData = {
      series: Object.values(languageCounts).sort((a, b) => b - a),
      options: {
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0
        },
        labels:
          languageCounts["-"] !== undefined
            ? [props.t("Aucune langue")]
            : Object.keys(languageCounts)
                .sort((a, b) => {
                  const valA = languageCounts[a];
                  const valB = languageCounts[b];
                  return valB - valA;
                })
                .map((language) => props.t(getFrenchTransation(language))),
        colors: Object.keys(languageCounts).map((language) => getLanguageColor(language)),
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240
              },
              legend: {
                show: false
              }
            }
          }
        ]
      }
    };
    //console.log({ dountchartData });
    setDataGraph(dountchartData);
  }
  function convertirCodePaysEnNomComplet(codePays) {
    const nomComplet = LANGAGE_CODE_NAME[codePays];
    return nomComplet || "Langue inconnu";
  }
  const seriesVide = ["100.00"];
  useEffect(() => {
    getMessageData(theStatistics);
  }, [theStatistics]);

  // const data = {
  //   labels: ["Anglais", "Français"],
  //   datasets: [
  //     {
  //       data: [21, 16],
  //       backgroundColor: ["#FF7049", "#563BFF"],
  //       borderColor: ["#FF7049", "#563BFF"],
  //     },
  //   ],
  // };

  // const option = {

  //   aspectRatio: 1,
  //   // layout: {
  //   //   padding: {
  //   //       left: 0,
  //   //       right: 0,
  //   //       top: 0,
  //   //       bottom: 0,
  //   //   }
  //   // },
  //   legend: {
  //     position: "bottom",
  //   },

  //   responsive: true,
  //   cutoutPercentage: 70,
  //   animation: {
  //     animateScale: true,
  //     animateRotate: true,
  //   },
  // };

  return (
    <React.Fragment>
      {/*{dataGraph && dataGraph.series.length > 0 && dataGraph.options.labels[0] !== "-" ? (*/}
      <>
        {dataGraph && (
          <Row>
            <ReactApexChart options={dataGraph.options} series={dataGraph.series} type="donut" height="326" className="apex-charts" />
          </Row>
        )}
        <div className="d-flex justify-content-center">
          <div className="customLegend" style={{ gap: "18px" }}>
            {dataGraph &&
              dataGraph.options.labels.slice(0, 4).map((label, index) => (
                <div key={index} className="d-flex align-items-center">
                  <div className="legend" style={{ backgroundColor: dataGraph.options.colors[index] }}></div>
                  <span style={{ fontSize: "12px" }} className="font-primary">
                    {props.t(label)}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </>
      {/*) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + languages + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}>
          <div
            className="font-primary font-size-22 color-black mt-4 mb-1 text-center"
            style={{
              width: "418px",
              lineHeight: "28px"
            }}>
            {props.t("Les statistiques seront visibles après 30 emails")}
          </div>
          <img src={logo_maaia} alt="logo_maaia" height={40} />
        </div>
      )}*/}
    </React.Fragment>
  );
};

export default withTranslation()(LangageStateChart);
