import React from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import InfoMono from "../../../assets/images/icons/InfoMono";
import CheckCircleMono from "../../../assets/images/icons/CheckCircleMono";
import AlertTriangeMono from "../../../assets/images/icons/AlertTriangeMono";

function CustomToast({ isOpen, toggle, headerStyle, bodyStyle, message, t }) {
  const getColor = (key) => {
    switch (key) {
      case "success":
        return "rgb(150, 191, 72)";
      case "warning":
        return "#ffa412";
      case "danger":
        return "#f10944";
      default:
        return "#563bff";
    }
  };
  const getIcon = (key) => {
    switch (key) {
      case "success":
        return (
          <CheckCircleMono
            width={"24px"}
            height={"24px"}
            style={{
              color: getColor(key)
            }}
          />
        );
      case "warning":
        return (
          <AlertTriangeMono
            width={"24px"}
            height={"24px"}
            style={{
              color: getColor(key)
            }}
          />
        );
      case "danger":
        return (
          <InfoMono
            width={"24px"}
            height={"24px"}
            style={{
              color: getColor(key)
            }}
          />
        );
      default:
        return (
          <InfoMono
            width={"24px"}
            height={"24px"}
            style={{
              color: getColor(key)
            }}
          />
        );
    }
  };

  return (
    <Toast
      isOpen={isOpen}
      style={{
        borderRadius: "14px",
        backgroundColor: "white"
      }}
      className="d-flex">
      <div
        style={{
          padding: "0.75rem 0 0 0.75rem"
        }}>
        {getIcon(message.key)}
      </div>
      <div
        style={{
          flex: "1"
        }}>
        <ToastHeader
          className="font-primary simple-text"
          style={{
            color: message.key ? getColor(message.key) : "",
            paddingTop: "0.75rem",
            paddingBottom: "0",
            borderBottom: "none",
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
            ...headerStyle
          }}
          toggle={toggle}>
          {message.title ? message.title : "Message"}
        </ToastHeader>
        <ToastBody
          style={{
            backgroundColor: "white",
            borderBottomRightRadius: "14px",
            borderBottomLeftRadius: "14px",
            paddingTop: "0px",
            ...bodyStyle
          }}>
          {message.body ? message.body : "Action effectuée"}
        </ToastBody>
      </div>
    </Toast>
  );
}

export default CustomToast;
