import React, { useEffect, useState } from "react";
import Slider from "react-rangeslider";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { Navigate, useNavigate } from "react-router-dom";
import {
  checkSubcriptionUpdateType,
  stripe_payment_return_url
} from "../../../helpers/api_helper";

const CustomCalculator = (props) => {
  let navigate = useNavigate();
  const { userPlan } = useSelector((state) => ({
    userPlan: state.subscription.userPlan
  }));

  const { client_data } = useSelector((state) => {
    return {
      //   number_of_emails: state.subscription.subscriptionCredits,
      //  consumed_email_count: state.GeneralStateRedux.consumed_email_count,
      client_data: state.GeneralStateRedux.client_data
    };
  });

  function openPricing() {
    return localStorage.getItem("I18N_LANGUAGE") === "en"
      ? "https://synibox.io/pricing"
      : "https://synibox.io/pricing";
  }

  return (
    <div
      className="mx-auto d-flex"
      style={{ flexDirection: "column", width: "94%" }}
    >
      <div
        className="w-100 d-flex justify-content-center mt-4 gap-4"
        style={{ flex: "1" }}
      >
        <Col>
          <div className="pricing_content-title-2">
            <div className="pricing_text-margin">
              <div className="text-size-medium text-weight-medium mb-0">
                STARTER
              </div>
              <div className="text-size-large text-weight-bold">$29</div>
              {userPlan === "MAAIA_PLAN_STARTER" ? (
                <div
                  className="pricing_button-0 font-size-14"
                  style={{
                    color: "black",
                    backgroundColor: "#dedede",
                    borderRadius: "8px",
                    fontWeight: "600",
                    cursor: "not-allowed"
                  }}
                >
                  {props.t("Your Actual Plan")}
                </div>
              ) : (
                <div
                  style={{
                    cursor:
                      checkSubcriptionUpdateType(
                        "MAAIA_PLAN_STARTER",
                        userPlan
                      ) === "downgrade"
                        ? "not-allowed"
                        : "pointer"
                  }}
                  className={`${
                    checkSubcriptionUpdateType(
                      "MAAIA_PLAN_STARTER",
                      userPlan
                    ) === "downgrade"
                      ? "pricing_button-0"
                      : "pricing_button"
                  } w-button`}
                  onClick={() => {
                    if (
                      checkSubcriptionUpdateType(
                        "MAAIA_PLAN_STARTER",
                        userPlan
                      ) !== "downgrade"
                    ) {
                      navigate(
                        (client_data || props.client_data).stripe_region ===
                          "US"
                          ? `/checkout?p=starter&region=US`
                          : `/checkout?p=starter`
                      );
                    }
                  }}
                >
                  <strong className="font-size-14">
                    {props.t("Choose this Plan")}
                  </strong>
                </div>
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={openPricing()}
                className="my-2"
              >
                <span className="font-size-14">{props.t("See details")}</span>
              </a>
            </div>
          </div>
        </Col>
        <Col>
          <div className="pricing_content-title">
            <div className="pricing_text-margin">
              <div className="text-size-medium text-weight-medium mb-0">
                GROWTH
              </div>
              <div className="text-size-large text-weight-bold">$99</div>
              {userPlan === "MAAIA_PLAN_GROWTH" ? (
                <div
                  className="pricing_button-0 font-size-14"
                  style={{
                    color: "black",
                    backgroundColor: "#dedede",
                    borderRadius: "8px",
                    fontWeight: "600",
                    cursor: "not-allowed"
                  }}
                >
                  {props.t("Your Actual Plan")}
                </div>
              ) : (
                <div
                  style={{
                    cursor:
                      checkSubcriptionUpdateType(
                        "MAAIA_PLAN_GROWTH",
                        userPlan
                      ) === "downgrade"
                        ? "not-allowed"
                        : "pointer"
                  }}
                  className={`${
                    checkSubcriptionUpdateType(
                      "MAAIA_PLAN_GROWTH",
                      userPlan
                    ) === "downgrade"
                      ? "pricing_button-0"
                      : "pricing_button"
                  } w-button`}
                  onClick={() => {
                    if (
                      checkSubcriptionUpdateType(
                        "MAAIA_PLAN_GROWTH",
                        userPlan
                      ) !== "downgrade"
                    ) {
                      navigate(
                        (client_data || props.client_data).stripe_region ===
                          "US"
                          ? `/checkout?p=growth&region=US`
                          : `/checkout?p=growth`
                      );
                    }
                  }}
                >
                  <strong className="font-size-14">
                    {props.t("Choose this Plan")}
                  </strong>
                </div>
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={openPricing()}
                className="my-2"
              >
                <span className="font-size-14">{props.t("See details")}</span>
              </a>
            </div>
          </div>
        </Col>
        <Col>
          <div className="pricing_content-title-2">
            <div className="pricing_text-margin">
              <div className="text-size-medium text-weight-medium mb-0">
                BUSINESS
              </div>
              <div className="text-size-large text-weight-bold">$199</div>
              {userPlan === "MAAIA_PLAN_BUSINESS" ? (
                <div
                  className="pricing_button-0 font-size-14"
                  style={{
                    color: "black",
                    backgroundColor: "#dedede",
                    borderRadius: "8px",
                    fontWeight: "600",
                    cursor: "not-allowed"
                  }}
                >
                  {props.t("Your Actual Plan")}
                </div>
              ) : (
                <div
                  style={{
                    cursor:
                      checkSubcriptionUpdateType(
                        "MAAIA_PLAN_BUSINESS",
                        userPlan
                      ) === "downgrade"
                        ? "not-allowed"
                        : "pointer"
                  }}
                  className={`${
                    checkSubcriptionUpdateType(
                      "MAAIA_PLAN_BUSINESS",
                      userPlan
                    ) === "downgrade"
                      ? "pricing_button-0"
                      : "pricing_button"
                  } w-button`}
                  onClick={() => {
                    if (
                      checkSubcriptionUpdateType(
                        "MAAIA_PLAN_BUSINESS",
                        userPlan
                      ) !== "downgrade"
                    ) {
                      navigate(
                        (client_data || props.client_data).stripe_region ===
                          "US"
                          ? `/checkout?p=business&region=US`
                          : `/checkout?p=business`
                      );
                    }
                  }}
                >
                  <strong className="font-size-14">
                    {props.t("Choose this Plan")}
                  </strong>
                </div>
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={openPricing()}
                className="my-2"
              >
                <span className="font-size-14">{props.t("See details")}</span>
              </a>
            </div>
          </div>
        </Col>
        <Col>
          <div className="pricing_content-title">
            <div className="pricing_text-margin">
              <div className="text-size-medium text-weight-medium mb-0">
                PRO
              </div>
              <div className="text-size-large text-weight-bold">$399</div>
              {userPlan === "MAAIA_PLAN_PRO" ? (
                <div
                  className="pricing_button-0 font-size-14"
                  style={{
                    color: "black",
                    backgroundColor: "#dedede",
                    borderRadius: "8px",
                    fontWeight: "600",
                    cursor: "not-allowed"
                  }}
                >
                  {props.t("Your Actual Plan")}
                </div>
              ) : (
                <div
                  style={{
                    cursor:
                      checkSubcriptionUpdateType("MAAIA_PLAN_PRO", userPlan) ===
                      "downgrade"
                        ? "not-allowed"
                        : "pointer"
                  }}
                  className={`${
                    checkSubcriptionUpdateType("MAAIA_PLAN_PRO", userPlan) ===
                    "downgrade"
                      ? "pricing_button-0"
                      : "pricing_button"
                  } w-button`}
                  onClick={() => {
                    if (
                      checkSubcriptionUpdateType("MAAIA_PLAN_PRO", userPlan) !==
                      "downgrade"
                    ) {
                      navigate(
                        (client_data || props.client_data).stripe_region ===
                          "US"
                          ? `/checkout?p=pro&region=US`
                          : `/checkout?p=pro`
                      );
                    }
                  }}
                >
                  <strong className="font-size-14">
                    {props.t("Choose this Plan")}
                  </strong>
                </div>
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={openPricing()}
                className="my-2"
              >
                <span className="font-size-14">{props.t("See details")}</span>
              </a>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default withTranslation()(CustomCalculator);
