import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import Icons from "../../static/icons";
function EmailBoxCard(props) {
  const { index, mail, setSelectedMailBox, toggleBox } = props;

  useEffect(() => {}, [mail]);

  return (
    <Col className="col-auto" key={index}>
      <Card
        className="agent-card"
        style={{
          borderLeft: "5px solid #563BFF",
          borderRadius: "15px",
          boxShadow: "0px 1px 1px 1px rgba(53, 22, 252, 0.1)",
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10x",
          width: "100%"
        }}>
        <CardBody className="p-1">
          <Row className="d-flex justify-content-between">
            <Col className="text-center">
              <div
                className="font-primary"
                style={{
                  fontSize: "20px",
                  backgroundColor: "rgb(244, 244, 244)",
                  color: "black",
                  borderRadius: "100px",
                  fontWeight: "normal",
                  height: "50px",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                {mail.email
                  .split(" ")
                  .map((part) => part.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </Col>
            <Col className="d-flex justify-content-start">
              <div
                className="agent-name font-primary"
                style={{
                  width: "fit-content",
                  color: "#1E222C",
                  fontSize: "16px",
                  display: "flex",
                  fontStyle: "normal",
                  position: "center",
                  alignItems: "center",
                  fontWeight: "bold"
                }}>
                {mail.email}
              </div>
            </Col>
            <Col className="text-right d-flex justify-content-end">
              <div className="d-flex flex-row mt-3">
                <div>
                  <button
                    onClick={() => {
                      toggleBox(mail);
                    }}
                    style={{
                      border: "none",
                      backgroundColor: "inherit",
                      borderRadius: "8px",
                      color: "#FF0000",
                      cursor: "pointer"
                    }}>
                    {Icons.delete_icon}
                  </button>
                </div>
                <div className="ml-2">
                  <button
                    onClick={() => {
                      setSelectedMailBox(mail);
                    }}
                    style={{
                      border: "none",
                      backgroundColor: "inherit",
                      borderRadius: "8px"
                    }}>
                    {Icons.pen}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <div className="d-flex col-auto ps-3" style={{ alignItems: "center", justifyContent: "center", padding: "0px", flex: "0" }}>
              <div
                className="font-primary"
                style={{
                  fontSize: "20px",
                  backgroundColor: "rgb(244, 244, 244)",
                  color: "black",
                  borderRadius: "100px",
                  fontWeight: "normal",
                  height: "50px",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {mail.email
                  .split(" ")
                  .map((part) => part.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
            <Col className="ps-2">
              <div className="d-flex align-items-center">
                <div
                  className="agent-name font-primary"
                  style={{
                    width: "fit-content",
                    color: "#1E222C",
                    fontSize: "16px",
                    display: "flex",
                    fontStyle: "normal",
                    position: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}>
                  {mail.email}
                </div>
              </div>
              <div className="d-flex align-items-center" style={{}}>
                <div className="d-flex ms-auto">
                  <Col className="col-auto">
                    <div className="d-flex align-items-center">
                      <button
                        onClick={() => {
                          toggleBox();
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "inherit",
                          borderRadius: "8px",
                          color: "#0fdf0f",
                          cursor: "pointer",
                        }}>
                        {Icons.play}
                      </button>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <div className="d-flex align-items-center">
                      <button
                        onClick={() => {
                          setSelectedMailBox(mail);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "inherit",
                          borderRadius: "8px",
                        }}>
                        {Icons.pen}
                      </button>
                    </div>
                  </Col>
                </div>
              </div>

              <div
                className=" d-flex flex-wrap align-items-center"
                style={{
                  height: "18px",
                }}>
                <div
                  style={{
                    fontSize: "8px",
                    fontStyle: "normal",
                    fontWeight: "100",
                    color: "grey",
                  }}></div>
              </div>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </Col>
  );
}

export default withTranslation()(EmailBoxCard);
