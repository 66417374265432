import {
  SUBSCRIPTION_CREDITS,
  USER_PLAN_FREE_TRANSLATION_LIMIT,
  USER_PLAN_STATUS,
  USER_PLAN_SUCCESS,
  EMPTY_SUBSCRIPTION,
  SUBSCRIPTION_RESSOURCES,
  SUBSCRIPTION_LANGUAGES,
  USER_PLAN,
  SUBSCRIPTION_INCLUDED_CREDITS,
  LOADING_STATE
} from "./actionTypes";

const INIT_STATE = {
  userPlan: "FREE",
  isUserPlanActive: false,
  subscription: undefined,
  freeTranslationLimit: 2,
  subscriptionCredits: 0,
  subscriptionIncludedCredits: 0,
  numberOfRessources: 0,
  numberOfLanguages: 0,
  loadingState: true
};

const subscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_PLAN_SUCCESS:
      state = {
        ...state,
        userPlan: action.payload
      };
      break;
    case USER_PLAN_STATUS:
      state = {
        ...state,
        isUserPlanActive: action.payload
      };
      break;
    case USER_PLAN:
      state = {
        ...state,
        subscription: action.payload
      };
      break;
    case USER_PLAN_FREE_TRANSLATION_LIMIT:
      state = {
        ...state,
        freeTranslationLimit: action.payload
      };
      break;
    case SUBSCRIPTION_CREDITS:
      state = {
        ...state,
        subscriptionCredits: action.payload
      };
      break;
    case SUBSCRIPTION_INCLUDED_CREDITS:
      state = {
        ...state,
        subscriptionIncludedCredits: action.payload
      };
      break;
    case SUBSCRIPTION_RESSOURCES:
      state = {
        ...state,
        numberOfRessources: action.payload
      };
      break;
    case SUBSCRIPTION_LANGUAGES:
      state = {
        ...state,
        numberOfLanguages: action.payload
      };
      break;
    case LOADING_STATE:
      state = {
        ...state,
        loadingState: action.payload
      };
      break;
    case EMPTY_SUBSCRIPTION:
      state = INIT_STATE;
      break;
    default:
      return state;
  }
  return state;
};

export default subscription;
