import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import logo_maaia from "../../assets/images/companies/img-3.png";
import turnover from "../../assets/images/turnover.png";
import { withTranslation } from "react-i18next";
const Turnover = (props) => {
  let DashedLineData = null;
  const [mailReceive, setMailReceive] = useState();
  const [loading, setLoading] = useState(true);
  const dataMap = new Map();
  const theMessages = props.messages;
  const [formattedDataList, setformattedDataList] = useState({
    series: [
      {
        name: "CA",
        data: []
      }
    ],
    options: {
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 3
      },
      colors: ["#F10944"],
      xaxis: {
        type: "datetime",
        categories: []
      },
      grid: {
        borderColor: "#f1f1f1"
      },
      tooltip: {
        x: {
          format: "dd/MM/yy"
        }
      },
      chart: {
        toolbar: {
          show: false
        }
      }
    }
  });

  // Fonction pour agréger le nombre de messages par jour
  const aggregateMessagesByDay = (data) => {
    const aggregatedData = {};
    let theCount = 0;
    data.forEach((message) => {
      theCount += 1;
      const messageDate = new Date(message.message_date);
      const dayKey = messageDate.getTime();

      if (aggregatedData[dayKey]) {
        aggregatedData[dayKey].count = theCount * 7;
      } else {
        aggregatedData[dayKey] = {
          date: dayKey,
          count: theCount * 7
        };
      }
    });

    return Object.values(aggregatedData);
  };

  const handleDateChange = (theData) => {
    console.log("last messages", theData[theData.length - 1]);
    var data = theData;
    // Agréger le nombre de messages par jour
    const aggregatedData = aggregateMessagesByDay(data);

    // Mettre à jour le state avec les données agrégées
    setformattedDataList((prevData) => ({
      ...prevData,
      series: [
        {
          name: "C.A",
          data: aggregatedData.map((dataPoint) => ({
            x: dataPoint.date,
            y: dataPoint.count
          }))
        }
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: aggregatedData.map((dataPoint) => dataPoint.date)
        }
      }
    }));
    // setformattedDataList(finalChartData);

    setLoading(false);
  };
  useEffect(() => {
    handleDateChange(theMessages);
  }, [theMessages]);

  return (
    <React.Fragment>
      {formattedDataList && formattedDataList.series[0].data.length > 0 ? (
        <ReactApexChart
          options={formattedDataList.options}
          series={formattedDataList.series}
          type="area"
          height="235"
          className="apex-charts font-primary"
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + turnover + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "138%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}>
          <div
            className="font-primary font-size-22 color-black mt-4 mb-1 text-center"
            style={{
              width: "418px",
              lineHeight: "28px"
            }}>
            {props.t("Bientôt disponible")}
          </div>
          <img src={logo_maaia} height={40} />
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Turnover);
