import React, { useState, useEffect } from "react";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createStripeCheckoutSession,
  createStripePaymentEntent,
  stripe_payment_return_url,
  prestashop_payment_return_url,
  createStripeCustomer,
  synibox_dashboard_url,
  getStripeSubscription,
  createStripeSubscripion as createStripeSubscription,
  applyStripeDiscountCode,
  retrieveStripeUpComingInvoice,
  getStripeProduct,
  SMARTAS,
  createStripeSetupIntent,
  updateStripeCustomer,
  createRetryPaymentPreview,
  finalizePayment,
  createLog,
  klaviyoUpdateProfile
} from "../../helpers/api_helper";
import logo_maaia from "../../assets/images/icons-infos-purple.png";
import {
  Col,
  CardBody,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
  ToastBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Modal,
  InputGroup,
  InputGroupText,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
  Alert
} from "reactstrap";
import "./PaymentForm.scss";
import InfoMono from "../../assets/images/icons/InfoMono";
import PlusOutlined from "../../assets/images/icons/PlusOutlined";
import ArrowLeftOutlined from "../../assets/images/icons/ArrowLeftOutlined";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Client, Account, Databases, ID, Query } from "appwrite";
import { withTranslation } from "react-i18next";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import { useSelector } from "react-redux";
import { useMixpanel } from "../../Hooks/useMixpanel";

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [country, setCountry] = useState(null);
  const { mixPanelTrack } = useMixpanel();

  const handleError = (error) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  async function updateClientInfos(data) {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      props.company_id,
      JSON.stringify(data)
    );

    return promise.then(
      async function (response) {
        console.log("updateCompanyInfos success", response); // Success
        return response;
      },
      function (error) {
        console.log("updateCompanyInfos error", error); // Failure
        return null;
      }
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Confirmer le paiement en utilisant stripe.confirmPayment
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: stripe_payment_return_url // URL de redirection après succès
      },
      redirect: "if_required"
    });

    if (error) {
      // Gérer les erreurs de paiement
      handleError(error);
    } else {
      // Paiement réussi
      console.log("Payment confirmed:", paymentIntent);

      // Finaliser avec la capture du paiement si nécessaire (si capture_method est 'manual')
      if (paymentIntent.capture_method === "manual") {
        const res = await finalizePayment(props.region, paymentIntent.id);

        if (!res) {
          setErrorMessage("system_error");
          setIsLoading(false);
          return;
        } else {
          await updateClientInfos({ stripe_region: country });

          const company_id = localStorage.getItem("companyId");
          var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

          const updates = {
            properties: {
              stripe_region: country
            }
          };
          klaviyoUpdateProfile(company_id, updates);

          let logData = {
            eventCreatedAt: Date.now(),
            author_id: user_id,
            company_id: company_id,
            plan: SMARTAS.PLAN_NAME[props.price_id]
          };
          let logdata = {
            event: "subscription_payment_finalized",
            author_id: user_id,
            company_id: company_id,
            log_data: JSON.stringify(logData)
          };
          createLog(logdata, mixPanelTrack);
          window.location.href = stripe_payment_return_url;
        }
      }

      // Exécuter vos actions de succès ici (ex: mise à jour de l'interface utilisateur)
      console.log("Payment finalized successfully");
    }

    setIsLoading(false);
  };

  console.log({ the_passed_region: props.region });

  const defaultAdd =
    props.region === "US"
      ? { name: props.customer_fullName, address: { country: "US" } }
      : {
          name: props.customer_fullName,
          address: { country: props.region || "FR" }
        };

  const handleAddressChange = (event) => {
    let the_country;
    console.log("Address changed:", event);
    if (event.elementType === "address" && event.elementMode === "billing") {
      the_country = event.value.address.country;
      const fullName = event.value.name;
      console.log("Address changed: country is", the_country);
      console.log("Name is", event.value.name);
      props.setCustomerFullName(fullName);
    }

    if (
      (the_country && the_country === "US" && props.region !== "US") ||
      (props.region === "US" && the_country && the_country !== "US")
    ) {
      console.log("Need refresh");
      props.refreshStripePaymentIntent(the_country);
    } else {
      console.log("No need refresh", the_country);
    }
    setCountry(the_country);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="payment-form d-flex"
      style={{ height: "100%" }}
    >
      <div className="my-auto w-100 py-4">
        <div className="form-group">
          <AddressElement
            options={{
              mode: "billing",
              defaultValues: defaultAdd
            }}
            onChange={handleAddressChange}
          />
        </div>

        {errorMessage && (
          <Alert color="danger" className="font-primary font-size-12 mb-3">
            {props.t(errorMessage)}
          </Alert>
        )}
        <div className="form-group">
          <PaymentElement
            options={{
              layout: {
                type: "accordion",
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: false
              }
            }}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || props.isRefreshing}
          className="r_button"
          id="submit"
        >
          <span id="button-text">
            {isLoading || props.isRefreshing ? (
              <span
                className="spinner-border spinner-border-sm me-2 font-primary"
                role="status"
                aria-hidden="true"
              />
            ) : (
              props.t("Pay now")
            )}
          </span>
        </button>
      </div>
      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};

const StripeRetryPaymentCheckout = (props) => {
  // const [stripePromise, setLoadStripe] = useState(undefined);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [subscriptionIntent, setSubscriptionIntent] = useState(null);
  const [paymentIntent, setpaymentIntent] = useState(null);
  const [stripeCustomerID, setStripeCustomerID] = useState(undefined);
  const [customer_email, setCustomerEmail] = useState(null);
  const [customer_fullName, setCustomerFullName] = useState(null);
  const [customerIsLoading, setCustomerIsLoading] = useState(null);
  const [client_data, setCompanyInfos] = useState(undefined);

  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState(undefined);
  const [couponRequestMessage, setCouponRequestMessage] = useState("");
  const [couponRequestLoading, setCouponRequestLoading] = useState(false);

  const [showUpdateUI, setShowUpdateUI] = useState(false);

  const { search } = useLocation();
  let navigate = useNavigate();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region") || undefined;

  let stripePromise;
  if (region && region === "US") {
    console.log("Load strip for US");

    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_USA);
  } else {
    console.log("Load strip for World");
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_WORLD);
  }

  const handleEmailChange = (email) => {
    console.log("Email changed: email is", email);
    setCustomerEmail(email);
  };

  const refreshStripePaymentIntent = async (region) => {
    console.log("start refreshStripePaymentIntent");

    if (region === "US") {
      window.location.href = `${synibox_dashboard_url}/retry-payment-checkout?region=US`;
    } else {
      window.location.href = `${synibox_dashboard_url}/retry-payment-checkout`;
    }
  };

  const handleCreateCustomer = async (email, name) => {
    setCustomerIsLoading(true);
    console.log("Started creating customer");

    let customer_data;
    if (region && region === "US") {
      customer_data = await createStripeCustomer("US", email, name);
      console.log({ customer_data });
    } else {
      customer_data = await createStripeCustomer(undefined, email, name);
      console.log({ customer_data });
    }
    setCustomerIsLoading(false);

    if (customer_data) {
      setStripeCustomerID(customer_data.customer.id);
      fetchClientSecret(region, client_data.plan_id);
    }
  };

  const fetchClientSecret = async (region, subscription_id) => {
    setPageLoading(true);

    const response = await createRetryPaymentPreview(region, subscription_id);

    setPageLoading(false);

    if (response) {
      const paymentIntent = response.paymentIntent;
      setpaymentIntent(paymentIntent);
      setClientSecret(paymentIntent.client_secret);
    } else {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
  };

  useEffect(() => {
    initCustomer();
  }, []);

  const initCustomer = async () => {
    setCustomerIsLoading(true);
    setPageLoading(true);
    const user_string = localStorage.getItem("user");
    const user = user_string ? JSON.parse(user_string) : undefined;

    if (
      user_string &&
      user &&
      user.prefs &&
      user.prefs["owner"] &&
      (user.prefs["owner"] === "true" || user.prefs["owner"] === true)
    ) {
      const the_client_data = await getClientData();

      if (!the_client_data) {
        console.log("No company infos");
        return;
      }
      setCompanyInfos(the_client_data);

      console.log({ synibox_user_name: user });
      handleEmailChange(user.email);
      setCustomerFullName(
        [user.prefs["first_name"], user.prefs["last_name"]]
          .filter(Boolean)
          .join(" ")
      );
      const stripe_customer_id = the_client_data.stripe_customer_id;
      if (stripe_customer_id) {
        setStripeCustomerID(stripe_customer_id);

        const response = await createRetryPaymentPreview(
          region,
          the_client_data.plan_id
        );

        if (response && response.invoice.amount_due > 0) {
          console.log({ the_subscription: response.invoice.lines.data });
          const plan = response.invoice.lines.data.find(
            (line) =>
              line.price.id === SMARTAS.STARTER_WORLD ||
              line.price.id === SMARTAS.STARTER_US ||
              line.price.id === SMARTAS.GROWTH_WORLD ||
              line.price.id === SMARTAS.GROWTH_US ||
              line.price.id === SMARTAS.BUSINESS_WORLD ||
              line.price.id === SMARTAS.BUSINESS_US ||
              line.price.id === SMARTAS.PRO_WORLD ||
              line.price.id === SMARTAS.PRO_US ||
              line.price.id === SMARTAS.PAUSE_US ||
              line.price.id === SMARTAS.PAUSE_WORLD
          );
          console.log({ plan });
          setSubscriptionIntent(response.invoice);

          setPlanDetails(plan);

          setClientSecret(response.clientSecret);
        } else {
          navigate(`/settings`, {
            replace: true,
            state: { tab: "billing", modal: "" }
          });
        }
      } else {
        navigate(`/settings`, {
          replace: true,
          state: { tab: "billing", modal: "" }
        });
      }
    } else {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
    setCustomerIsLoading(false);
    setPageLoading(false);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const getClientData = async function () {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    try {
      const response = await databases.getDocument(
        "default",
        "client_infos",
        localStorage.getItem("companyId")
      );
      console.log("getClientData", response);
      onCompanyInfoChanged(response);
      return response;
    } catch (error) {
      console.log(error); // Failure
      return null;
    }
  };

  const options = {
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
    appearance: {
      theme: "stripe"
    },
    clientSecret,
    layout: {
      type: "accordion",
      defaultCollapsed: false
    },
    locale: props.i18n.language
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{
        height: "100vh",
        backgroundColor: "white"
      }}
    >
      <>
        <Col
          className=""
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingRight: "70px",
            overflow: "auto"
          }}
        >
          <div
            className="d-flex ms-auto"
            style={{ flexDirection: "column", width: "500px", height: "100%" }}
          >
            <div className="my-auto">
              <div className={`d-flex align-items-center gap-1 mb-3`}>
                <img src={logo_maaia} alt="logo_maaia" height={30} />
                <p
                  className="font-size-16 mb-0 ms-1"
                  style={{ fontWeight: "500" }}
                >
                  Synibox
                </p>
              </div>

              <div className="d-flex mb-3" style={{ flexDirection: "column" }}>
                <p className="font-size-16 mb-0" style={{ fontWeight: "500" }}>
                  Synibox
                </p>

                {subscriptionIntent && (
                  <>
                    {planDetails && ProductCard(planDetails, true)}

                    <div>
                      <div className="mt-3">
                        {subscriptionIntent && (
                          <div
                            className="d-flex align-items-center justify-content-between pb-3"
                            style={{
                              borderBottom: "1px solid rgba(15, 15, 15, 0.1)"
                            }}
                          >
                            <div className="product_name">
                              {props.t("Subtotal")}
                            </div>
                            <span className="line_item_price">
                              {subscriptionIntent.currency === "usd"
                                ? "$"
                                : "€"}
                              {subscriptionIntent.subtotal / 100}
                            </span>
                          </div>
                        )}
                      </div>

                      {subscriptionIntent && (
                        <div className="mt-3">
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ fontWeight: "600" }}
                          >
                            <div className="product_name">
                              {props.t("Total to pay today")}
                            </div>
                            <span className="line_item_price">
                              {subscriptionIntent.currency === "usd"
                                ? "$"
                                : "€"}
                              {subscriptionIntent.total / 100}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div style={{ alignSelf: "end" }}>
            <LanguageDropdown hasArrow />
          </div>
        </Col>
        <Col
          className=""
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            boxShadow: "-12px 0px 15px -4px rgba(128, 128, 128, 0.15)",
            paddingLeft: "70px",
            overflow: "auto"
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {!stripeCustomerID && !pageLoading && (
              <div className="form-group">
                <label htmlFor="email">{props.t("E-mail")}</label>
                <input
                  className="w-100"
                  id="email"
                  type="email"
                  value={customer_email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                  placeholder={props.t("Enter your e-mail address")}
                />
                <button
                  type="submit"
                  disabled={!customer_email}
                  className="r_button mt-3"
                  onClick={(e) =>
                    handleCreateCustomer(customer_email, customer_fullName)
                  }
                >
                  <span id="button-text">
                    {customerIsLoading || props.isRefreshing ? (
                      <span
                        className="spinner-border spinner-border-sm me-2 font-primary"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      props.t("Continuer")
                    )}
                  </span>
                </button>
              </div>
            )}

            {pageLoading ? (
              <div
                className="mt-4 font-size-16 gap-1"
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <span
                  className="spinner-border spinner-border-md me-2 font-primary text-center"
                  role="status"
                  aria-hidden="true"
                />
                {props.t("Chargement") + "..."}
              </div>
            ) : (
              clientSecret &&
              client_data && (
                <Elements stripe={stripePromise} options={options}>
                  <PaymentForm
                    refreshStripePaymentIntent={refreshStripePaymentIntent}
                    isRefreshing={isRefreshing}
                    price_id={planDetails.price.id}
                    stripeCustomerID={stripeCustomerID}
                    region={
                      client_data && client_data.stripe_region
                        ? client_data.stripe_region
                        : region
                    }
                    setCustomerFullName={setCustomerFullName}
                    customer_email={customer_email}
                    customer_fullName={customer_fullName}
                    company_id={client_data.$id}
                    t={props.t}
                  />
                </Elements>
              )
            )}
          </div>
        </Col>
      </>
    </div>
  );

  function ProductCard(
    plan,
    is_plan,
    trial = false,
    description = null,
    region = undefined
  ) {
    return (
      <div className={is_plan ? "addon_card" : ""}>
        <div className="my-2 w-100">
          <div className="d-flex">
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column"
              }}
            >
              <div className="d-flex justify-content-between w-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div className="product_name">
                    {description
                      ? props.t("Trial period for ") + description
                      : plan.description
                          .split(" ")
                          .map((word) => props.t(word))
                          .join(" ")}
                  </div>
                  {!trial && (
                    <div>
                      <select className="qty_select">
                        {plan.plan.interval === "month" ? (
                          <option value="Monthly">{props.t("Monthly")}</option>
                        ) : (
                          <option value="Yearly">{props.t("Yearly")}</option>
                        )}
                      </select>
                    </div>
                  )}
                </div>
                <span className="line_item_price" style={{ fontWeight: "600" }}>
                  {trial
                    ? region === "US"
                      ? "$"
                      : "€"
                    : plan.plan.currency === "usd"
                    ? "$"
                    : "€"}
                  {trial ? "00.00" : plan.amount / 100}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withTranslation()(StripeRetryPaymentCheckout);
