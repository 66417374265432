import { AGENT_ACTIVE_LOADING, SHOW_AGENT_STATUS, FETCH_CONSUMED_CREDIT, APPLY_CONSUMED_CREDIT, EMPTY_GENERAL_STATE } from "./actionTypes";

const initialState = {
  consumed_email_count: 0,
  client_data: undefined
};

const GeneralStateRedux = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_AGENT_STATUS:
      return {
        ...state,
        agentStatus: action.payload
      };
    case AGENT_ACTIVE_LOADING:
      return {
        ...state,
        activeLoading: action.payload
      };
    case FETCH_CONSUMED_CREDIT:
      return {
        ...state
        //   consumed_email_count:0,
        //  client_data: action.payload,
      };
    case APPLY_CONSUMED_CREDIT:
      state = {
        ...state,
        consumed_email_count: action.payload.consumed_email_count,
        client_data: action.payload.client_data
      };
      break;
    case EMPTY_GENERAL_STATE:
      state = initialState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GeneralStateRedux;

// const folders_redux = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_FOLDERS_REDUX:
//       state = {
//         ...state,
//         loading: true,
//         fetchError: null,
//       }
//       break
//     case APPLY_FOLDERS_SUCCESSFULL:
//       state = {
//         ...state,
//         loading: false,
//         response: action.payload,
//         fetchError: null,
//       }
//       break
//     case APPLY_FOLDERS_ERROR:
//       state = {
//         ...state,
//         loading: false,
//         fetchError: action.payload,
//       }
//       break
//     default:
//       state = { ...state }
//       break
//   }
//   return state
// }
