import React from "react";
import { Container, Row, Col } from "reactstrap";
import LogoMaia from "../../assets/images/logo/synibox_logo_500x500.png";
import { withTranslation } from "react-i18next";
import logo_smartas_1 from "../../assets/images/companies/img-3.png";

const NoDesktopTempUI = (props) => {
  document.title = "Error-500  | Synibox - Smart Assistant";

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh"
        }}
      >
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} md={12} xl={12}>
                <div className="text-center">
                  <div>
                    <img
                      src={logo_smartas_1}
                      alt="logoSmartAs"
                      style={{
                        objectFit: "contain",
                        height: "150px",
                        width: "150px"
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "18px" }}>
                    <p style={{ fontSize: "18px" }}>
                      {props.t("Disponible uniquement sur écran large")}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(NoDesktopTempUI);
