import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import NoDesktopTempUI from "../Pages/Utility/NoDesktopTempUI";
import { useSelector } from "react-redux";

const AuthProtected = (props) => {
  /*
    redirect is un-auth access protected routes via url
    */
  const user_string = localStorage.getItem("user");
  const user = user_string ? JSON.parse(user_string) : undefined;
  const user_auth_string = localStorage.getItem("authUser");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 1200);
  };

  const { client_data } = useSelector((state) => {
    return {
      client_data: state.GeneralStateRedux.client_data
    };
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  console.log({ instanceType: process.env.REACT_APP_MAAIA_ENV });

  if (user_string) {
    // console.log("XXX the selected", localStorage.getItem("selected_mail_box"));
    // if (!localStorage.getItem("selected_mail_box")) {
    //   if (client_data && client_data.mailBox.length > 0) {
    //     console.log("XXX applyed selected", localStorage.getItem("selected_mail_box"));
    //     localStorage.setItem("selected_mail_box", client_data.mailBox[0].email);
    //   }
    // }
    /*
    if (
      user &&
      user.prefs &&
      user.prefs["owner"] &&
      (user.prefs["owner"] === "true" || user.prefs["owner"] === true) &&
      !user.prefs["finished_onbording"]
    ) {
      return <Navigate to={{ pathname: "/setup" }} />;
    }
    */
  } else {
    return (
      <Navigate to={{ pathname: "/logout", state: { from: props.location } }} />
    );
  }

  return <> {isLargeScreen ? props.children : <NoDesktopTempUI />}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
